export function generateUniqueId(): string {
  const timestamp = Date.now().toString(36);
  const array = new Uint32Array(2);
  crypto.getRandomValues(array);
  const random = Array.from(array, (num) => num.toString(36)).join('');
  return timestamp + random;
}

export function isBackForwardNavigation(window): boolean {
  const perfNavList = window?.performance?.getEntriesByType(
    'navigation'
  ) as PerformanceNavigationTiming[];

  return perfNavList?.length > 0 && perfNavList[0].type === 'back_forward';
}

export function getCurrentFragment(window: Window): string {
  const hash = window.location.hash;

  return hash ? hash.substring(1) : '';
}

export function flattenParams(params: any): Record<string, any> {
  const result: any = {};

  Object.keys(params).forEach((key) => {
    if (typeof params[key] === 'object' && !Array.isArray(params[key]) && params[key] !== null) {
      Object.keys(params[key]).forEach((subKey) => {
        if (!params[key][subKey]) return;
        result[`${key}.${subKey}`] = params[key][subKey];
      });
    } else {
      result[key] = params[key];
    }
  });

  return result;
}
