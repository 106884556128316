import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { PeopleActionEnum } from '@src/app/shared/enums/people.enum';
import { PeopleAction } from '@src/app/shared/interfaces/people.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { ArrayUtils } from '@src/app/shared/utils/array-utils';
import { PeopleCardsComponent } from '../people-cards.component';
import { CardType } from '@src/app/shared/components/people-card-list/people-card-list.component';

@Component({
  selector: 'app-initator-cards',
  templateUrl: './initator-cards.component.html',
  styles: [],
})
export class InitatorCardsComponent
  extends PeopleCardsComponent
  implements OnChanges
{
  @Input() toItems: UserInterface[] = [];
  @Input() entityId: number;
  @Input() allowRemoveMyself = false;

  @Output() itemsRoleUpdate = new EventEmitter();
  @Output() toItemsChange = new EventEmitter();
  extraActions = [
    {
      action: PeopleActionEnum.Demote,
      label: 'Unassign Admin role',
      tooltip: 'UI.Demote-Initiator-Hint',
      faIcon: 'far fa-user-times',
    },
  ];
  CardType = CardType;

  demoteToStakeholder(peopleAction: PeopleAction): void {
    const person = peopleAction.people;
    if (person && peopleAction.action === PeopleActionEnum.Demote) {
      this.httpService
        .demoteInitiator(this.entityId, person.id)
        .subscribe(() => {
          const itemsRoleUpdate = ArrayUtils.removeItem(
            this.items,
            person,
            'id'
          );

          this.itemsRoleUpdate.emit(itemsRoleUpdate);

          this.toItems = this.toItems ?? [];
          const toItemsChange = [...this.toItems, person];
          this.toItemsChange.emit(toItemsChange);

          this.setDisplayItems();
        });
    }
  }

  /* Oldway to update custom role of admin
  // this role is dedicated attribute of the entity
  updateDirectPeopleRole(person: UserInterface): void {
    const dto = {
      adminRole: person.role.codeId
    };
    this.httpService
      .updateCustomAttribute(this.entityId, dto)
      .subscribe((res) => {
        this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
      },
        (errorRes: HttpErrorResponse) => {
          person.roleIndex = person.previousRoleIndex;
          person.role = { ...person.previousRole };
          if (errorRes.error?.title) {
            this.toastService.showError(errorRes.error.title);
          }
        });
  }
  */

  updateDirectPeopleRole(
    person: UserInterface,
    ignoreValidateRole = false
  ): void {
    const isAdmin = true;
    this.httpService
      .updatePeopleRole(this.entityId, person, isAdmin, ignoreValidateRole)
      .subscribe({
        next: (res) => {
          if (res && typeof res === 'string') {
            this.openConfirmIgnoreValidateRoleDialog(
              person,
              'UI.Venture.ConfirmEditRoleStudentFromAnotherVenture'
            );
          } else {
            this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
          }
        },
        error: (errorRes: HttpErrorResponse) => {
          this.rollbackCustomRole(person);
          if (errorRes.error?.title) {
            this.toastService.showError(errorRes.error.title);
          }
        },
      });
  }

  confirmToIgnoreValidateRole(person: UserInterface): void {
    this.updateDirectPeopleRole(person, true);
  }
}
