import { HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PeopleHttpService } from '@src/app/core/http/people-http.service';
import { NotificationService } from '@src/app/core/notification.service';
import { ToastService } from '@src/app/core/toast.service';
import { PeopleActionEnum } from '@src/app/shared/enums/people.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { PeopleAction } from '@src/app/shared/interfaces/people.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { finalize } from 'rxjs/operators';
import { PeopleCardsComponent } from '../people-cards.component';
import { maxPageSize } from '@shared/constants/common';

@Component({
  selector: 'app-pending-people-cards',
  templateUrl: './pending-people-cards.component.html',
  styles: [],
})
export class PendingPeopleCardsComponent
  extends PeopleCardsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() label = 'UI.PeopleEdit.PendingPeopleTitle';
  @Output() peopleApproved = new EventEmitter<UserInterface>();

  isLoading = false;
  extraActions: PeopleAction[] = [
    {
      action: PeopleActionEnum.Approve,
      label: 'UI.Common.Approve',
      class: 'btn-primary',
      faIcon: 'far fa-user-check',
    },
  ];

  constructor(
    protected peopleService: PeopleHttpService,
    protected modalService: NgbModal,
    protected notificationService: NotificationService,
    protected toastService: ToastService
  ) {
    super(peopleService, modalService, notificationService, toastService);
  }

  ngOnInit(): void {
    this.getListPendingPeople();
  }

  handleExtraAction(peopleAction: PeopleAction): void {
    const person = peopleAction.people;
    if (person && peopleAction.action === PeopleActionEnum.Approve) {
      this.approveStakeHolder(person);
    }
  }

  approveStakeHolder(person: UserInterface, ignoreValidateRole = false): void {
    this.httpService
      .approveStakeHolder(this.entityId, person, ignoreValidateRole)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (typeof res === 'string') {
          this.openConfirmIgnoreValidateRoleDialog(
            person,
            'UI.Venture.ConfirmAcceptPendingStudentOfAnotherVenture'
          );
        } else {
          this.getListPendingPeople();
          this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
          this.peopleApproved.emit(person);
        }
      });
  }

  confirmToIgnoreValidateRole(person: UserInterface): void {
    this.approveStakeHolder(person, true);
  }

  getListPendingPeople(): void {
    const params = new HttpParams({
      fromString: `pageIndex=${this.page}&pageSize=${maxPageSize}`,
    });
    this.isLoading = true;
    this.httpService
      ?.paginatePendingStakeHolders(this.entityId, params)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((res: ApiGetResponse<any>) => {
        this.items = res.items;
        this.collectionSize = res?.rowCount;
      });
  }

  removeItem(person: UserInterface): void {
    if (person) {
      this.httpService
        .rejectStakeHolder(this.entityId, person)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.getListPendingPeople();
          this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
        });
    }
  }

  refuseToIgnoreValidateRole(person: UserInterface): void {
    // do nothing
  }

  ngOnDestroy(): void {
    /**/
  }
}
