import { Component, EventEmitter, Output } from '@angular/core';
import { PartnerOganizationsComponent } from '@src/app/components/organizations/components/organization/partner-oganizations/partner-oganizations.component';

@Component({
  selector: 'app-featured-innovation-spaces',
  templateUrl: './featured-innovation-spaces.component.html',
})
export class FeaturedInnovationSpacesComponent extends PartnerOganizationsComponent {
  @Output() itemRemoved = new EventEmitter();

  removeItem(item): void {
    this.itemRemoved.emit(item);
  }
}
