import {
  Component,
  Injector,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { JoinEventComponent } from '@components/entity-section/interaction-bar/join-interact/join-event/join-event.component';
import { EventHttpService } from '@core/http/event-http.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { EventSectionIndex, EventState } from '@shared/enums/event-entity.enum';
import { UrlParam } from '@shared/enums/url-param.enum';
import {
  EventInterface,
  EventMessageRights,
} from '@shared/interfaces/event.interface';
import { LeadCompany } from '@shared/interfaces/lead-company.interface';
import { BaseEntityEditComponent } from '@src/app/components/base/base-detail/base-entity-edit/base-entity-edit.component';
import { EventPaymentComponent } from '@src/app/components/events/components/event/event-edit/event-payment/event-payment/event-payment.component';
import { NewMessageDialogComponent } from '@src/app/components/news-messages/components/new-message-dialog/new-message-dialog.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { SessionService } from '@src/app/core/session.service';
import { CustomDateParserFormatter } from '@src/app/shared/components/boostrap-datepicker/boostrap-datepicker.component';
import { LocationInfo } from '@src/app/shared/components/google-map-autocomplete/google-map-autocomplete.component';
import { OrganizerComponent } from '@src/app/shared/components/organizer/organizer.component';
import { CardType } from '@src/app/shared/components/people-card-list/people-card-list.component';
import { transformToTagModaByKey } from '@src/app/shared/components/tag-field/tag-field.component';
import { maxPageSize } from '@src/app/shared/constants/common';
import { DeactiveShowType } from '@src/app/shared/enums/deactive-show-type.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { RecipientType } from '@src/app/shared/enums/message.enum';
import { OrgsSearchMode } from '@src/app/shared/enums/org-search-mode.enum';
import { OrganizationType } from '@src/app/shared/enums/organization.enum';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import { TokenTypes } from '@src/app/shared/enums/token-type';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import {
  AttributeDescription,
  CustomAtrributeValue,
} from '@src/app/shared/interfaces/attribute-description.interface';
import { MessagePayload } from '@src/app/shared/interfaces/news-message.interface';
import { OrganizationInterface } from '@src/app/shared/interfaces/organization.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { UIPosition } from '@src/app/shared/models/ui.model';
import { ToggleButtonSliderComponent } from '@src/app/shared/toggle-slider/toggle-button-slider/toggle-button-slider.component';
import { ArrayUtils } from '@src/app/shared/utils/array-utils';
import { CustomMetadataUtils } from '@src/app/shared/utils/custom-metadata-utils';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { TimeUtils } from '@src/app/shared/utils/time-utils';
import { environment } from '@src/environments/environment';
import moment from 'moment/moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, finalize } from 'rxjs/operators';
import { EventExportComponent } from '../../event-export/event-export.component';
import { PopupBlockerDetectionService } from '@src/app/core/services/popup-blocker-detection.service';

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class EventEditComponent
  extends BaseEntityEditComponent<EventInterface>
  implements OnInit, OnChanges
{
  markOrgFormTouched = false;
  isLinkedToExistingOrg = false;
  showFollowersModal = false;
  showLikesModal = false;
  eventSectionIndex = EventSectionIndex;

  isShowExternalRegistration = false;
  DeactiveShowTypeEnum = DeactiveShowType;
  isProduction = environment.production;

  OrganizationTypeEnum = OrganizationType;
  orgAsPartners: LeadCompany[] = [];

  eventPartnerStatusAtrrDes: AttributeDescription;
  partnerOrganizations: LeadCompany[] = [];
  eventLocationFlagPos = new UIPosition('0.5rem', 'unset', '1.25rem');
  isNotShownPhysicalLocation = false;
  filteringKeyword: string;

  entityBase: string = environment.jipUrl.event;
  isProcessingPayment = false;
  paymentApiKey = '';
  isLeadOrgExternalOrg: boolean = false;
  orgHttpService: OrganizationHttpService;
  creationDate: Date;
  participantsWithProfile: UserInterface[];
  participantsWithoutProfile: UserInterface[];
  cardType = CardType;

  readonly TokenTypes = TokenTypes;

  @ViewChild('showExternalRegistrationToggle')
  showExternalRegistrationToggle: ToggleButtonSliderComponent;

  @ViewChild('jointEvent') joinEventComponent: JoinEventComponent;
  @ViewChild('eventPayment') eventPayment: EventPaymentComponent;
  @ViewChild('organizer') organizer: OrganizerComponent;

  private popupBlockerDetectionService: PopupBlockerDetectionService

  constructor(
    public baseHttpService: EventHttpService,
    protected authenService: AuthenService,
    protected sessionService: SessionService,
    protected injector: Injector,
    private cookieService: CookieService
  ) {
    super(baseHttpService, authenService, sessionService, injector);

    this.entityName = EntityName.Event;
    this.orgsSearchMode = OrgsSearchMode.ForLeadIncludeChallenge;
    this.portalName = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
    this.portalUrl = this.cookieService.get(SessionKeyEnum.TENANT_DOMAIN);
    this.orgHttpService = this.injector.get<OrganizationHttpService>(
      OrganizationHttpService
    );
    this.popupBlockerDetectionService = this.injector.get(PopupBlockerDetectionService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.isCreating && this.isCreating) {
      this.sessionService.apiReady$
        .pipe(untilDestroyed(this))
        .subscribe((ready) => {
          if (ready) {
            this.handleSetOrganizer();
          }
        });
    }
  }

  handleSetOrganizer() {
    const id = Number(StringUtils.getParamFromUrl(UrlParam.OrganizationId));

    if (id && !isNaN(id)) {
      this.orgHttpService
        .read(id)
        .pipe(untilDestroyed(this))
        .subscribe((organization: OrganizationInterface) => {
          setTimeout(() => {
            this.organizer?.onSelect(organization);
          }, 1000);
        });
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.creationDate = new Date();

    this.listenToPopupBlockedParam();
  }

  listenToPopupBlockedParam() {
    const popupBlockedParam = StringUtils.getParamFromUrl(UrlParam.PopUpBlocked);

    if (popupBlockedParam && popupBlockedParam === 'true') {
      this.popupBlockerDetectionService.isPopupBlocked$.next(true);
    }
  }

  shouldShowField(control: string, isEditing: boolean): boolean {
    return (
      (this.form?.get(control)?.value && !this.isCreating && !isEditing) ||
      this.isCreating ||
      isEditing
    );
  }

  customInit(): void {
    this.entityRoot = environment.jipUrl.event;

    this.formSections = [
      {
        fieldsName: ['Title'],
      },
      {
        fieldsName: [
          'EventDateTime',
          'Price',
          'PriceUnit',
          'EventLength',
          'Language',
          'Speakers',
          'TotalPlaces',
          'ShowExternalRegistration',
          'RegistrationLink',
          'AvailableSeats',
          'Payment',
          'PaymentToken',
        ],
      },
      {
        fieldsName: ['SendMessageRights'],
      },
      {
        fieldsName: ['LeadCompany'],
      },
      {
        fieldsName: ['PartnerOrganizations'],
      },
      {
        fieldsName: ['Agenda'],
      },
      {
        fieldsName: ['Description', 'Attachments'],
      },
      {
        fieldsName: ['Stakeholders'],
      },
      {
        fieldsName: ['Location', 'VirtualLocation'],
      },
      {
        fieldsName: ['State', 'EventNumber'],
      },
    ];
  }

  updateFormValue(): void {
    super.updateFormValue();
    if (this.entity) {
      this.isShowExternalRegistration = this.entity.showExternalRegistration;
      this.onChangeShowExternalRegistration(this.isShowExternalRegistration);

      if (this.entity.speakers && this.form) {
        const Speakers = this.getSpeakersFromEntity();
        this.form.patchValue({ Speakers });
      }
      this.isLinkedToExistingOrg = !!this.entity.leadCompany?.id;

      if (this.entity.id) {
        this.loadPartnerOrgs();

        if (!this.isShowExternalRegistration) {
          this.loadStakeholders(() =>
            this.checkJoinEvent(this.joinEventComponent)
          );
        }

        this.initiators = [...this.entity?.initiators];
      }
    }
  }

  setInitators(items = []): void {
    this.initiators = [...items];
  }

  getSpeakersFromEntity(): any {
    const arrays: string[] = this.entity.speakers || [];
    return transformToTagModaByKey(arrays, !this.editable);
  }

  getFormFieldValueFromEntity(name: string): any {
    if (name === 'Speakers') {
      return this.getSpeakersFromEntity();
    } else {
      return super.getFormFieldValueFromEntity(name);
    }
  }

  getDto(): EventInterface {
    const result: EventInterface = super.getDto();
    if (this.form && this.form.getRawValue()) {
      Object.assign(result, this.getSpeakerDto());
      Object.assign(result, this.getImageSection());
      Object.assign(result, this.getShowExternalRegistration());
    }
    return result;
  }

  getShowExternalRegistration(): any {
    return {
      showExternalRegistration: this.isShowExternalRegistration,
    };
  }

  getDtoField(name: string, force = false): object {
    if (name === 'Stakeholders') {
      return { participants: this.getStakeholdersDto() };
    } else {
      return super.getDtoField(name, force);
    }
  }

  getSectionInfo(index: number): any {
    const section = this.formSections[index];

    if (section) {
      const result = super.getSectionInfo(index);
      if (section.fieldsName.includes('Speakers')) {
        Object.assign(result, this.getSpeakerDto());
      }
      if (section.fieldsName.includes('ShowExternalRegistration')) {
        Object.assign(result, this.getShowExternalRegistration());
      }
      // always get image id from form input
      Object.assign(result, this.getImageSection());
      return result;
    }
    return {};
  }

  formatField(dto: any, key: string): void {
    switch (key) {
      case 'attachments':
        const items = dto[key] || [];
        dto[key] = items.map((item) => {
          return item?.id;
        });
        break;

      case 'partnerOrganizations':
        const partnerOrgs: LeadCompany[] = dto[key] || [];
        dto[key] = partnerOrgs.map((partnerOrg) => {
          return {
            organizationId: partnerOrg.id,
            partnerStatus: partnerOrg.eventPartnerStatus?.codeId,
          };
        });
        break;

      case 'leadCompany':
        const leadCompany: LeadCompany = dto[key];
        dto[key] = {
          ...leadCompany,
          link: StringUtils.getCorrectUrl(leadCompany.link),
          logo: leadCompany.logo?.url,
        };

        break;

      default:
        super.formatField(dto, key);
    }
  }

  getSpeakerDto(): any {
    if (this.form.getRawValue().Speakers) {
      return {
        speakers: this.form.getRawValue().Speakers.map((item) => item.value),
      };
    }

    return {};
  }

  updateForm(orgFormValue: LeadCompany): void {
    this.form.patchValue({
      LeadCompany: orgFormValue,
      LeadCompanyLink: orgFormValue?.link,
      LeadCompanyLogo: orgFormValue?.logoId,
      LeadCompanyName: orgFormValue?.name,
    });
    if (orgFormValue) {
      FormUtils.markControlAsTouch(this.form, 'LeadCompany');
    }
  }

  getImageSection(): any {
    let imgId = 0;
    const imageValue = this.form?.value.Image;
    if (imageValue?.id) {
      imgId = imageValue.id;
    }

    return {
      imageId: imgId,
    };
  }

  markOrgFormAsTouched(): void {
    this.markOrgFormTouched = true;
  }

  showLeadOrgError(): boolean {
    const leadCompany = this.form?.controls?.LeadCompany;
    if (!leadCompany || !(leadCompany.touched || leadCompany.dirty)) {
      return false;
    }

    if (this.isLinkedToExistingOrg) {
      return !leadCompany.value?.id;
    }

    return !leadCompany.value;
  }

  reverseLinkToExistingOrg(): void {
    this.isLinkedToExistingOrg = !!this.entity?.leadCompany?.id;
  }

  afterEventPassed(): void {
    if (this.entity) {
      this.entity.isPassedEvent = true;
    }
  }

  afterJoinEvent(): void {
    if (this.entity) {
      this.entity.hasJoined = true;
      this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
      this.getLatestEntity(this.entity.id);
    }
  }

  onChangeShowExternalRegistration(result: boolean): void {
    if (this.form) {
      const registrationLinkControl = this.form.get('RegistrationLink');
      if (result) {
        if (this.stakeholders?.length > 0) {
          this.toastService.showError(
            'UI.EventEdit.ChangeExternalRegistrationError',
            '',
            2000
          );
          this.showExternalRegistrationToggle.toggle(false);
          return;
        }
        registrationLinkControl?.setValidators([Validators.required]);
      } else {
        registrationLinkControl?.clearValidators();
        registrationLinkControl?.setValue('');
      }
      registrationLinkControl?.updateValueAndValidity();
    }
  }

  openSendMessageToMember(event: EventInterface): void {
    if (!this.sessionService.isLogin || !event) {
      return;
    }
    const isEnableCommunication =
      this.sessionService.tenant.enableCommunication;

    const modalRef = this.modalService.open(NewMessageDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: `message-modal-custom ${
        !isEnableCommunication && 'service-not-available-dialog'
      }`,
    });

    const messagePayload: MessagePayload = {
      type: RecipientType.Event,
      ids: [event.id],
      isSendMail: this.isOwner,
    };
    modalRef.componentInstance.messagePayload = messagePayload;
    modalRef.componentInstance.description = 'UI.EventEdit.DirectMessage';
  }

  //#region STAKEHOLDERS
  loadStakeholders(callBack?: () => void): void {
    this.baseHttpService
      .paginateByEntity('/participants', this.entity?.id, {
        pageSize: maxPageSize,
      })
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        this.stakeholders = res?.items;
        this.isTeam = ArrayUtils.hasItem(this.stakeholders, this.profile, 'id');
        this.participantsWithProfile = this.stakeholders.filter(
          (item) => !item.isUnregisteredUser
        );
        this.participantsWithoutProfile = this.stakeholders.filter(
          (item) => item.isUnregisteredUser
        );
        if (callBack) {
          setTimeout(() => {
            callBack();
          });
        }
      });
  }

  updateParticipantWithProfile(items: UserInterface[]): void {
    const itemsToUpdate = [...items, ...this.participantsWithoutProfile];
    this.updateStakeholder(itemsToUpdate);
  }

  updateParticipantWithoutProfile(items: UserInterface[]): void {
    const itemsToUpdate = [...items, ...this.participantsWithProfile];
    this.updateStakeholder(itemsToUpdate);
  }

  updateStakeholder(items: UserInterface[]): void {
    FormUtils.removeError(this.form, 'formError');
    this.isSubmitting = false;
    this.isSubmitted = false;

    const updatedstakeHolders = items ? items : [];
    this.stakeholders = [...updatedstakeHolders];

    if (!this.isCreating) {
      this.saveSection(EventSectionIndex.Stakeholders)
        .pipe(untilDestroyed(this))
        .subscribe((res: boolean) => {
          if (!res && this.entity) {
            this.getLatestEntity(this.entity.id);
          }
        });
    } else {
      FormUtils.maskFormAsTouched(this.form);
    }
  }

  getStakeholdersDto(): number[] {
    return this.stakeholders?.map((item) => {
      return item.id;
    });
  }
  //#endregion End Stakeholders

  //#region PARTNER ORGANIZATIONS
  loadPartnerOrgs(): void {
    this.baseHttpService
      .paginateByEntity('/org-partners', this.entity?.id, {
        pageSize: maxPageSize,
      })
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        res?.items.forEach((item) => {
          if (!item.name) {
            item.name = item.orgName;
          }
          if (!item.url) {
            item.url = item.orgUrl;
          }
        });
        this.partnerOrganizations = res?.items;
      });
  }
  //#endregion End Partner Organizations

  isSendMessToMemberShown(): boolean {
    if (!this.isCreating) {
      return this.stakeholders?.length > 0 && this.canSendMessage();
    }
    return false;
  }

  cancelSection(index: number): void {
    super.cancelSection(index);
    if (index === EventSectionIndex.General) {
      this.isShowExternalRegistration = this.entity?.showExternalRegistration;
      this.onChangeShowExternalRegistration(this.isShowExternalRegistration);
      this.eventPayment.isAdvancedSettingOpen = false;
    }
    if (index === EventSectionIndex.Privacy) {
      const isPrivateControl = this.form?.get('IsPrivate');
      if (isPrivateControl) {
        isPrivateControl.setValue(this.entity?.isPrivate);
        isPrivateControl.updateValueAndValidity();
      }
    }
  }

  onLeadOrgSelectedEvent(): void {
    let formValue;
    if (this.form) {
      formValue = this.form.getRawValue();
    }
    if (this.isCreating && this.form && !environment.production) {
      const leadCompanyId = formValue?.LeadCompany?.id?.toString();
      super.generateCustomAttributeFormWhenCreate(leadCompanyId);
    }
    this.paymentApiKey = formValue?.LeadCompany?.apiKey;

    if (formValue?.LeadCompany?.id) {
      this.isLeadOrgExternalOrg = false;
    } else {
      this.isLeadOrgExternalOrg = true;
    }
  }

  updateOrganisationPayload(values: LeadCompany[]): void {
    this.form.patchValue({
      PartnerOrganizations: values,
    });
  }

  openExportPopup(eventId: number): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const modalRef = this.modalService.open(EventExportComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: 'message-modal-custom',
    });

    modalRef.componentInstance.entityId = eventId;
  }

  changeEventPrivacy(index: number): Observable<boolean> {
    let apiPath = '';
    const isPrivate = this.form.get('IsPrivate')?.value;
    if (isPrivate) {
      apiPath = '/unpublish';
    } else {
      apiPath = '/publish';
    }
    return new Observable((observer) => {
      this.baseHttpService
        .postByEntity(this.entity?.id, null, apiPath)
        .pipe(
          untilDestroyed(this),
          concatMap(() => this.saveSection(index)),
          catchError(() => {
            this.handleApiCommonProcess(observer, false);
            return of(undefined);
          })
        )
        .subscribe((res) => {
          if (res !== undefined) {
            this.removeEditingSectionIndex(index);
            this.handleApiCommonProcess(observer, true);
            this.getLatestEntity(this.entity.id);
          }
        });
    });
  }

  isInviteSectionDisabled(event: EventInterface): boolean {
    const disabledState: string[] = [
      EventState.FullyBooked,
      EventState.Cancelled,
      EventState.Passed,
      EventState.Created,
      EventState.Approved,
    ];

    return (
      disabledState.includes(event.state?.codeId) ||
      this.entity.isFullyBooked ||
      this.entity.isPassedEvent
    );
  }

  onSearchPeople(event: string): void {
    this.filteringKeyword = event;
  }

  onPeopleAdded(): void {
    this.filteringKeyword = '';
  }

  /**
   * Auto join event when stackHolder loaded
   */
  checkJoinEvent(event: JoinEventComponent): void {
    const register = StringUtils.getParamFromUrl(UrlParam.RegisterEvent);

    if (register) {
      event?.join({ preventDefault(): void {} } as Event, this.entity);
    }

    StringUtils.removeParamFromUrl(UrlParam.RegisterEvent, null, true);

    // Clear method after first calling
    this.checkJoinEvent = () => undefined;
  }

  protected afterFormChangedStatus(): void {
    this.isNotShownPhysicalLocation = this.getIsNotShownPhysicalLocation();
  }

  protected handleSyncEntityStateCount(): void {
    this.syncLikingsState();
    this.syncFollowingsState();
    this.syncCommentsState();
    this.syncEntityJoinStates();
    this.syncIsFullyBookedState();
    this.syncIsPassedEventState();
    this.syncIsCancelledEventState();
  }

  private syncIsFullyBookedState(): void {
    this.entity.isFullyBooked =
      !this.isCreating &&
      this.entity &&
      this.entity.id &&
      ((this.entity.totalPlaces !== 0 && this.entity.availableSeats === 0) ||
        this.entity.state?.codeId === EventState.FullyBooked);
  }

  private syncIsPassedEventState(): void {
    const eventStartDate = new Date(this.entity.eventDateTime);
    const eventLengh =
      this.entity.eventLength ||
      CustomMetadataUtils.getAttributeValueByPropertyName(
        this.entity.attributeDescriptions,
        this.entity.attributeValues,
        'EventLength'
      ) ||
      0;
    const eventEndDate = TimeUtils.isValidDate(eventStartDate)
      ? TimeUtils.addHoursToDate(eventStartDate, eventLengh)
      : null;

    this.entity.isPassedEvent =
      TimeUtils.isValidDate(eventEndDate) &&
      new Date().getTime() > eventEndDate.getTime();
  }

  private getIsNotShownPhysicalLocation(): boolean {
    const shouldNotShow = !this.isCreating && !this.isOwner;
    if (this.entity?.location) {
      return (
        shouldNotShow && !this.isValidPhysicalLocation(this.entity?.location)
      );
    }
    return shouldNotShow;
  }

  private isValidPhysicalLocation(jsonLocation: string): boolean {
    try {
      const location: LocationInfo = JSON.parse(jsonLocation);
      return !!location.address && !!location.name;
    } catch (error) {
      return false;
    }
  }

  private syncIsCancelledEventState(): void {
    this.entity.isCancelled =
      this.entity.state?.codeId === EventState.Cancelled;
  }

  handleOnSubmit() {
    this.onSubmit();
    this.markOrgFormAsTouched();
  }

  canSendMessage(): boolean {
    if (!this.sessionService.isLogin) return true;
    let messageRight = this.entity.sendMessageRights;

    if (typeof messageRight === 'object') {
      messageRight = (this.entity.sendMessageRights as CustomAtrributeValue)
        .codeId;

      switch (messageRight) {
        case EventMessageRights.Everybody:
        case EventMessageRights.AdminAndCommunity:
          return this.isOwner || this.isTeam;
        case EventMessageRights.AdminOnly:
          return this.isOwner;
        default:
          return false;
      }
    } else {
      return true;
    }
  }

  addCustomVariableDto(dto: any) {
    const customDto: any = this.getPaymentDto();

    if (
      customDto &&
      'payment' in customDto &&
      this.form.getRawValue().LeadCompany?.id
    ) {
      return { ...dto, ...customDto };
    } else {
      return dto;
    }
  }

  getPaymentDto() {
    let dto = {};
    dto = this.eventPayment?.getPaymentDto();
    return dto;
  }

  getPaymentToken(id?: number) {
    return this.baseHttpService.getPaymentToken(id ? { id } : {});
  }

  saveSection(index: number): Observable<boolean> {
    if (index !== this.eventSectionIndex.General) {
      if (index === this.eventSectionIndex.Organizer) {
        return super.saveSection(index).pipe(
          finalize(() => {
            this.eventPayment.isAdvancedSettingOpen = false;
          })
        );
      }

      return super.saveSection(index);
    }

    this.setPaymentDto();

    return this.finalizeSectionSave(index);
  }

  private setPaymentDto(): void {
    const dto = this.getPaymentDto();
    if (!dto) return;
    if (this.entity.leadCompany?.id && !this.form.getRawValue().LeadCompany?.id)
      return;
    this.form.get('Payment')?.setValue(dto['payment']);
  }

  private finalizeSectionSave(index: number): Observable<boolean> {
    return super.saveSection(index).pipe(
      finalize(() => {
        this.eventPayment.isAdvancedSettingOpen = false;
        this.eventPayment.isEditParameterOn = false;
      })
    );
  }

  handleAfterGetEntityDetail(entity: EventInterface): void {
    super.handleAfterGetEntityDetail(entity);
    if (!entity.hasPayment) {
      this.paymentApiKey = entity.apiKey;
    }
  }

  shouldShowEventPayment(): boolean {
    if (this.isShowExternalRegistration) {
      return false;
    } else {
      if (this.isCreating) {
        return !this.isLeadOrgExternalOrg;
      } else {
        return Boolean(this.entity?.leadCompany?.id);
      }
    }
  }

  getDefaultEventDateTime(): string {
    const now = moment(this.creationDate);
    const tomorrow = moment(this.creationDate).add(1, 'day');

    tomorrow.set({
      hour: now.hour(),
      minute: now.minute(),
      second: now.second(),
      millisecond: now.millisecond(),
    });

    return tomorrow.toISOString();
  }
}
