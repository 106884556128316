import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PopupBlockerDetectionService {
  isPopupBlocked$ = new BehaviorSubject(false);

  handlePopupBlockerDetection(window: Window) {
    const isProcessTokenPage = location.origin.includes(
      environment.jipUrl.processToken
    );

    if (isProcessTokenPage) return;

    if (!window || window.closed || typeof window.closed == 'undefined') {
      this.isPopupBlocked$.next(true);
    }
  }
}
