import { ChangeDetectorRef, Component } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TimestampsService } from '@src/app/core/http/timestamps.service';
import { VentureHttpService } from '@src/app/core/http/venture.http.service';
import { SessionService } from '@src/app/core/session.service';
import { RELATED_ENTITY_SUPPORTED_TO_EDIT_IN_VENTURE_TABLE } from '@src/app/shared/constants/metadata-table.const';
import { PatternType } from '@src/app/shared/enums/attribute-type.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { VenturePropertyName } from '@src/app/shared/enums/property-name.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import {
  TimestampModel,
  TimestampPaginationPayload,
} from '@src/app/shared/interfaces/timestamp.interface';
import { VentureInterface } from '@src/app/shared/interfaces/venture.interface';
import { MetadataTableComponent } from '../metadata-table.component';

@Component({
  selector: 'app-metadata-table-venture',
  templateUrl: '../metadata-table.component.html',
})
export class MetadataTableVentureComponent extends MetadataTableComponent<VentureInterface> {
  entityName = EntityName.Venture;

  ventureFilterPropertyName: string[] = [
    'Id',
    'Title',
    VenturePropertyName.SubmittedOrganizations,
    'SubmissionPhase',
    VenturePropertyName.Initiator,
    VenturePropertyName.Stakeholders,
    VenturePropertyName.PendingStakeholders,
    'Status',
    'ProjectType',
    'StartDate',
    'Deadline',
    'LookingFor',
    'TermsAndConditionsConfirmation',
    'BachelorThesisOfNotAParttimeStudent',
    'TimestampCreator',
    'IsPrivate',
    'Phase',
    'CurrentSubmission',
    'CbChartaPhase',
  ];
  readonlyField = ['Id'];

  constructor(
    public baseHttpService: VentureHttpService,
    protected sessionService: SessionService,
    protected modalService: NgbModal,
    protected cd: ChangeDetectorRef,
    protected timestampsService: TimestampsService
  ) {
    super(sessionService, baseHttpService, cd);
  }

  protected haveOrganizationAdminPermission(
    attrDes: AttributeDescription,
    venture: VentureInterface
  ): boolean {
    return (
      venture?.isOrganizationAdmin &&
      (attrDes?.type === PatternType.Dedicated ||
        // For role only
        attrDes.propertyName === VenturePropertyName.Initiator ||
        attrDes.propertyName === VenturePropertyName.Stakeholders)
    );
  }

  hasEditPermission(attrDes: AttributeDescription, entityId: number): boolean {
    const entity = this.entityDatas.find((x) => x.id === entityId);
    const canEdit = super.hasEditPermission(attrDes, entityId);

    if (attrDes.propertyName === VenturePropertyName.IsPrivate) {
      return canEdit && this.isOwner(entity);
    }

    if (attrDes.propertyName === VenturePropertyName.CurrentSubmission) {
      return canEdit && this.isParentEntityOwner;
    }

    return (
      canEdit &&
      (this.isOwner(entity) ||
        this.isTeam(entity) ||
        this.haveOrganizationAdminPermission(attrDes, entity))
    );
  }

  hasCreateTimeStampPermission(entityId: number): boolean {
    const entity = this.entityDatas.find((x) => x.id === entityId);
    return (
      this.isOwner(entity) || this.isTeam(entity) || entity?.isOrganizationAdmin
    );
  }

  isSendMessToMemberShown(entityId: number): boolean {
    const currentEntity: VentureInterface = this.entityDatas.find(
      (x) => x.id === entityId
    );
    if (currentEntity) {
      const isOwner =
        currentEntity.initiator?.id === this.sessionService.currentUser?.id;
      if (isOwner) {
        return currentEntity.stakeholders?.length > 0;
      }
      return true;
    }
    return false;
  }

  getBooleanTypeValue(value: boolean | string, propertyName = ''): string {
    if (propertyName === 'IsPrivate') {
      return value ? 'Private' : 'Public';
    } else {
      return super.getBooleanTypeValue(value, propertyName);
    }
  }

  afterCreateTimestamp(entityId: number, rowIndex: number): void {
    const payload: TimestampPaginationPayload = {
      pageIndex: 0,
      pageSize: 1,
      entityName: this.entityName,
      entityId,
    };
    this.timestampsService
      .paginate(payload)
      .pipe(untilDestroyed(this))
      .subscribe((res: ApiGetResponse<TimestampModel>) => {
        if (res?.items) {
          const latestTimestamp = res.items[0];
          super.onValueUpdated(latestTimestamp, 'TimestampCreator', rowIndex);
        }
      });
  }

  canEditCustomContition(
    attrDes: AttributeDescription,
    value: any,
    row: any
  ): boolean {
    if (attrDes.propertyName === VenturePropertyName.Initiator) {
      return !!value;
    }
    if (attrDes.propertyName === VenturePropertyName.Stakeholders) {
      return !!value && Array.isArray(value) && value.length > 0;
    }
    if (attrDes.propertyName === VenturePropertyName.Phase) {
      return !row.CbChartaPhase;
    }
    return true;
  }

  ditCell(
    popover: NgbPopover,
    entityId: number,
    attrDes: AttributeDescription,
    event: Event
  ): void {
    const row = this.rows.find((x) => x.Id === entityId);

    if (attrDes.propertyName === VenturePropertyName.Phase && row.CbChartaPhase)
      return;

    super.editCell(popover, entityId, attrDes, event);
  }

  protected isSupportedRelatedEntityType(
    attrDes: AttributeDescription
  ): boolean {
    return attrDes.attributeType === this.AttributeType.RelatedEntity
      ? this.isMultiStringRelatedEntity(attrDes) ||
          RELATED_ENTITY_SUPPORTED_TO_EDIT_IN_VENTURE_TABLE.includes(
            attrDes.propertyName
          )
      : false;
  }

  protected getDefaultLstFilterPropertyName(): string[] {
    return this.ventureFilterPropertyName;
  }

  protected canShowColumn(propertyName: string): boolean {
    return true;
  }

  protected customUpdateColumn(resultColumn: Record<string, any>): void {
    if (resultColumn.propertyName === 'TimestampCreator') {
      resultColumn.width = 200;
    }
  }

  protected shouldNotEditOnPopover(attrDes: AttributeDescription): boolean {
    return (
      super.shouldNotEditOnPopover(attrDes) ||
      attrDes.propertyName === VenturePropertyName.Initiator ||
      attrDes.propertyName === VenturePropertyName.Stakeholders
    );
  }

  protected shouldNotOpenEntityDetailAsNewTab(
    attrDes: AttributeDescription
  ): boolean {
    const propertyNames: string[] = [
      VenturePropertyName.PendingStakeholders,
      VenturePropertyName.SubmittedOrganizations,
      VenturePropertyName.TimestampCreator,
    ];
    return propertyNames.includes(attrDes.propertyName);
  }
}
