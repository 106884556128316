import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-radio-selection',
  templateUrl: './radio-selection.component.html',
})
export class RadioSelectionComponent {
  @Input() editable: boolean;
  @Input() items = [];
  @Input() currentIndex: number;
  @Input() styleClass = '';
  @Input() controlName = '';
  @Input() horizontalSingleSelection: boolean = false;
  @Input() isViewAsText: boolean = false;

  @Output() selectItem = new EventEmitter();
  @Output() currentIndexChange = new EventEmitter();

  onRadioChange(event, item) {
    this.selectItem.emit(item);
    this.currentIndexChange.emit(this.currentIndex);
  }
}
