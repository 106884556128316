import { Component } from '@angular/core';
import { FormFieldCustomComponent } from '@src/app/shared/components/form-custom-attributes/form-field-custom/form-field-custom.component';
import { OrganizationPrivacy } from '@src/app/shared/enums/organization.enum';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-organization-privacy',
  templateUrl: './organization-privacy.component.html',
})
export class OrganizationPrivacyComponent extends FormFieldCustomComponent {
  portalName: string = environment.portalName;
  OrganizationPrivacy = OrganizationPrivacy;

  onRadioChange(event, codeId: string) {
    const selectedItem = this.selectionChoices.find(
      (item) => item.codeId === codeId
    );

    this.setValue(selectedItem);
  }
}
