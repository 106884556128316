import { Injectable } from '@angular/core';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { PopupBlockerDetectionService } from '@src/app/core/services/popup-blocker-detection.service';
import { SessionService } from '@src/app/core/session.service';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { TimeUtils } from '@src/app/shared/utils/time-utils';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventPaymentService {
  constructor(
    private sessionService: SessionService,
    private eventHttpService: EventHttpService,
    private popupBlockerDetectionService: PopupBlockerDetectionService
  ) {}

  handleEventPayment(event: EventInterface) {
    const targetDateTimeZoneOffset = TimeUtils.getTargetDateTimezone(
      event.eventDateTime
    );

    this.eventHttpService
      .checkout(event.id, { targetTimezone: targetDateTimeZoneOffset })
      .subscribe({
        next: (res: string) => {
          const registerEventUrl = this.sessionService.appendLanguagePath(
            `${environment.jipUrl.event}/${event.id}`
          );

          this.sessionService.setLoginCallBackpage(registerEventUrl);
          const newWin = window.open(res, '_blank');
          const popupBlocked =
            !newWin || newWin.closed || typeof newWin.closed == 'undefined';
          this.popupBlockerDetectionService.handlePopupBlockerDetection(newWin);

          this.goBack(popupBlocked);
        },
      });
  }

  private goBack(isPopupBlocked = false): void {
    let url = this.sessionService.getLoginCallBackpage() || location.origin;
    
    if(isPopupBlocked) {
      url = url + `?${UrlParam.PopUpBlocked}=true`
    }
    
    FormUtils.navigateTo(url);
  }
}
