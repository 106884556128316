<ng-container *ngIf="attributeDescription">
  <div [formGroup]="formInput">
    <div class="organization__radio-item">
      <input
        name="OrganizationPrivacy"
        [id]="OrganizationPrivacy.PublicOrg"
        type="radio"
        class="organization__radio"
        [value]="OrganizationPrivacy.PublicOrg"
        [class.disabled-input]="!editable"
        [checked]="
          selectionChoices[selectedIndex]?.codeId ===
          OrganizationPrivacy.PublicOrg
        "
        (change)="onRadioChange($event, OrganizationPrivacy.PublicOrg)"
      />
      <label
        [for]="OrganizationPrivacy.PublicOrg"
        class="radio-info d-flex flex-column"
        [class.disabled-action]="!editable"
      >
        <span class="radio-text">
          <i class="fas fa-globe-europe"></i>
          {{ 'Public' | translate }}
        </span>

        <span
          class="radio-hint"
          [innerHtml]="
            'UI.ORG-DETAIL.Public-org-hint'
              | translate
              | stringReplacement
                : {
                    '{portalName}': '#' | linkWrapper : portalName
                  }
          "
        ></span>
      </label>
    </div>
    <div class="organization__radio-item">
      <input
        name="OrganizationPrivacy"
        [id]="OrganizationPrivacy.PrivateOrg"
        class="organization__radio"
        type="radio"
        [value]="OrganizationPrivacy.PrivateOrg"
        [class.disabled-input]="!editable"
        [checked]="
          selectionChoices[selectedIndex]?.codeId ===
          OrganizationPrivacy.PrivateOrg
        "
        (change)="onRadioChange($event, OrganizationPrivacy.PrivateOrg)"
      />
      <label
        [for]="OrganizationPrivacy.PrivateOrg"
        class="radio-info d-flex flex-column"
        [class.disabled-action]="!editable"
      >
        <span class="radio-text">
          <i class="fas fa-lock"></i>
          {{ 'Private' | translate }}
        </span>
        <span class="radio-hint radio-hint--warning">
          {{ 'UI.ORG-DETAIL.Private-org-hint' | translate }}
        </span>
      </label>
    </div>
    <div class="organization__radio-item">
      <input
        name="OrganizationPrivacy"
        id="HiddenOrg"
        class="organization__radio"
        type="radio"
        [value]="OrganizationPrivacy.HiddenOrg"
        [class.disabled-input]="!editable"
        [checked]="
          selectionChoices[selectedIndex]?.codeId ===
          OrganizationPrivacy.HiddenOrg
        "
        (change)="onRadioChange($event, OrganizationPrivacy.HiddenOrg)"
      />
      <label
        for="HiddenOrg"
        class="radio-info d-flex flex-column"
        [class.disabled-action]="!editable"
      >
        <span class="radio-text">
          <i class="far fa-eye-slash"></i>
          {{ 'Hidden' | translate }}
        </span>
        <span class="radio-hint">
          Only members and all admins can see the organization.
        </span>
      </label>
    </div>
  </div>
</ng-container>
