<app-organization-card-list
  [editting]="editing"
  [displayItems]="organisations"
  [emptyPlacholderKey]="
    organizationType === organizationTypeEnum.Challenge
      ? 'UI.ORG.Partner-challenges-empty'
      : 'UI.ORG.Partner-organizations-empty'
  "
  [showVenturesCtrl]="showVenturesCtrl"
  (itemRemoved)="removeItem($event)"
  [advancedSetting]="advancedSetting"
  [ignoreCompany]="ignoreCompany"
>
</app-organization-card-list>
