<app-unsaved-form-check
  [unsavedFormCheckService]="unsavedFormCheckService"
></app-unsaved-form-check>

<ng-container *ngIf="isProcessingPayment">
  <div class="w-100 text-center">
    <i class="fas fa-spinner fa-spin"></i>
  </div>
</ng-container>

<ng-container
  *ngIf="!isLoadingEntity && !isGoingToOtherPage && !isProcessingPayment"
>
  <form *ngIf="form" [formGroup]="form" class="venture-edit event-edit">
    <div
      *ngIf="form"
      [class.pb-3]="!isCreating"
      class="venture__wrapper venture-details"
    >
      <div class="w-100 py-1 px-3 d-flex flex-row-reverse background-white">
        <app-language-indicator *ngIf="isCreating"></app-language-indicator>
      </div>

      <div class="venture__header">
        <ng-container *ngIf="!isCreating">
          <div
            class="venture__publishing justify-content-between align-items-center"
          >
            <app-privacy-flag
              [isPrivate]="entity?.isPrivate"
            ></app-privacy-flag>

            <div class="d-flex align-items-center">
              <ng-container
                *ngIf="isShowExternalRegistration && entity?.registrationLink"
              >
                <div [ngbTooltip]="entity | eventRegisterTooltip | translate">
                  <div class="event-register-btn">
                    <a
                      [class.disabled-input]="entity | eventRegisterBtnDisabled"
                      [class.disable-register]="
                        entity | eventRegisterBtnDisabled
                      "
                      appUnsavedFormCheck
                      [value]="entity?.registrationLink"
                      target="_blank"
                      class="btn btn-interact btn-interact--big btn-primary mr-2"
                      appUrlPrefix
                    >
                      <i class="fas fa-file-medical"></i>
                      <span class="text-hide-small">{{
                        'UI.Event.ExternalRegistration' | translate
                      }}</span>
                    </a>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="!isShowExternalRegistration">
                <app-join-event
                  #jointEvent
                  [entity]="entity"
                  [entityName]="entityName"
                  [baseHttpService]="baseHttpService"
                  (afterEventPassed)="afterEventPassed()"
                  (afterJoined)="afterJoinEvent()"
                  (leaveEntity)="afterLeaveEntity($event)"
                  [isOwner]="isOwner"
                >
                </app-join-event>

                <ng-container *ngIf="!isCreating && isTeam">
                  <button
                    appLeaveEntity
                    [entityId]="entity.id"
                    [entityName]="entityName"
                    [(isLeaving)]="isLeavingEntity"
                    [disabled]="isLeavingEntity"
                    (afterLeaved)="afterLeaveEntity($event)"
                    class="btn btn-interact btn-interact--big btn-outline-primary ml-2"
                  >
                    <i class="fas fa-user-times"></i>
                    <span
                      class="text-hide-small"
                      [innerHTML]="
                        'UI.Common.LeaveThis'
                          | translate
                          | stringReplacement
                            : {
                                '{entity}': (entityName | translate)
                              }
                      "
                    >
                    </span>
                  </button>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="isOwner && !isCreating">
                <button
                  type="button"
                  class="btn btn-interact btn-interact--big btn-outline-primary ml-2"
                  appRequireLogin
                  appDebounceClick
                  (debounceClick)="openExportPopup(entity.id)"
                >
                  <i class="fas fa-file-export"></i>
                  <span class="text-hide-small">
                    {{ 'UI.Common.Export' | translate }}</span
                  >
                </button>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <div
          *ngIf="!isCreating && editable"
          [class.alert-danger]="imageControl?.isInvalid"
          [class.bg-white]="!imageControl?.isInvalid"
          class="upload-file__container justify-content-between shadow-none w-100"
        >
          <div class="event-edit__image-upload--primary btn-primary">
            <app-form-field-custom
              #imageControl
              styleClass="fa-image text-white fill-container small-image-upload-btn"
              [attributeDescription]="getFieldOptions('Image')"
              [formInput]="getFormSectionIndex('Image')"
              [language]="getLocaleBySectionKey('Image')"
              [options]="{
                id: form?.value?.Image?.id,
                multiLocale: false
              }"
              [allowCropImg]="true"
              [aspectRatio]="bannerImageCropRatio"
            >
            </app-form-field-custom>
          </div>

          <app-language-switcher
            (languageSelected)="onLanguageChange($event, 'Image')"
            (languageRemoved)="onRemoveLanguage('Image')"
            [className]="'mr-3'"
          ></app-language-switcher>
        </div>

        <app-image-wrapper
          imgClass="widget__image venture__image"
          [imgAlt]="getFormSectionIndex('Image').value?.title"
          [imgObject]="getFormSectionIndex('Image').value?.Image"
        >
          <ng-container *ngIf="!isCreating">
            <app-event-stamp [event]="entity"></app-event-stamp>
            <app-event-location-flag
              [event]="entity"
              [uiPosition]="eventLocationFlagPos"
            ></app-event-location-flag>
          </ng-container>
        </app-image-wrapper>

        <div class="venture__title-wrapper">
          <app-form-section
            #sectionTitle
            [index]="eventSectionIndex.Title"
            [canSave]="isValidSection(eventSectionIndex.Title)"
            [showEditButton]="!isCreating && editable"
            [saveSectionCallback]="saveSection.bind(this)"
            (cancelSection)="cancelSection($event)"
            (editIndexChange)="markEditingSectionIndex($event)"
          >
            <app-form-field-custom
              [formInput]="form"
              [editable]="isCreating || (editable && sectionTitle.editting)"
              [attributeDescription]="getFieldOptions('Title')"
              [options]="{ placeholder: 'UI.Event.Name' | translate }"
            >
            </app-form-field-custom>
          </app-form-section>
        </div>
        <div
          *ngIf="isCreating"
          [class.alert-danger]="imageControl?.isInvalid"
          [class.bg-white]="!imageControl?.isInvalid"
          class="upload-file__container event-edit__upload-image--position-creating position-absolute"
          [ngClass]="{
            'extend-container': !form?.value?.Image?.url
          }"
        >
          <app-form-field-custom
            #imageControl
            class="upload-file__control text-center"
            [formInput]="form"
            [attributeDescription]="getFieldOptions('Image')"
            [options]="{ id: form?.value?.Image?.id, multiLocale: true }"
            styleClass="upload-file__big-upload-btn"
            [allowCropImg]="true"
            [aspectRatio]="bannerImageCropRatio"
            [customUploadButtonTemplate]="customUploadButtonTemplate"
          >
          </app-form-field-custom>
          <ng-template #customUploadButtonTemplate>
            <div
              class="card w-fit mr-auto mb-0 upload-file__big-upload-btn extend-container d-flex align-items-center flex-row"
              [ngClass]="{
                'alert alert-danger': imageControl?.isInvalid,
                'extend-container': !form?.value?.Image?.url
              }"
            >
              <i
                class="fas fa-camera fa-lg"
                [ngClass]="{ 'upload-file__icon': !imageControl?.isInvalid }"
              ></i>
              <span *ngIf="!form?.value?.Image?.url">{{
                'UI.UploadImageButton.FieldIsRequired' | translate
              }}</span>
            </div>
          </ng-template>
        </div>
      </div>

      <ng-container *ngIf="!isCreating">
        <app-interaction-bar
          entityPath="{{ entityBase }}/{{ entity.id }}"
          [httpService]="baseHttpService"
          [entity]="entity"
          [entityName]="entityName"
          [isLoadingLikingsCount]="isLoadingLikingsCount"
          [isLoadingFollowingsCount]="isLoadingFollowingsCount"
          [isLoadingCommentsCount]="isLoadingCommentsCount"
          [hideShareIcon]="entity.isPrivate"
          (triggerToggleLike)="toggleLike(entity)"
          (triggerShowLikingsData)="
            showEntityData(); showLikesModal = !!entity?.likesCount
          "
          (triggerShowFollowingsData)="
            showEntityData(); showFollowersModal = true
          "
          (afterCommentModalClosed)="onCommentModalClosed()"
          (triggerToggleFavorite)="toggleFavorite()"
        >
          <div class="d-flex">
            <button
              *ngIf="entity?.showExternalRegistration || entity?.hasJoined"
              appAddToCalendar
              [event]="entity"
              class="btn btn-interact btn-interact--small btn-secondary-dark text-white"
            >
              <i class="fas fa-calendar-plus"></i>
              <span class="text-hide-small">
                {{ 'UI.Event.AddToCalendar' | translate }}
              </span>
            </button>
          </div>
        </app-interaction-bar>
      </ng-container>

      <!-- General -->
      <div class="venture__body widget__card--background">
        <app-form-section
          #sectionGeneral
          [index]="eventSectionIndex.General"
          [canSave]="isValidSection(eventSectionIndex.General)"
          [showEditButton]="!isCreating && editable"
          [saveSectionCallback]="saveSection.bind(this)"
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="row">
            <div class="col-12 col-md-6 mb-3">
              <app-form-field-custom
                [formInput]="form"
                [editable]="isCreating || (editable && sectionGeneral.editting)"
                [showLabel]="true"
                [attributeDescription]="getFieldOptions('EventDateTime')"
                [options]="{
                  shouldUseDefaultDate: true,
                  defaultDateTime: getDefaultEventDateTime()
                }"
              ></app-form-field-custom>
            </div>

            <div class="col-12 col-md-6 mb-3">
              <app-form-field-custom
                class="group-item group-input"
                [formInput]="form"
                [editable]="isCreating || (editable && sectionGeneral.editting)"
                [showLabel]="true"
                [attributeDescription]="getFieldOptions('Price')"
              >
                <div
                  part="prefix"
                  class="event__price-unit"
                  [class.w-auto]="
                    !(isCreating || (editable && sectionGeneral.editting))
                  "
                >
                  <app-form-field-custom
                    class="dropdown--full-width"
                    [formInput]="form"
                    [editable]="
                      isCreating || (editable && sectionGeneral.editting)
                    "
                    [attributeDescription]="getFieldOptions('PriceUnit')"
                  >
                  </app-form-field-custom>
                </div>
              </app-form-field-custom>
            </div>

            <div class="col-12 col-md-6 mb-3">
              <div class="event__length">
                <app-form-field-custom
                  [formInput]="form"
                  [editable]="
                    isCreating || (editable && sectionGeneral.editting)
                  "
                  [showLabel]="true"
                  [attributeDescription]="getFieldOptions('EventLength')"
                >
                  <span
                    part="postfix"
                    class="event__length--unit"
                    [class.position-static]="
                      !(isCreating || (editable && sectionGeneral.editting))
                    "
                    >h</span
                  >
                </app-form-field-custom>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-3">
              <div class="event__language">
                <app-form-field-custom
                  class="dropdown--full-width"
                  [formInput]="form"
                  [editable]="
                    isCreating || (editable && sectionGeneral.editting)
                  "
                  [showLabel]="true"
                  [attributeDescription]="getFieldOptions('Language')"
                >
                </app-form-field-custom>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-3">
              <app-form-field-custom
                [formInput]="form"
                [editable]="isCreating || (editable && sectionGeneral.editting)"
                [type]="allType.MultiString"
                [showLabel]="true"
                [options]="{ placeholder: 'Enter Speaker' | translate }"
                [attributeDescription]="getFieldOptions('Speakers')"
              >
              </app-form-field-custom>
            </div>

            <div class="col-12 col-md-6 mb-3 event__seats">
              <div class="venture__label widget__label">
                {{ 'UI.EventEdit.Seats' | translate }}
              </div>
              <app-form-field-custom
                [formInput]="form"
                [editable]="
                  isShowExternalRegistration &&
                  (isCreating || (editable && sectionGeneral.editting))
                "
                [attributeDescription]="getFieldOptions('AvailableSeats')"
              >
                <div part="postfix">
                  <app-form-field-custom
                    [formInput]="form"
                    [editable]="
                      isCreating || (editable && sectionGeneral.editting)
                    "
                    [attributeDescription]="getFieldOptions('TotalPlaces')"
                  >
                    <span part="prefix"> / </span>
                  </app-form-field-custom>
                </div>
              </app-form-field-custom>
            </div>

            <ng-container *ngIf="isCreating || isOwner">
              <div class="col-12 mb-3">
                <div class="venture__label widget__label venture__nda-required">
                  <div class="mb-2 pb-1">
                    {{
                      getFieldOptions('ShowExternalRegistration')?.displayName
                    }}
                  </div>
                  <div>
                    <app-toggle-button-slider
                      #showExternalRegistrationToggle
                      [(status)]="isShowExternalRegistration"
                      (statusChange)="onChangeShowExternalRegistration($event)"
                      [settings]="{
                        disabled: !(
                          isCreating ||
                          (editable && sectionGeneral?.editting)
                        ),
                        activeText: 'Yes' | translate,
                        deactiveText: 'No' | translate
                      }"
                    >
                    </app-toggle-button-slider>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="isShowExternalRegistration">
                <div class="col-12 mb-3">
                  <app-form-field-custom
                    [formInput]="form"
                    [editable]="
                      isCreating || (editable && sectionGeneral.editting)
                    "
                    [attributeDescription]="getFieldOptions('RegistrationLink')"
                    [options]="{
                      placeholder: 'UI.Event.RegistrationLink' | translate
                    }"
                  >
                  </app-form-field-custom>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="shouldShowEventPayment()">
              <app-event-payment
                #eventPayment
                [id]="id"
                [form]="form"
                [attributeDescription]="getFieldOptions('Payment')"
                [event]="entity"
                [isCreating]="isCreating"
                [httpService]="baseHttpService"
                [editting]="editable && sectionGeneral.editting"
                [isAdmin]="isOwner"
                [entity]="entity"
                [userId]="profile?.id"
                [paymentApiKey]="paymentApiKey"
              ></app-event-payment>
            </ng-container>
          </div>
        </app-form-section>
      </div>

      <!-- Privacy -->
      <div
        class="venture__body widget__card--background"
        *ngIf="isCreating || isOwner"
      >
        <app-form-section
          #sectionPrivacy
          [index]="eventSectionIndex.Privacy"
          [canSave]="isValidSection(eventSectionIndex.Privacy)"
          [showEditButton]="!isCreating && editable"
          [saveSectionCallback]="
            changeEventPrivacy.bind(this, eventSectionIndex.Privacy)
          "
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="row">
            <div class="col-12">
              <div class="venture__label widget__label mb-3">
                {{ getFieldOptions('IsPrivate')?.displayName }}
              </div>
              <div [formGroup]="form">
                <div class="organization__radio-item">
                  <input
                    formControlName="IsPrivate"
                    name="IsPrivate"
                    id="isPublic"
                    type="radio"
                    class="organization__radio"
                    [value]="false"
                    [class.disabled-input]="
                      !isCreating && !sectionPrivacy?.editting
                    "
                  />
                  <label
                    for="isPublic"
                    class="radio-info d-flex flex-column"
                    [class.disabled-action]="
                      !isCreating && !sectionPrivacy?.editting
                    "
                  >
                    <span class="radio-text">
                      <i class="fas fa-globe-europe"></i>
                      {{ 'Public' | translate }}
                    </span>
                    <span
                      class="radio-hint"
                      [innerHtml]="
                        'UI.EventEdit.PublicRadioHint'
                          | translate
                          | stringReplacement
                            : {
                                '{portalName}': '#' | linkWrapper : portalName
                              }
                      "
                    ></span>
                  </label>
                </div>
                <div class="organization__radio-item">
                  <input
                    formControlName="IsPrivate"
                    name="IsPrivate"
                    id="isPrivate"
                    class="organization__radio"
                    type="radio"
                    [value]="true"
                    [class.disabled-input]="
                      !isCreating && !sectionPrivacy?.editting
                    "
                  />
                  <label
                    for="isPrivate"
                    class="radio-info d-flex flex-column"
                    [class.disabled-action]="
                      !isCreating && !sectionPrivacy?.editting
                    "
                  >
                    <span class="radio-text">
                      <i class="fas fa-lock"></i>
                      {{ 'Private' | translate }}
                    </span>
                    <span class="radio-hint">
                      {{ 'UI.EventEdit.PrivateRadioHint' | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="venture__label widget__label mb-3">
              {{ getFieldOptions('SendMessageRights').displayName }}
            </div>
            <app-form-field-custom
              [controlName]="'SendMessageRights'"
              [formInput]="form"
              [attributeDescription]="getFieldOptions('SendMessageRights')"
              [editable]="isCreating || (editable && sectionPrivacy.editting)"
            >
            </app-form-field-custom>
          </div>
        </app-form-section>
      </div>

      <!-- Organizer -->
      <app-deactivated-organization-warning
        *ngIf="!sectionOrganizer.editting && !isCreating && !leadCompany?.value"
      ></app-deactivated-organization-warning>

      <div class="venture__body widget__card--background">
        <app-form-section
          #sectionOrganizer
          [index]="eventSectionIndex.Organizer"
          [canSave]="isValidSection(eventSectionIndex.Organizer)"
          [showEditButton]="!isCreating && editable"
          [saveSectionCallback]="saveSection.bind(this)"
          (saveSection)="markOrgFormAsTouched()"
          (cancelSection)="
            cancelSection($event);
            cancelCompany(
              $event,
              organizer.resetCompanyInput.bind(organizer),
              'LeadCompany'
            );
            reverseLinkToExistingOrg()
          "
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="row">
            <div class="col-12">
              <app-organizer
                #organizer
                deactivatedOrgIcon="fas fa-times"
                deactivatedOrgLabel="UI.LeadOrgUnavailable"
                (orgFormChange)="updateForm($event)"
                (orgSelected)="onLeadOrgSelectedEvent()"
                [leadCompany]="leadCompany?.value"
                [searchMode]="orgsSearchMode"
                [disabledSection]="
                  !(isCreating || (editable && sectionOrganizer.editting))
                "
                [labelSectionName]="'Organizer'"
                [editing]="isCreating || (isOwner && sectionOrganizer.editting)"
                [(isLinkedFromParent)]="isLinkedToExistingOrg"
                [markOrgFormTouched]="markOrgFormTouched"
                [nameAttributeDescription]="getFieldOptions('LeadCompanyName')"
                [logoAttributeDescription]="getFieldOptions('LeadCompanyLogo')"
                [urlAttributeDescription]="getFieldOptions('LeadCompanyLink')"
                [childClass]="'col-12 col-md-6'"
              ></app-organizer>
              <div
                *ngIf="
                  showLeadOrgError() &&
                  (isCreating || (isOwner && sectionOrganizer?.editting))
                "
                class="alert alert-danger"
              >
                <span translate>UI.Common.Organizer-is-required</span>
              </div>
            </div>
          </div>
        </app-form-section>
      </div>

      <!-- Partner Organizations & Networks -->
      <div class="venture__body widget__card--background">
        <app-form-section
          #orgPartner
          [index]="eventSectionIndex.PartnerOrganizations"
          [canSave]="isValidSection(eventSectionIndex.PartnerOrganizations)"
          [showEditButton]="!isCreating && editable"
          [saveSectionCallback]="saveSection.bind(this)"
          (saveSection)="markOrgFormAsTouched()"
          (cancelSection)="eventOrgs.reset(); cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <app-event-organizations
            #eventOrgs
            [editting]="isCreating || orgPartner.editting"
            [items]="partnerOrganizations"
            [userId]="profile?.id"
            [ignoreCompany]="entity?.leadCompany"
            [baseHttpService]="baseHttpService"
            [entity]="entity"
            (notifyOrganisationPayload)="updateOrganisationPayload($event)"
            (removedPartnerOrgSuccess)="loadPartnerOrgs()"
          ></app-event-organizations>
        </app-form-section>
      </div>

      <ng-container *ngIf="!isProduction">
        <ngb-accordion [activeIds]="['custom-metadata-panel']">
          <!-- Custom Metadata -->
          <ngb-panel
            *ngIf="
              customMetadata.attributeDescriptions.length > 0 &&
              isCustomAttributeFormGenerated
            "
            cardClass="venture__body venture__accordion ignore-overflow"
            id="custom-metadata-panel"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <button
                class="btn d-flex align-items-center justify-content-between w-100 text-left"
                ngbPanelToggle
                type="button"
              >
                <div translate class="pull-left float-left">
                  <span
                    class="venture__company-header mr-5 d-block d-lg-inline-block"
                    >{{
                      'UI.Organization.OrganizationSpecificInformation'
                        | translate
                    }}</span
                  >
                </div>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </button>
            </ng-template>

            <ng-template ngbPanelContent>
              <div class="venture__body widget__card--background m-0">
                <app-form-section
                  #sectionCustomMetadata
                  [index]="formSections.length - 1"
                  [canSave]="isValidSection(formSections.length - 1)"
                  [showEditButton]="!isCreating && editable"
                  [saveSectionCallback]="saveCustomAttributeSection.bind(this)"
                  (saveSection)="markOrgFormAsTouched()"
                  (cancelSection)="cancelSection($event)"
                  (editIndexChange)="markEditingSectionIndex($event)"
                >
                  <app-custom-attributes
                    [formInput]="form"
                    [editable]="
                      isCreating ||
                      (editable && sectionCustomMetadata?.editting)
                    "
                    [metadataRecord]="customMetadata"
                  >
                  </app-custom-attributes>
                </app-form-section>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </ng-container>

      <!-- Agenda -->
      <div class="venture__body widget__card--background">
        <app-form-section
          #sectionAgenda
          [index]="eventSectionIndex.Agenda"
          [canSave]="isValidSection(eventSectionIndex.Agenda)"
          [showEditButton]="!isCreating && editable"
          [saveSectionCallback]="saveSection.bind(this)"
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="row">
            <div class="col-12">
              <app-form-field-custom
                (languageSelected)="
                  onLanguageChange($event, eventSectionIndex.Agenda)
                "
                (languageRemoved)="
                  onRemoveLanguage(eventSectionIndex.Agenda, $event)
                "
                [attributeDescription]="getFieldOptions('Agenda')"
                [editable]="isCreating || (editable && sectionAgenda.editting)"
                [formInput]="getFormSectionIndex(eventSectionIndex.Agenda)"
                [options]="{
                  isViewAsText: true,
                  isWideView: true,
                  showLanguageSwitcher: editable && sectionAgenda.editting
                }"
                [showLabel]="true"
                [showLanguageIndicator]="isCreating"
                class="event__agenda"
              >
              </app-form-field-custom>
            </div>
          </div>
        </app-form-section>
      </div>

      <!-- Description/Course Content -->
      <div class="venture__body widget__card--background">
        <app-form-section
          #sectionDescription
          [index]="eventSectionIndex.Description"
          [canSave]="isValidSection(eventSectionIndex.Description)"
          [showEditButton]="!isCreating && editable"
          [saveSectionCallback]="saveSection.bind(this)"
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <app-form-field-custom
                  class="event__description"
                  (languageSelected)="
                    onLanguageChange($event, eventSectionIndex.Description)
                  "
                  (languageRemoved)="
                    onRemoveLanguage(eventSectionIndex.Description, $event)
                  "
                  [formInput]="
                    getFormSectionIndex(eventSectionIndex.Description)
                  "
                  [editable]="
                    isCreating || (editable && sectionDescription.editting)
                  "
                  [showLabel]="true"
                  [showLanguageIndicator]="isCreating"
                  [attributeDescription]="getFieldOptions('Description')"
                  [options]="{
                    isViewAsText: true,
                    isWideView: true,
                    showLanguageSwitcher:
                      editable && sectionDescription.editting
                  }"
                >
                </app-form-field-custom>
              </div>

              <app-form-field-custom
                [type]="allType.Files"
                [formInput]="form"
                [editable]="
                  isCreating || (editable && sectionDescription.editting)
                "
                [showLabel]="true"
                [attributeDescription]="getFieldOptions('Attachments')"
              >
              </app-form-field-custom>
            </div>
          </div>
        </app-form-section>
      </div>

      <!-- Admin & Stakeholders -->
      <ng-container *ngIf="!isCreating">
        <div class="venture__body venture-people widget__card--background">
          <div class="row">
            <div class="col-12">
              <app-search-people-box
                [searchName]="filteringKeyword"
                (keyChanged)="onSearchPeople($event)"
                styleClass="my-2"
              ></app-search-people-box>

              <label
                class="venture__label venture__label--head widget__label"
                translate
              >
                {{ getFieldOptions('Initiator')?.displayName }}
              </label>
              <ng-container *ngIf="initiators.length > 0; else deactiveUser">
                <app-initator-cards
                  [items]="initiators"
                  [entityId]="entity?.id"
                  [httpService]="baseHttpService"
                  [enablePagination]="false"
                  [editable]="editable"
                  [allowAddPeople]="false"
                  [allowRemovePeople]="false"
                  [filteringKeyword]="filteringKeyword"
                  [globalOrgId]="globalOrgId"
                  [enableExtraAction]="isOwner"
                  [toItems]="participantsWithProfile"
                  (itemsRoleUpdate)="setInitators($event)"
                  (toItemsChange)="updateParticipantWithProfile($event)"
                ></app-initator-cards>
              </ng-container>

              <ng-template #deactiveUser>
                <app-deactive-entity
                  [deactiveShowType]="DeactiveShowTypeEnum.Card"
                ></app-deactive-entity>
              </ng-template>

              <div *ngIf="entity?.canInvite" class="my-4 pb-3">
                <app-invitation-by-email
                  [baseHttpService]="baseHttpService"
                  [ccEmail]="(profile$ | async)?.email"
                  [entityId]="entity?.id"
                  [messageBody]="'UI.Invitation.Mail-content--Body-For-Event'"
                  [senderName]="profile?.firstName + ' ' + profile?.lastName"
                  [senderSignature]="
                    'UI.Invitation.Signature'
                      | translate
                      | stringReplacement
                        : {
                            '{name}':
                              profile?.firstName + ' ' + profile?.lastName,
                            '{portalName}': portalName
                          }
                  "
                  [disableReason]="
                    isInviteSectionDisabled(entity)
                      ? 'UI.EventEdit.DisabledInviteTooltip'
                      : ''
                  "
                  [inviteTokenType]="TokenTypes.InviteEvent"
                ></app-invitation-by-email>
              </div>

              <ng-container *ngIf="!isShowExternalRegistration">
                <div
                  class="d-flex flex-column flex-md-row align-items-start align-items-md-center my-3"
                >
                  <label
                    class="venture__label venture__label--head widget__label mr-4"
                  >
                    {{ 'UI.EventEdit.Participants' | translate }}
                  </label>
                  <button
                    *ngIf="isSendMessToMemberShown()"
                    onmouseup="this.blur();"
                    class="btn btn-primary form-section__btn m-0"
                    type="button"
                    appRequireLogin
                    appDebounceClick
                    (debounceClick)="openSendMessageToMember(entity)"
                  >
                    <i class="fas fa-paper-plane btn-icon-right"></i>
                    <span>
                      {{ 'UI.EventEdit.DirectMessageButton' | translate }}
                    </span>
                  </button>
                  <ng-container *ngIf="!isCreating && isTeam">
                    <button
                      appLeaveEntity
                      [entityId]="entity.id"
                      [entityName]="entityName"
                      [(isLeaving)]="isLeavingEntity"
                      [disabled]="isLeavingEntity"
                      (afterLeaved)="afterLeaveEntity($event)"
                      class="btn btn-outline-primary form-section__btn m-0 mt-2 mt-md-0 ml-md-2"
                    >
                      <i class="fas fa-user-times"></i>
                      <span
                        class="ml-2"
                        [innerHTML]="
                          'UI.Common.LeaveThis'
                            | translate
                            | stringReplacement
                              : {
                                  '{entity}': (entityName | translate)
                                }
                        "
                      >
                      </span>
                    </button>
                  </ng-container>
                </div>

                <app-stakeholder-cards
                  (itemsChange)="updateParticipantWithProfile($event)"
                  (itemsRoleUpdate)="updateParticipantWithProfile($event)"
                  (peopleAdded)="onPeopleAdded()"
                  [httpService]="baseHttpService"
                  [items]="participantsWithProfile"
                  [entityId]="entity?.id"
                  [editable]="editable && isOwner"
                  [shouldValidatePersonBeforeAdding]="!isCreating"
                  [ignoreId]="entity?.initiator?.id || (profile$ | async)?.id"
                  [parentEntityName]="entityName"
                  [parentEntity]="entity"
                  [emptyMessage]="'There are no participants yet'"
                  [removePeopleTitle]="'UI.RemoveDialog.RemoveFromParticipants'"
                  [filteringKeyword]="filteringKeyword"
                  [globalOrgId]="globalOrgId"
                  [enableExtraAction]="isOwner"
                ></app-stakeholder-cards>

                <h4 class="font-weight-bold venture__label--head" translate>
                  UI.EventEdit.ParticipantsWithoutProfile
                </h4>

                <app-stakeholder-cards
                  (itemsChange)="updateParticipantWithoutProfile($event)"
                  [httpService]="baseHttpService"
                  [items]="participantsWithoutProfile"
                  [entityId]="entity?.id"
                  [editable]="editable && isOwner"
                  [shouldValidatePersonBeforeAdding]="!isCreating"
                  [ignoreId]="entity?.initiator?.id || (profile$ | async)?.id"
                  [parentEntityName]="entityName"
                  [parentEntity]="entity"
                  [emptyMessage]="'There are no participants yet'"
                  [removePeopleTitle]="'UI.RemoveDialog.RemoveFromParticipants'"
                  [filteringKeyword]="filteringKeyword"
                  [globalOrgId]="globalOrgId"
                  [allowAddPeople]="false"
                  [hideProfileImage]="true"
                  [cardType]="cardType.ParticipantWithoutProfile"
                ></app-stakeholder-cards>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Location -->
      <div class="venture__body widget__card--background">
        <label class="venture__label venture__label--head widget__label pb-3">
          {{ 'UI.EventEdit.EventLocation' | translate }}
        </label>

        <app-form-section
          #sectionLocation
          [index]="eventSectionIndex.Location"
          [canSave]="isValidSection(eventSectionIndex.Location)"
          [showEditButton]="!isCreating && editable"
          [saveSectionCallback]="saveSection.bind(this)"
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="row">
            <ng-container
              *ngIf="
                (!isNotShownPhysicalLocation &&
                  shouldShowField('Location', sectionLocation.editting)) ||
                  shouldShowField('VirtualLocation', sectionLocation.editting);
                else locationHint
              "
            >
              <div
                *ngIf="
                  !isNotShownPhysicalLocation &&
                  shouldShowField('Location', sectionLocation.editting)
                "
                class="col-12 mb-3"
              >
                <app-form-field-custom
                  [attributeDescription]="getFieldOptions('Location')"
                  [editable]="
                    isCreating || (editable && sectionLocation.editting)
                  "
                  [formInput]="form"
                  [hideLink]="true"
                  [options]="{
                    placeholder: 'Go To Location' | translate
                  }"
                  [showLabel]="true"
                  [type]="allType.Map"
                >
                </app-form-field-custom>
              </div>

              <div
                *ngIf="
                  shouldShowField('VirtualLocation', sectionLocation.editting)
                "
                class="col-12 mb-3"
              >
                <app-form-field-custom
                  *ngIf="
                    isCreating ||
                      entity?.hasJoined ||
                      entity?.showExternalRegistration;
                    else hiddenLabelLink
                  "
                  [attributeDescription]="getFieldOptions('VirtualLocation')"
                  [editable]="
                    isCreating || (editable && sectionLocation.editting)
                  "
                  [formInput]="form"
                  [showLabel]="true"
                >
                </app-form-field-custom>

                <ng-template #hiddenLabelLink>
                  <div class="venture__label widget__label">
                    {{ getFieldOptions('VirtualLocation')?.displayName }}
                  </div>
                  <span class="font-italic font-size-sm" translate>
                    UI.Event.VirtualLocationHiddenLabel
                  </span>
                </ng-template>
              </div>
            </ng-container>

            <ng-template #locationHint>
              <div class="col-12 mb-3 font-italic" translate>
                UI.Event.LocationHiddenHint
              </div>
            </ng-template>
          </div>
        </app-form-section>
      </div>

      <!-- State -->
      <div
        *ngIf="shouldShowField('EventNumber', false) || isCreating || isOwner"
        class="venture__body widget__card--background"
      >
        <app-form-section
          #sectionState
          [index]="eventSectionIndex.State"
          [canSave]="isValidSection(eventSectionIndex.State)"
          [showEditButton]="!isCreating && editable"
          [saveSectionCallback]="saveSection.bind(this)"
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <ng-container *ngIf="isCreating || isOwner">
            <div class="row">
              <div class="col-12">
                <app-form-field-custom
                  [formInput]="form"
                  [editable]="isCreating || (editable && sectionState.editting)"
                  [type]="allType.VentureStatus"
                  [showLabel]="true"
                  [attributeDescription]="getFieldOptions('State')"
                >
                </app-form-field-custom>
              </div>
            </div>
          </ng-container>

          <div
            *ngIf="shouldShowField('EventNumber', sectionState.editting)"
            class="row"
          >
            <div class="col-12">
              <app-form-field-custom
                [formInput]="form"
                [editable]="isCreating || (editable && sectionState.editting)"
                [showLabel]="true"
                [type]="allType.StringSingle"
                [attributeDescription]="getFieldOptions('EventNumber')"
              >
              </app-form-field-custom>
            </div>
          </div>
        </app-form-section>
      </div>

      <div
        *ngIf="
          (form.errors || form.invalid) &&
          isSubmitted &&
          (form.touched || form.dirty)
        "
        class="venture__footer-alert alert alert-danger"
        translate
      >
        {{ errorMessage[formErrorKey] || 'Please check the error above' }}
      </div>

      <div *ngIf="isCreating" class="venture__btn-wrapper">
        <button
          appRequireSession
          (credentialClick)="handleOnSubmit()"
          class="btn btn-primary venture__btn-submit"
          [disabled]="
            isSubmitting || isSubmitted || !(form?.touched || form?.dirty)
          "
        >
          <ng-container *ngIf="isSubmitting">
            <i class="fas fa-spinner fa-spin"></i>
          </ng-container>
          <span translate>{{ isCreating ? 'Create' : 'Save' }}</span>
        </button>
      </div>
    </div>
  </form>
</ng-container>

<app-people-modal
  *ngIf="showFollowersModal"
  [entityId]="selectedEntityId"
  [entityTitle]="entity?.title"
  modalTitle="Followers"
  [httpService]="baseHttpService"
  (dismissModal)="showFollowersModal = false"
></app-people-modal>

<app-likes-modal
  *ngIf="showLikesModal"
  [entityId]="selectedEntityId"
  [entityTitle]="entity?.title"
  [modalTitle]="'UI.Likes.Event' | translate"
  [httpService]="baseHttpService"
  (dismissModal)="showLikesModal = false"
></app-likes-modal>
