<app-people-modal
  *ngIf="showPeopleModal"
  modalTitle="Followers List"
  [entityId]="selectedEntityId"
  [httpService]="httpService"
  (dismissModal)="showPeopleModal = false"
></app-people-modal>

<app-ventures-modal
  *ngIf="showVenturesModal"
  [entityId]="selectedEntityId"
  [modalTitle]="'Ventures' | translate"
  [httpService]="httpService"
  (dismissModal)="showVenturesModal = false"
  [globalOrgId]="globalOrgId"
></app-ventures-modal>

<ul
  *ngIf="displayItems?.length > 0; else itemEmtpyMessage"
  class="widget__container people-cards"
>
  <li
    *ngFor="let person of displayItems; index as listIndex; trackBy: trackItem"
    class="people__item"
  >
    <div class="people__wrapper d-flex flex-column h-100">
      <div
        class="people__body px-3 widget__card--background flex-grow-1"
        [class.pointer-none]="person.isUnregisteredUser"
      >
        <app-people-info-small
          [person]="person"
          [customUserRoles]="customUserRoles"
          [editable]="editable"
          [fromPendingPeople]="fromPendingPeople"
          [hideProfileImage]="hideProfileImage"
          (peopleCardClicked)="onPeopleCardClicked()"
        >
          <ng-container *ngIf="customUserRoles.length === 0">
            <ng-container
              *ngTemplateOutlet="extraActionsTemplate"
            ></ng-container>
          </ng-container>
        </app-people-info-small>

        <ng-container *ngIf="editable && customUserRoles.length > 0">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <label class="mr-2 word-wrap--normal">
                {{ 'UI.Profile.Role' | translate }}
              </label>
              <app-boostrap-dropdown
                class="dropdown--full-width truncate-selection"
                [items]="customUserRoles"
                [(currentIndex)]="person.roleIndex"
                [defaultLabel]="'UI.Common.SelectRole' | translate"
                (selectItem)="selectRole($event, person)"
              >
              </app-boostrap-dropdown>
              <ng-container *ngIf="person.pendingMessage && fromPendingPeople">
                <div
                  class="people__pending-message"
                  (click)="openModalPendingMessage(person.pendingMessage)"
                >
                  <i class="fas fa-comment"></i>
                </div>
              </ng-container>
            </div>

            <div class="d-flex justify-content-end py-2">
              <ng-container
                *ngTemplateOutlet="extraActionsTemplate"
              ></ng-container>
            </div>
          </div>
        </ng-container>

        <ng-template #extraActionsTemplate>
        </ng-template>
      </div>

      <div
        *ngIf="cardType !== CardType.ParticipantWithoutProfile"
        class="people__footer px-3"
      >
        <ul class="list-inline people__actions justify-content-between row">
          <!-- Followers -->
          <li
            *ngIf="sessionService.currentUser?.id !== person?.id"
            [class.disabled-action]="!enableInteractionBar"
            class="list-inline-item people__actions-item"
            [ngbTooltip]="'Followers' | translate"
          >
            <ng-container *ngIf="isLoadingFollowingsCount; else followersBtn">
              <div class="text-center">
                <i class="fas fa-spinner fa-spin"></i>
              </div>
            </ng-container>
            <ng-template #followersBtn>
              <a
                appDebounceClick
                (debounceClick)="showEntityData(person); showPeopleModal = true"
                class="d-flex text-gray-600"
              >
                <app-internal-icon
                  [className]="'filter-gray'"
                  [name]="'user-follow'"
                ></app-internal-icon>
                <span class="people__actions-label font-weight-500">
                  <span
                    [innerHTML]="person?.followerCount | parentheses"
                  ></span>
                </span>
              </a>
            </ng-template>
          </li>

          <!-- Your profile -->
          <li
            *ngIf="sessionService.currentUser?.id === person?.id"
            class="d-flex align-items-center your-profile"
          >
            <i class="fas fa-info-circle mr-2"></i>
            Your Profile
          </li>

          <!-- Message -->
          <li
            *ngIf="
              sessionService.currentUser?.id !== person?.id &&
              enableInteractionBar
            "
            appRequireLogin
            appDebounceClick
            (debounceClick)="
              openSendMessage(
                person?.id,
                person?.firstName + ' ' + person?.lastName
              )
            "
            class="pointer-cursor"
            [ngbTooltip]="'UI.EventEdit.DirectMessageButton' | translate"
          >
            <app-internal-icon
              [className]="'filter-gray'"
              [name]="'paper-plane-top'"
              [height]="20"
            ></app-internal-icon>
          </li>

          <!-- More actions -->
          <li
            ngbDropdown
            class="pointer-cursor dropdown-no-arrow"
            [dropdownClass]="'people-actions'"
            [ngbTooltip]="'UI.Common.Button.More' | translate"
          >
            <div ngbDropdownToggle>
              <i class="far fa-ellipsis-h fa-lg align-middle text-gray-500"></i>
            </div>
            <ul ngbDropdownMenu>
              <li ngbDropdownItem class="py-2 px-3">
                <ng-container *ngIf="isLoadingVenturesCount; else venturesBtn">
                  <div class="text-center">
                    <i class="fas fa-spinner fa-spin"></i>
                  </div>
                </ng-container>

                <ng-template #venturesBtn>
                  <div
                    class="d-flex align-items-center"
                    appDebounceClick
                    (debounceClick)="
                      showEntityData(person); showVenturesModal = true
                    "
                  >
                    <app-internal-icon
                      [className]="'filter-dark-gray mr-2'"
                      [name]="'suitcase'"
                      [height]="20"
                    ></app-internal-icon>

                    View project ideas

                    <span *ngIf="person?.venturesCount" class="ml-1 ventures-count"
                      >({{ person?.venturesCount }})</span
                    >
                  </div>
                </ng-template>
              </li>

              <ng-container
                *ngIf="
                  cardType === CardType.OrganizationAdmin
                    ? enableExtraAction && displayItems.length > 1
                    : enableExtraAction &&
                      ((cardType &&
                        sessionService.currentUser?.id === person?.id) ||
                        (!cardType &&
                          sessionService.currentUser?.id !== person?.id))
                "
              >
                <li
                  *ngFor="let action of extraActions"
                  ngbDropdownItem
                  class="py-2 px-3"
                  appRequireLogin
                  appDebounceClick
                  (debounceClick)="doExtraAction(action, person)"
                >
                  <i class="mr-2" [ngClass]="action.faIcon"></i>

                  {{ action.label | translate }}
                </li>
              </ng-container>

              <li
                *ngIf="person | call : shouldShowRemoveBtn"
                class="remove-person text-primary border-top py-2 px-3"
                (click)="peopleRemoved.emit(person)"
                ngbDropdownItem
              >
                <i class="far fa-user-minus mr-2"></i>

                Remove this person
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <ng-container *ngIf="cardType === CardType.ParticipantWithoutProfile">
        <div
          *ngIf="person | call : shouldShowRemoveBtn"
          class="people__footer px-3 mt-1"
        >
          <div
            class="text-primary d-flex align-items-center justify-content-center pointer-cursor"
            (click)="peopleRemoved.emit(person)"
          >
            <em class="far fa-user-minus"></em>
            <span class="ml-2" translate
              >UI.EventEdit.RemoveThisParticipant</span
            >
          </div>
        </div>
      </ng-container>
    </div>
  </li>
</ul>
<ng-template #itemEmtpyMessage>
  <div class="people-cards-emtpy m-0 font-size-sm" translate>
    {{ emptyMessage }}
  </div>
</ng-template>
