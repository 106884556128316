import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { AuthenService } from '@core/authen/authen.service';
import { UnsavedFormCheckService } from '@core/form/unsaved-form-check.service';
import { NewsMessagesHttpService } from '@core/http/news-messages-http.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldCustomComponent } from '@shared/components/form-custom-attributes/form-field-custom/form-field-custom.component';
import { StorageEnum } from '@shared/enums/storage.enum';
import {
  CustomAtrributeValue,
  MetaInformation,
} from '@shared/interfaces/attribute-description.interface';
import { ImageInterface } from '@shared/interfaces/file.interface';
import { EntityGlobalFilterCriteria } from '@shared/interfaces/filters/global-filter.interface';
import { News } from '@shared/interfaces/news.interface';
import {
  OrganizationFilter,
  OrganizationInterface,
} from '@shared/interfaces/organization.interface';
import { Post } from '@shared/interfaces/post.interface';
import { UserConfigManagementService } from '@shared/services/user-config-management.service';
import { SendMessageBoxBaseComponent } from '@src/app/components/dialogs/send-message-box-base/send-message-box-base.component';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { TenantHttpService } from '@src/app/core/http/tenant.http.service';
import { CommunitySelectorStoredService } from '@src/app/core/services/community-selector-stored.service';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import {
  IconType,
  InternalIcon,
} from '@src/app/shared/enums/internal-icon.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { StreamOption } from '@src/app/shared/interfaces/stream.interface';
import { TenantInterface } from '@src/app/shared/interfaces/tenant.interface';
import { TextValidator } from '@src/app/shared/utils/form-fields-validation';
import { BehaviorSubject, finalize } from 'rxjs';
@Component({
  selector: 'app-new-post-dialog',
  templateUrl: './new-post-dialog.component.html',
})
export class NewPostDialogComponent
  extends SendMessageBoxBaseComponent
  implements OnInit
{
  @Input() postData: News;

  @Input() placeholder = 'UI.SendMessage.Placeholder';

  @Input() globalFilterCriteria: EntityGlobalFilterCriteria;

  currentOrg: OrganizationFilter;

  globalOrgId: number;

  items: CustomAtrributeValue[] = [];

  InternalIcon = InternalIcon;

  IconType = IconType;

  streams: StreamOption[] = [];

  isLoadingStreamOptions$ = new BehaviorSubject(false);

  get streamsControl(): AbstractControl {
    return this.form?.get('streams');
  }

  //#region SERVICES
  protected toastService: ToastService;
  protected orgHttpService: OrganizationHttpService;
  protected tenantHttpService: TenantHttpService;
  protected sessionService: SessionService;
  //#endregion End Services

  constructor(
    public readonly activeModal: NgbActiveModal,
    public readonly unsavedFormCheckService: UnsavedFormCheckService,
    protected readonly injector: Injector,
    protected readonly authService: AuthenService,
    protected readonly sendMessageHttpService: SendMessageHttpService,
    private readonly newsMessagesHttpService: NewsMessagesHttpService,
    private readonly userConfig: UserConfigManagementService,
    private communitySelectorService: CommunitySelectorStoredService
  ) {
    super(
      activeModal,
      unsavedFormCheckService,
      authService,
      sendMessageHttpService,
      injector
    );
    this.toastService = injector.get<ToastService>(ToastService);
    this.orgHttpService = injector.get<OrganizationHttpService>(
      OrganizationHttpService
    );
    this.tenantHttpService = injector.get<TenantHttpService>(TenantHttpService);
    this.sessionService = injector.get<SessionService>(SessionService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const isCreationMode = this.isCreationMode(this.postData);

    this.getStreamOptions(isCreationMode);
  }

  isFormNotSaved(): boolean {
    return this.content?.value;
  }

  isCreationMode(post: News): boolean {
    return !post?.id;
  }

  createPost(): void {
    this.isSubmitting = true;

    const payload: Post = {
      content: this.content?.value,
      imageId: this.imageId?.value,
      orgId: this.currentOrg?.id ?? this.globalOrgId,
      streams: this.streamsControl?.value,
    };

    this.newsMessagesHttpService.createPost(payload).subscribe(() => {
      this.toastService.showSuccess('UI.Toast.ShareSuccessfully');
      this.isSubmitting = false;
      this.activeModal.close(true);
    });
  }

  editPost(post: News): void {
    this.isSubmitting = true;
    const payload: Post = {
      content: this.content?.value,
      streams: this.streamsControl?.value,
    };

    if (this.imageId?.value) {
      payload.imageId = this.imageId?.value;
    } else if (!this.messageForm?.imageInputDisplay?.source) {
      payload.imageId = null;
    }

    this.newsMessagesHttpService.editPost(post.id, payload).subscribe(() => {
      this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
      this.isSubmitting = false;
      this.activeModal.close(true);
    });
  }

  formFieldLoaded(newPostDialogComponent: FormFieldCustomComponent): void {
    if (!this.postData?.content?.image) return;

    const image: ImageInterface = { url: this.postData.content.image };
    newPostDialogComponent?.imageInputDisplay?.setImage(image);
  }

  onSubmit(post: News): void {
    this.isCreationMode(post) ? this.createPost() : this.editPost(post);
  }

  getModalTitle(post: News): string {
    return this.isCreationMode(post) ? 'UI.News.NewPost' : 'UI.News.EditPost';
  }

  getSubmitButtonText(post: News): string {
    return this.isCreationMode(post) ? 'UI.Interaction.Share' : 'Save';
  }

  getStreamOptions(isCreationMode: boolean): void {
    if (isCreationMode) {
      if (this.globalFilterCriteria.organizationId) {
        this.loadStreamOptions(
          +this.globalFilterCriteria.organizationIds,
          isCreationMode
        );
      } else {
        const tenantId = +this.sessionService.getTenantId();
        this.loadStreamOptions(tenantId, isCreationMode);
      }
    } else {
      const { streams } = this.postData.content;
      this.loadStreamOptions(streams, isCreationMode);
    }
  }

  loadStreamOptions(id: number | number[], isCreationMode: boolean): void {
    let api$;
    let httpService:
      | BaseHttpService<OrganizationInterface>
      | BaseHttpService<TenantInterface>;

    if (isCreationMode) {
      httpService = this.globalFilterCriteria.organizationId
        ? this.orgHttpService
        : this.tenantHttpService;
    } else {
      httpService = this.postData.content.organization
        ? this.orgHttpService
        : this.tenantHttpService;
    }

    this.isLoadingStreamOptions$.next(true);

    if (isCreationMode) {
      api$ = httpService.getStreamsByEntityId(id as number);
    } else {
      api$ = httpService.getStreamsByStreamId(id as number[]);
    }

    api$
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.isLoadingStreamOptions$.next(false);
          }, 500);
        })
      )
      .subscribe((streams) => {
        this.streams = streams;
        
        if (isCreationMode) {
          const defaultStream = streams.find((item) => item.streamKey === 1);
          this.form.patchValue({
            streams: defaultStream ? [defaultStream.id] : [],
          });
        } else {
          const { streams: streamIds } = this.postData.content;
          this.form.patchValue({
            streams: streamIds,
          });
        }
      });
  }

  shouldShowComma(item): boolean {
    const idx = this.streamsControl.value.findIndex((i) => i === item.id);
    if (idx + 1 === this.streamsControl.value.length) {
      return false;
    }

    return true;
  }

  protected handleAfterLoadAttributeDescription(res: MetaInformation): void {
    this.initForm();
    this.setCurrentOrg().then();
    this.setFormDataIfAny(this.postData);
  }

  private async setCurrentOrg(): Promise<void> {
    const currentOrgList = await this.userConfig.get(
      StorageEnum.orgFilterToken
    );

    this.currentOrg = currentOrgList?.find((item: OrganizationFilter) => {
      return item.id === this.globalFilterCriteria?.organizationIds[0];
    });

    if (!this.currentOrg) {
      this.communitySelectorService.popularOrgs
        .pipe(untilDestroyed(this))
        .subscribe((orgs: any) => {
         this.currentOrg = orgs.find(
            (org) => org.id === this.globalFilterCriteria?.organizationIds[0]
          );
    
        });
    }

    if (!this.currentOrg && this.postData) {
      const { orgName } = this.postData.content?.organization ?? {};
      if(!orgName) return;
      this.currentOrg = {
        ...this.postData.content.organization,
        name: orgName,
      } as unknown as OrganizationFilter;
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      Content: [
        '',
        {
          validators: [Validators.required, TextValidator.notEmptyOrWhitespace],
        },
      ],
      imageId: [],
      streams: [],
    });

    this.formErrorService.register(this);
  }

  private setFormDataIfAny(post: News): void {
    if (!post) return;

    this.form.patchValue({
      Content: post.message,
    });
  }
}
