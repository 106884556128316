import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { ChallengeHttpService } from '@core/http/challenge-http.service';
import { SessionService } from '@core/session.service';
import { ToastService } from '@core/toast.service';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JuryInvitationDialogComponent } from '@shared/components/molecules/jury-invitaion-dialog/jury-invitation-dialog.component';
import { EntityName } from '@shared/enums/entity-name.enum';
import { PeopleActionEnum } from '@shared/enums/people.enum';
import { TokenTypes } from '@shared/enums/token-type';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { WINDOW } from '@shared/helpers/window.token';
import { OrganizationInterface } from '@shared/interfaces/organization.interface';
import { PeopleAction } from '@shared/interfaces/people.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import {
  JurorFormState,
  JurorRatingState,
} from '@src/app/shared/enums/juror.enum';
import { JurorRating } from '@src/app/shared/interfaces/juror.interface';
import { Observable, Subject } from 'rxjs';
import { pluck, repeatWhen, tap } from 'rxjs/operators';
import { CardType } from '../../people-card-list/people-card-list.component';

@Component({
  selector: 'app-jury-section',
  templateUrl: './jury-section.component.html',
})
export class JurySectionComponent implements OnChanges, OnDestroy {
  @Input() challenge: OrganizationInterface;

  @Input() profile: UserInterface;

  @Input() showEditButton: boolean;

  @Output() acceptedAsJuror = new EventEmitter<void>();

  editing: boolean;

  currentFormState: JurorFormState = JurorFormState.List;

  currentRatingState = JurorRatingState.Link;

  isAdmin: boolean;

  isJuror = false;

  jurors$: Observable<UserInterface[]>;

  pendingJurors: UserInterface[] = [];

  refreshJurorList$ = new Subject<void>();

  refreshPendingList$ = new Subject<void>();

  readonly extraActions: PeopleAction[] = [
    {
      action: PeopleActionEnum.Accept,
      label: 'Accept',
      class: 'btn-primary m-1',
      faIcon: 'far fa-user-check',
    },
    {
      action: PeopleActionEnum.Deny,
      label: 'Deny',
      class: 'btn-outline-primary m-1',
      faIcon: 'far fa-user-times',
    },
  ];

  pendingListTitle: string;

  jurorRatingLink: JurorRating;

  previousJurorRatingLink: JurorRating;

  isSinglePendingJuror: boolean;

  requesting: boolean;

  hasSaved = false;

  readonly EntityName = EntityName;

  readonly JurorFormState = JurorFormState;

  readonly JurorRatingState = JurorRatingState;

  readonly TokenTypes = TokenTypes;

  readonly portalName: string = environment.portalName;

  readonly CardType = CardType;

  get challengeId(): number {
    return this.challenge?.id;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    public readonly challengeService: ChallengeHttpService,
    private readonly modalService: NgbModal,
    private readonly toastService: ToastService,
    private readonly sessionService: SessionService
  ) {}

  ngOnChanges(): void {
    if (!this.challenge) return;

    this.isAdmin = this.challenge?.initiators.some(
      ({ id }) => id === this.profile?.id
    );

    this.getJurorList();

    if (!this.profile?.id) return;

    this.getPendingJurors();
  }

  ngOnDestroy(): void {
    /**/
  }

  openModal(): void {
    const modalRef = this.modalService.open(JuryInvitationDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'sm',
      scrollable: false,
      windowClass: 'message-modal-custom',
    });

    modalRef.componentInstance.challengeId = this.challengeId;

    modalRef.result.then((isSent: boolean) => {
      if (!isSent) return;

      this.refreshPendingList$.next(); // Incase send invitation only
      this.refreshJurorList$.next(); // Incase assigning directly
      this.toastService.showSuccess('UI.Toast.MessageSuccessfullySent');
    });
  }

  onRemoveJuror(user: UserInterface): void {
    this.challengeService
      .deleteJurors(this.challengeId, [user.id])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.refreshJurorList$.next();
        this.toastService.showSuccess('UI.Toast.DeletedSuccessfully');
      });
  }

  onRemovePendingJuror(user: UserInterface): void {
    this.challengeService
      .deleteJurors(this.challengeId, [user.id])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.refreshPendingList$.next();
        this.toastService.showSuccess('UI.Toast.DeletedSuccessfully');
      });
  }

  onExtraActionClicked(event: PeopleAction): void {
    if (event.action === PeopleActionEnum.Accept) {
      this.acceptJurors();
    }

    if (event.action === PeopleActionEnum.Deny) {
      this.denyJurors();
    }
  }

  acceptJurors(): void {
    this.challengeService
      .acceptJurors(this.challengeId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastService.showSuccess('UI.Toast.InvitationAccepted');
        this.refreshPendingList$.next();
        this.refreshJurorList$.next();
        this.acceptedAsJuror.next();
      });
  }

  denyJurors(): void {
    this.challengeService
      .denyJurors(this.challengeId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastService.showSuccess('UI.Toast.InvitationRejected');
        this.refreshPendingList$.next();
      });
  }

  onSave(): void {
    if (!this.jurorRatingLink) return;

    this.requesting = true;
    this.hasSaved = true;

    this.challengeService
      .updateRatingLink(this.challengeId, this.jurorRatingLink)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.requesting = false;
        this.editing = false;
        this.toastService.showSuccess('UI.Toast.UpdatedSuccessfully');
      });
  }

  cancel(): void {
    this.editing = false;

    if (!this.hasSaved) {
      this.jurorRatingLink = { ...this.previousJurorRatingLink };
    } else {
      this.getRatingLink();
    }
  }

  filterChanged(state: JurorFormState): void {
    this.currentFormState = state;

    if (state === JurorFormState.Rating) {
      this.getRatingLink();
    }
  }

  getChallengeDetailUrl(challenge: OrganizationInterface): string {
    const challengePath = this.sessionService.appendLanguagePath(
      environment.jipUrl.challenges
    );

    return `${this.window.origin}${challengePath}/${challenge.id}`;
  }

  private getJurorList(): void {
    this.jurors$ = this.challengeService.getJurors(this.challengeId).pipe(
      pluck('items'),
      tap((jurors) => {
        this.isJuror = jurors.some(({ id }) => id === this.profile?.id);
      }),
      repeatWhen(() => this.refreshJurorList$.asObservable())
    );
  }

  private getPendingJurors(): void {
    this.challengeService
      .getPendingJurors(this.challengeId)
      .pipe(
        pluck('items'),
        repeatWhen(() => this.refreshPendingList$.asObservable()),
        untilDestroyed(this)
      )
      .subscribe((res: UserInterface[]) => {
        this.pendingJurors = res;

        this.isSinglePendingJuror =
          res.length === 1 && res[0].id === this.profile.id;

        this.pendingListTitle =
          (this.isAdmin && !this.isSinglePendingJuror) || !res.length
            ? 'UI.Challenge.PendingJurors'
            : 'UI.Challenge.BeingInvitedAsAJuror';
      });
  }

  private getRatingLink(): void {
    this.challengeService
      .getRatingLink(this.challengeId)
      .pipe(untilDestroyed(this))
      .subscribe((res: JurorRating) => {
        this.hasSaved = false;
        this.jurorRatingLink = res;
        this.previousJurorRatingLink = { ...this.jurorRatingLink };
      });
  }
}
