import { SectionId } from '@src/app/shared/enums/section-id.enum';
import { TemplateName } from '../constants/visibility-config.const';

export function getActiveSectionIds(
  uiTemplate: TemplateName,
  isCreating: boolean,
  isShownAsChallenge: boolean
): string[] {
  const defaultIds = [
    'people-panel',
    'partner-org-panel',
    'partner-challenge-panel',
    'ventures-panel',
    'events-panel',
    SectionId.JuryPanel,
    'chart-panel',
    'expertise-panel',
    'timeline-panel',
    'location-panel',
    isCreating ? 'advanced-setting-panel' : '',
    'org-custom-attributes-panel',
  ];

  const customIds = [
    'ventures-panel',
    'timeline-panel',
    'location-panel',
    isCreating ? 'advanced-setting-panel' : '',
    'org-custom-attributes-panel',
  ];

  if (!uiTemplate || !isShownAsChallenge) return defaultIds;

  if (uiTemplate === TemplateName.Reduced3) {
    return customIds;
  } else {
    return defaultIds;
  }
}
