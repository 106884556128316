<div class="search-results">
  <div class="search-results__body px-3">
    <app-search-filter
      class="pt-4 pb-3"
      (filterChanged)="onFilterChanged($event)"
      [model]="searchEntitiesService.currentFilter"
    ></app-search-filter>

    <h6
      *ngIf="searchEntitiesService?.filterDTO?.keyword as keyword"
      class="mb-3"
    >
      <span translate>UI.Common.SearchResultsFor</span>
      <strong> "{{ keyword }}" </strong>
    </h6>

    <ng-container *ngFor="let section of sections$ | async">
      <ng-container *ngIf="section.content | async as response">
        <app-search-section-layout
          (pageChanged)="searchEntitiesService.changePaging($event)"
          (viewMoreSpecificEntity)="onViewSpecificEntity(section.key)"
          [collectionSize]="response.rowCount"
          [isEmpty]="!response?.items.length"
          [isShowPaging]="isShowPaging"
          [page]="response.pageIndex"
          [title]="section.label"
          [pageSize]="response.pageSize"
          class="mb-4"
        >
          <div
            *ngIf="response?.items.length; else emptyContent"
            [ngSwitch]="section.key"
          >
            <div *ngSwitchCase="EntityName.Challenge" class="mb-4">
              <div *ngFor="let challenge of response?.items" class="mb-3">
                <app-organization-card
                  [data]="challenge"
                  [isLabelNameShown]="false"
                ></app-organization-card>
              </div>
            </div>

            <div *ngSwitchCase="EntityName.Venture" class="mb-4">
              <div *ngFor="let venture of response?.items" class="mb-3">
                <app-venture-card 
                  [data]="venture"
                  [uiTemplate]="(venture.attributeValues | attributeValue : 'UiTemplate')?.describedValue?.value"
                ></app-venture-card>
              </div>
            </div>

            <div *ngSwitchCase="EntityName.Person" class="mb-4">
              <div *ngFor="let people of response?.items" class="mb-3">
                <app-person-card [data]="people"></app-person-card>
              </div>
            </div>

            <div *ngSwitchCase="EntityName.Event" class="mb-4">
              <div *ngFor="let event of response?.items" class="mb-3">
                <app-event-card [data]="event"></app-event-card>
              </div>
            </div>

            <div *ngSwitchCase="EntityName.Organization" class="mb-4">
              <div *ngFor="let org of response?.items" class="mb-3">
                <app-organization-card
                  [data]="org"
                  [isLabelNameShown]="false"
                ></app-organization-card>
              </div>
            </div>

            <div *ngSwitchCase="EntityName.News" class="mb-4">
              <app-news-and-message-list
                (commentModalClosed)="syncCommentStateCount($event)"
                [currentUserId]="(authService.getProfile() | async)?.id"
                [items]="response?.items"
              ></app-news-and-message-list>
            </div>
          </div>
        </app-search-section-layout>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #emptyContent>
  <app-empty-content></app-empty-content>
</ng-template>
