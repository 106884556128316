import {
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import {
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrgsSearchMode } from '@shared/enums/org-search-mode.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import {
  AttributeValue,
  MetaInformation,
} from '@shared/interfaces/attribute-description.interface';
import { LanguageInterface } from '@shared/interfaces/language.interface';
import { MultilingualFormDataManagementService } from '@shared/services/multilingual-form-data-management.service';
import { ArrayUtils } from '@shared/utils/array-utils';
import { FormUtils } from '@shared/utils/form-utils';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { DependantSelectionService } from '@src/app/core/form/dependant-selection.service';
import {
  FormComponentInterface,
  FormErrorService,
} from '@src/app/core/form/form-error.service';
import {
  UnsavedFormCheckService,
  UnsavedFormComponent,
} from '@src/app/core/form/unsaved-form-check.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { CentralConfigService } from '@src/app/core/services/central-config.service';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
import { LoadingService } from '@src/app/core/services/loading.service';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import { EntityStateCountComponent } from '@src/app/shared/components/entity-state-count/entity-state-count.component';
import { placeholderImg } from '@src/app/shared/constants/common';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import {
  AttributeType,
  PatternType,
} from '@src/app/shared/enums/attribute-type.enum';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { InternalIcon } from '@src/app/shared/enums/internal-icon.enum';
import { StorageEnum } from '@src/app/shared/enums/storage.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { VentureOriginField } from '@src/app/shared/enums/venture.enum';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { BaseEntityEditInterface } from '@src/app/shared/interfaces/base/base-entity-edit.interface';
import { JoinState } from '@src/app/shared/interfaces/join.interface';
import { MetadataRecord } from '@src/app/shared/interfaces/metadata-table.interface';
import { ApiResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { PaginationSettingPlace } from '@src/app/shared/models/pagination.model';
import { AttributeValuePipe } from '@src/app/shared/pipes/attribute-value.pipe';
import {
  CustomMetadataUtils,
  ORG_SPECIFIC_INFO_SUPPORT_ATTRIBUTE_TYPE,
} from '@src/app/shared/utils/custom-metadata-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  Subscriber,
  combineLatest,
  from,
  of,
  timer,
  zip,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  finalize,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-base-entity-edit',
  template: '',
})
export class BaseEntityEditComponent<T extends BaseEntityEditInterface>
  extends EntityStateCountComponent
  implements
    OnInit,
    OnChanges,
    OnDestroy,
    UnsavedFormComponent,
    FormComponentInterface
{
  @Input() id: number;
  @Input() isCreating = false;

  portalName: string = environment.portalName;
  portalUrl: string = environment.portalUrl;
  placeholderImg = placeholderImg;

  allType = AttributeType;
  entityOriginField = VentureOriginField;

  editable = false;
  isOwner = false;
  isTeam = false;
  initiators: UserInterface[] = [];

  entity: T;
  entityName: EntityName;
  EntityName = EntityName;
  orgsSearchMode: OrgsSearchMode;
  OrgsSearchMode = OrgsSearchMode;
  PaginationSettingPlace = PaginationSettingPlace;
  InternalIcon = InternalIcon;
  DateFormat = DateFormat;

  selectedEntityId: number;

  form: UntypedFormGroup;

  formSections: FormSection[] = [];

  editingSectionsIndex: number[] = [];
  sectionLanguage = new Map();

  errorMessage: { [key: string]: string };
  formErrorKey;

  isLogin = false;
  isSubmitting = false;
  isSubmitted = false;
  isGoingToOtherPage = false;
  isLeavingEntity = false;

  entityRoot = '';

  globalOrgId = 0;
  uiTemplateName: TemplateName = TemplateName.Default;
  uiTemplateName$ = new BehaviorSubject<TemplateName>(TemplateName.Default);

  ventureDemands;

  profile$: Observable<UserInterface>;
  profile: UserInterface;
  stakeholders: UserInterface[] = [];

  isLoadingEntity = false;
  isVentureGridView = true;
  isVentureFilterActive = false;
  isSharedVentureExpanded = false;

  bannerImageCropRatio = 20 / 7;

  protected notAllowUpdateFields: string[] = [];

  //#region CUSTOM ATTRIBUTE VARIABLES
  initFormSection: FormSection[] = [];

  customMetadata: MetadataRecord = {
    attributeDescriptions: [],
    attributeValues: [],
  };

  isCustomAttributeFormGenerated = false;
  protected isSaveFromCustomAttributeSection = false;
  //#endregion

  protected enablePatch = true;

  protected changeFormStatusSubject = new Subject<void>();

  //#region SERVICES
  public formErrorService: FormErrorService;
  public unsavedFormCheckService: UnsavedFormCheckService;
  protected fb: UntypedFormBuilder;
  protected modalService: NgbModal;
  protected customFormService: CustomFormService;
  protected toastService: ToastService;
  protected globalLoadingService: LoadingService;
  protected filterStoredService: GlobalFilterStoredService;
  protected attributeValuePipe: AttributeValuePipe;
  protected readonly formDataService = this.injector.get(
    MultilingualFormDataManagementService
  );
  protected dependantSelectionService: DependantSelectionService;
  private centralConfigSerivce: CentralConfigService;
  //#endregion End Services

  get leadCompany(): AbstractControl {
    return this.form?.controls?.LeadCompany;
  }

  constructor(
    public baseHttpService: BaseHttpService<any>,
    protected authenService: AuthenService,
    protected sessionService: SessionService,
    protected injector: Injector
  ) {
    super();

    this.filterStoredService = this.injector.get<GlobalFilterStoredService>(
      GlobalFilterStoredService
    );
    this.attributeValuePipe =
      this.injector.get<AttributeValuePipe>(AttributeValuePipe);

    this.fb = this.injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.modalService = this.injector.get<NgbModal>(NgbModal);
    this.customFormService =
      this.injector.get<CustomFormService>(CustomFormService);
    this.toastService = this.injector.get<ToastService>(ToastService);
    this.formErrorService =
      this.injector.get<FormErrorService>(FormErrorService);
    this.unsavedFormCheckService = this.injector.get<UnsavedFormCheckService>(
      UnsavedFormCheckService
    );
    this.globalLoadingService =
      this.injector.get<LoadingService>(LoadingService);
    this.dependantSelectionService =
      this.injector.get<DependantSelectionService>(DependantSelectionService);
    this.centralConfigSerivce = this.injector.get(CentralConfigService);

    this.customInit();

    this.initFormSection = [...this.formSections];

    combineLatest([
      this.sessionService.apiReady$,
      timer(500).pipe(
        untilDestroyed(this),
        switchMap(() => this.getOrgId())
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([ready, orgId]: [boolean, number]) => {
        if (ready) {
          this.handleOrgId(orgId);
        }
      });

    this.sessionService.isOnDetailPage =
      !window.location.pathname.includes('/create');

    this.profile$ = this.authenService.getProfile();
    this.profile$
      .pipe(untilDestroyed(this))
      .subscribe((profile: UserInterface) => {
        this.profile = profile;
      });

    this.changeFormStatusSubject
      .pipe(untilDestroyed(this), debounceTime(500))
      .subscribe(() => {
        if (!this.form) {
          this.changeFormStatusSubject.next();
        } else {
          this.changeFormStatus();
        }
      });

    this.formDataService.baseHttpService = this.baseHttpService;
    this.sectionLanguage = this.formDataService.sectionsLanguage;
  }

  public static hasPermission(
    profile: UserInterface,
    entity,
    initiators = null
  ): boolean {
    if (!profile || !entity) {
      return false;
    }

    if (initiators === null || initiators?.length === 0) {
      initiators =
        entity?.initiators ?? (entity?.initiator ? [entity?.initiator] : []);
    }

    return (
      ArrayUtils.hasItem(initiators, profile, 'id') ||
      ArrayUtils.hasItem(initiators, profile?.id)
    );
  }

  customInit(): void {
    /**/
  }

  isFormNotSaved(): boolean {
    return (
      (this.isCreating && !this.isSubmitting) ||
      this.editingSectionsIndex.length > 0
    );
  }

  getRequiredKeys(): string[] {
    return [];
  }

  getForm(): UntypedFormGroup {
    return this.form;
  }

  generateForm(): void {
    let params = new HttpParams();

    if (this.globalOrgId) {
      params = params.set(UrlParam.FilterTokenId, this.globalOrgId.toString());
    }

    this.baseHttpService
      .getAttributeDescription(params)
      .pipe(untilDestroyed(this))
      .subscribe((descriptions: MetaInformation) => {
        this.createFormUsingMetaInfo(descriptions);
      });
  }

  createFormUsingMetaInfo(descriptions: any): void {
    this.customFormService.setControlConfig(descriptions);
    const controls = this.customFormService.generateControlsConfig(
      this.getRequiredKeys()
    );

    const formGroup = {
      Content: [],
      Image: ['', [Validators.required]],
      ...controls,
    };
    this.form = this.fb.group(formGroup);

    this.formDataService.initializeFormGroup(this.form, this.entity);

    this.afterGenerateFormControl();
  }

  protected afterGenerateFormControl(): void {
    const customValidation = this.getCustomvalidation();
    if (this.form && customValidation) {
      this.form.setValidators(customValidation);
    }
    this.formErrorService.register(this);
    this.unsavedFormCheckService.register(this);
    this.authenService
      ?.isLogin()
      .pipe(untilDestroyed(this))
      .subscribe((isLogin: boolean) => {
        this.isLogin = isLogin;
        this.changeFormStatusSubject.next();
      });
  }

  protected getCustomvalidation(): ValidatorFn | ValidatorFn[] {
    return [];
  }

  isFieldInvalid(key: string): boolean {
    if (!this.form) {
      return true;
    }

    const control = this.form.controls[key];
    if (!control) {
      return true;
    }

    return control.invalid && (control.touched || control.dirty);
  }

  ngOnInit(): void {
    this.listenToRefreshBrowser();
  }

  ngOnDestroy(): void {
    this.unsavedFormCheckService.remove(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id && this.id) {
      this.formDataService.entityId = this.id;

      this.sessionService.apiReady$
        .pipe(untilDestroyed(this))
        .subscribe((apiReady) => {
          if (apiReady) {
            this.loadEntity();
          }
        });
    }
  }

  loadEntity(): void {
    if (this.isCreating) {
      return;
    }
    this.handleLoading(true);
    this.getLatestEntity(this.id);
  }

  syncEntityJoinStates(): void {
    if (this.entity?.id) {
      const stringIds = [String(this.entity?.id)];
      this.baseHttpService
        .getJoinStates(stringIds)
        .pipe(untilDestroyed(this))
        .subscribe((joinStates: JoinState[]) => {
          const foundState = joinStates.find(
            (state) => state.entityId === this.entity.id
          );
          this.entity.hasJoined = foundState ? foundState.hasJoined : false;
          this.entity.canJoin = foundState ? foundState.canJoin : false;
          this.entity.isPending = foundState ? foundState.isPending : false;
        });
    }
  }

  goToListPage(queryParams: { [key: string]: any } = {}): void {
    const currentLocale = this.sessionService
      .getCurrentLanguage()
      .locale.toLowerCase();
    const url = new URL(
      window.location.origin + '/' + currentLocale + this.entityRoot
    );
    for (const key in queryParams) {
      url.searchParams.set(key, queryParams[key]);
    }
    this.isGoingToOtherPage = true;
    this.goTo(url.toString());
  }

  changeFormStatus(): void {
    if (this.isCreating) {
      this.editable = true;
      setTimeout(() => {
        this.form.enable();
      });
    } else {
      this.handleFormValue();
      this.authenService.profile$
        .pipe(untilDestroyed(this))
        .subscribe((profile: UserInterface) => {
          this.isOwner = this.isInitiator(profile, this.entity);

          this.isTeam = ArrayUtils.hasItem(
            this.entity?.stakeholders,
            profile,
            'id'
          );

          if (this.hasEditPermision(profile, this.entity)) {
            this.editable = true;
            this.form.enable();
          } else {
            this.editable = false;
            this.form.disable();
          }
          this.afterFormChangedStatus();
        });
    }
  }

  protected afterFormChangedStatus(): void {
    // Implement in inherit entity
  }

  hasEditPermision(profile: UserInterface, entity: T): boolean {
    return this.isInitiator(profile, entity);
  }

  isInitiator(profile: UserInterface, entity: T): boolean {
    return BaseEntityEditComponent.hasPermission(
      profile,
      entity,
      this.initiators
    );
  }

  handleFormValue(): void {
    if (this.isCreating) {
      return;
    }

    if (this.entity) {
      if (!this.form) {
        setTimeout(() => {
          this.handleFormValue();
        }, 200);
        return;
      } else {
        this.updateFormValue();
      }
    }
  }

  updateFormValue(): void {
    if (!this.customFormService.entityDescription) {
      return;
    }

    for (const attr of this.customFormService.entityDescription
      .attributeDescriptions) {
      const key = attr.propertyName;
      this.updateFieldValue(key);
    }

    if (this.customFormService.customAttributeDescriptions?.length > 0) {
      for (const attr of this.customFormService.customAttributeDescriptions) {
        const key = attr.propertyName;
        this.updateFieldValue(key);
      }
    }
  }

  updateFieldValue(key): void {
    let value = this.getFormFieldValueFromEntity(key);

    value = key === 'BestPractices' ? value ?? null : value;

    this.form.patchValue(
      {
        [key]: value,
      },
      {
        emitEvent: false,
      }
    );
  }

  getFieldOptions(key, entity?): AttributeDescription {
    entity = entity || this.entity;
    return entity?.attributeDescriptions
      ? FormUtils.getFieldOptions(entity?.attributeDescriptions, key)
      : this.customFormService.getFieldOptions(key);
  }

  onSubmit(): void {
    FormUtils.maskFormAsTouched(this.form);
    if (this.form.invalid) {
      this.toastService.removeAll();
      this.toastService.showError('UI.Toast.CheckMandatoryField', '', 5000);
      return;
    }

    this.isSubmitting = true;
    this.isSubmitted = false;
    const dto = this.getDto();

    this.handleApi(dto)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isSubmitting = false;
        this.isSubmitted = true;
      });
  }

  handlePatchApi(
    dto: any,
    headers?: HttpHeaders,
    showToast?: boolean
  ): Observable<boolean> {
    return this.handleApi(dto, true, headers, showToast);
  }

  handleApi(
    dto,
    usePatchApi = false,
    headers?: HttpHeaders,
    showToast = true
  ): Observable<boolean> {
    dto = this.formatDto(dto);
    let $entityApi: Observable<any>;
    let params = new HttpParams();
    const orgId =
      this.centralConfigSerivce.innovationSpaceId ?? this.globalOrgId;
    if (orgId) {
      params = params.set(UrlParam.FilterTokenId, orgId.toString());
    }

    if (this.isCreating) {
      $entityApi = this.baseHttpService.create(dto, params);
    } else {
      if (!this.entity) {
        return of(false);
      }
      if (usePatchApi && this.enablePatch) {
        if (this.isSaveFromCustomAttributeSection) {
          $entityApi = this.baseHttpService.updateCustomAttribute(
            this.entity.id,
            dto,
            headers
          );
        } else {
          $entityApi = this.baseHttpService.patch(
            this.entity.id,
            dto,
            headers,
            params
          );
        }
      } else {
        $entityApi = this.baseHttpService.update(this.entity.id, dto);
      }
    }
    return new Observable((observer: Subscriber<boolean>) => {
      $entityApi.pipe(untilDestroyed(this)).subscribe({
        next: (result: any) => {
          const error = result as ApiResponse;
          if (error && error.status === 400) {
            this.checkError(error);
            showToast && this.handleApiCommonProcess(observer, false);
          }

          const id = !isNaN(result) ? result : result.id;
          if (id && !isNaN(id)) {
            if (this.isCreating) {
              if (
                this.customFormService.customAttributeDescriptions?.length > 0
              ) {
                this.patchCustomAttributeOnCreating(id)
                  .pipe(
                    untilDestroyed(this),
                    finalize(() => {
                      this.handleApiCommonProcess(observer, true);
                      showToast &&
                        this.toastService.showSuccess(
                          'UI.Toast.SavedSuccessfully'
                        );
                    })
                  )
                  .subscribe({
                    next: (resultPatch: any) => {
                      const errorPatch = resultPatch as ApiResponse;
                      if (errorPatch && errorPatch.status === 400) {
                        this.checkError(errorPatch);
                        this.handleApiCommonProcess(observer, false);
                      }
                      const idPatch = resultPatch;
                      if (idPatch && !isNaN(idPatch)) {
                        this.goTo(`${this.entityRoot}/${idPatch}`);
                      }
                    },
                    error: (err) => {
                      console.log(err);
                      this.handleApiCommonProcess(observer, false);
                    },
                  });
              } else {
                this.goTo(`${this.entityRoot}/${id}`);
                this.handleApiCommonProcess(observer, true);
                showToast &&
                  this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
              }
            } else {
              const orgId =
                this.centralConfigSerivce.innovationSpaceId ?? this.globalOrgId;
              this.getEntityDetails(orgId, id)
                .pipe(
                  untilDestroyed(this),
                  finalize(() => {
                    this.handleApiCommonProcess(observer, true);
                    showToast &&
                      this.toastService.showSuccess(
                        'UI.Toast.SavedSuccessfully'
                      );
                  })
                )
                .subscribe({
                  next: (entity: T) => {
                    this.handleAfterGetEntityDetail(entity);
                  },
                  error: (err) => {
                    console.log(err);
                    this.handleApiCommonProcess(observer, false);
                  },
                });
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          if (error.error?.title) {
            this.toastService.showError(
              StringUtils.extractContent(error.error?.title)
            );
          }
          this.checkError(error);
          this.handleApiCommonProcess(observer, false);
        },
      });
    });
  }

  shouldFetchEntityDetail(): Observable<boolean> {
    return of(true);
  }

  getLatestEntity(entityId: number): void {
    const orgIdFromCentralConfig = this.centralConfigSerivce.innovationSpaceId;
    const latestEntity$ = from(this.getOrgId()).pipe(
      switchMap((orgId) =>
        this.getEntityDetails(orgIdFromCentralConfig ?? orgId, entityId)
      ),
      untilDestroyed(this),
      catchError(() => {
        this.goToListPage();

        return EMPTY;
      }),
      finalize(() => this.handleLoading(false))
    );

    this.shouldFetchEntityDetail()
      .pipe(
        switchMap((canViewEntity) => {
          if (!canViewEntity) {
            this.handlePrivateEntity();
            return EMPTY;
          } else {
            return latestEntity$;
          }
        })
      )
      .subscribe((entity: T) => {
        this.handleAfterGetEntityDetail(entity);

        this.setDisplayUiTemplate(false);
      });
  }

  handlePrivateEntity() {
    window.location.replace(`${this.entityRoot}?${UrlParam.NotFound}=true`);
  }

  getEntityDetails = (organizationId: number, entityId: number) => {
    const params = organizationId
      ? new HttpParams()
          .set('organizationId', organizationId.toString())
          .set('Id', entityId)
      : null;

    return this.baseHttpService.read(entityId, null, params);
  };

  protected handleAfterGetEntityDetail(entity: T): void {
    this.entity = entity;
    if (this.entityName) {
      this.runEntityStateCount(
        this.entityName,
        [this.entity],
        this.baseHttpService
      );
    }
    this.handleCustomMetadata(
      entity?.attributeDescriptions,
      entity?.attributeValues
    );
    this.changeFormStatusSubject.next();
  }

  protected handleApiCommonProcess(
    observer: Subscriber<boolean>,
    result: boolean
  ): void {
    observer.next(result);
    observer.complete();
    this.isSaveFromCustomAttributeSection = false;
  }

  formatDto(dto: any): any {
    if (dto) {
      for (const key of Object.keys(dto)) {
        this.formatField(dto, key);
      }
    }
    dto = this.addCustomVariableDto(dto);
    return dto;
  }

  addCustomVariableDto(dto: any): any {
    // Implement in inherit entity
    return dto;
  }

  formatField(dto, key): void {
    let type = this.getFieldOptions(key, this.entity)?.attributeType;
    if (
      !type &&
      this.customFormService.customAttributeDescriptions?.length > 0
    ) {
      type =
        this.customFormService.getCustomAttributeFieldOptions(
          key
        )?.attributeType;
    }
    CustomMetadataUtils.formatDtoValueForUpdateCustomAttribute(dto, key, type);
  }

  checkError(data: any): void {
    this.formErrorService.handleError(data);
  }

  getDto(): T {
    const result: T = {} as T;
    if (this.form && this.form.getRawValue()) {
      for (const controlName of Object.keys(this.form.controls)) {
        Object.assign(result, this.getDtoField(controlName));
      }
    }
    return result;
  }

  getImageDto(): any {
    let imgId = 0;
    const imageValue = this.form?.value.Image;

    if (imageValue?.id) {
      imgId = imageValue?.id;
    }

    return {
      imageId: imgId,
    };
  }

  saveSection(...index: number[]): Observable<boolean> {
    const sectionLanguage = this.formDataService.sectionsLanguage;
    if (index.some((i) => sectionLanguage.has(i))) {
      return this.saveSectionMultiLocale(...index);
    } else {
      return this.saveSectionWithSingleLocale(...index);
    }
  }

  saveSectionWithSingleLocale(...index: number[]): Observable<boolean> {
    const isValidSection = index.every((i) => this.isValidSection(i));

    if (isValidSection) {
      let dto;
      if (this.enablePatch) {
        dto = this.getSectionInfo(...index);
      } else {
        dto = this.entity || {};
      }

      return this.handlePatchApi(dto).pipe(
        tap((success) => {
          if (success) {
            index.forEach((i) => this.removeEditingSectionIndex(i));
          }
        })
      );
    }
    return of(false);
  }

  saveSectionMultiLocale(...index: number[]): Observable<boolean> {
    const sectionsLanguage = this.formDataService.sectionsLanguage;
    const isValidSection = index.every((i) => this.isValidSection(i));

    if (!isValidSection) {
      return of(false);
    }

    const patches = [];

    if (this.enablePatch) {
      const patchMainLocale = this.handlePatchApi(
        this.getSectionInfo(...index)
      );
      patches.push(patchMainLocale);

      sectionsLanguage.get(index[0]).forEach((locale) => {
        const dto = this.getSectionInfoByLocale(locale, ...index);
        const headers: HttpHeaders = new HttpHeaders({ 'jip-locale': locale });

        patches.push(this.handlePatchApi(dto, headers, false));
      });
    } else {
      patches.push(this.entity || {});

      sectionsLanguage.get(index[0]).forEach((locale2) => {
        const headers: HttpHeaders = new HttpHeaders({ 'jip-locale': locale2 });
        const dto = this.formDataService.forms.get(locale2).entity || {};

        patches.push(this.handlePatchApi(dto, headers));
      });
    }

    return zip(...patches).pipe(
      tap(() =>
        index.forEach((i) => {
          this.removeEditingSectionIndex(i);
          this.sectionLanguage.delete(i);
        })
      )
    ) as Observable<boolean>;
  }

  cancelSection(index: number): void {
    const section = this.formSections[index];
    if (!this.form || !section || !this.entity) {
      return;
    }

    this.removeEditingSectionIndex(index);
    const previousValue = {};

    for (const name of section.fieldsName) {
      previousValue[name] = this.getFormFieldValueFromEntity(name);
    }

    this.form.patchValue(previousValue);

    this.onRemoveLanguage(index);
  }

  cancelCompany(
    index: number,
    clearCompanyInput: () => void,
    fieldName: string
  ): void {
    const section = this.formSections[index];

    if (!this.form || !section || !this.entity) return;

    const previousCompany = this.getFormFieldValueFromEntity(fieldName);

    if (previousCompany) return;

    clearCompanyInput();
  }

  onRemoveLanguage(index: number | string, locale?: string): void {
    this.formDataService.removeLanguage(index, locale);
  }

  markEditingSectionIndex(index: number): void {
    this.editingSectionsIndex.push(index);
  }

  /**
   * Call after patch update success
   */
  removeEditingSectionIndex(index: number): void {
    this.editingSectionsIndex = ArrayUtils.removeItem(
      this.editingSectionsIndex,
      index
    );
  }

  getFormFieldValueFromEntity(name: string): any {
    const entityValue = this.entity[StringUtils.toLowerCaseFirstLetter(name)];
    if (entityValue !== undefined) {
      return entityValue;
    }
    // Handle for custom metadata record
    if (this.customMetadata.attributeDescriptions?.length > 0) {
      return CustomMetadataUtils.getAttributeValueByPropertyName(
        this.customMetadata.attributeDescriptions,
        this.customMetadata.attributeValues,
        name
      );
    }
  }

  isValidSection(index: number): boolean {
    const section = this.formSections[index];
    let isValid = true;
    if (!this.form || !section) {
      return true;
    }

    for (const name of section.fieldsName) {
      const control: AbstractControl = this.form.controls[name];

      if (control && control.invalid) {
        isValid = false;
      }
      if (control) {
        control.markAllAsTouched();
      }
    }

    return isValid;
  }

  getSectionInfo(...index: number[]): any {
    let values = {};

    const getFormSection = (i: number) => {
      const section = this.formSections[i];
      if (!this.form || !section) {
        return {};
      }
      const result = {};
      for (const name of section.fieldsName) {
        if (!this.notAllowUpdateFields.includes(name)) {
          Object.assign(result, this.getDtoField(name, true));
        }
      }

      values = { ...values, ...result };
    };

    index.forEach(getFormSection);

    return values;
  }

  getSectionInfoByLocale(locale: string, ...index: number[]): any {
    let values = {};
    const sectionLanguage = this.formDataService.sectionsLanguage;

    const getFormSection = (i: number) => {
      const section = this.formSections[i];

      if (!sectionLanguage.has(i) || !section) {
        return {};
      }
      const result = {};
      for (const name of section.fieldsName) {
        if (!this.notAllowUpdateFields.includes(name)) {
          Object.assign(result, this.getDtoFieldByLocale(name, locale, true));
        }
      }

      values = { ...values, ...result };
    };

    index.forEach(getFormSection);

    return values;
  }

  getDtoField(name, force = false): {} {
    let formValue = this.form.getRawValue()[name];
    const attributeType = this.getFieldOptions(name)?.attributeType;

    if (
      attributeType === AttributeType.Integer ||
      attributeType === AttributeType.Double
    ) {
      if (!formValue) {
        formValue = 0;
      }
    }

    if (
      attributeType === AttributeType.String &&
      formValue &&
      typeof formValue === 'string'
    ) {
      formValue = formValue.trim();
    }

    if (
      attributeType === AttributeType.Link &&
      !this.isSaveFromCustomAttributeSection
    ) {
      formValue = formValue?.url ?? formValue;
    }

    if (formValue || force) {
      return {
        [StringUtils.toLowerCaseFirstLetter(name)]: formValue,
      };
    }

    return {};
  }

  getDtoFieldByLocale(name: any, locale: string, force = false): {} {
    const formControl = this.formDataService.forms.get(locale).form;

    let formValue = formControl.getRawValue()[name];

    const entity = this.formDataService.forms.get(locale).entity;

    const attributeType = this.getFieldOptions(name, entity)?.attributeType;

    if (
      attributeType === AttributeType.Integer ||
      attributeType === AttributeType.Double
    ) {
      if (!formValue) {
        formValue = 0;
      }
    }

    if (
      attributeType === AttributeType.Link &&
      !this.isSaveFromCustomAttributeSection
    ) {
      formValue = formValue?.url ?? formValue;
    }

    if (formValue || force) {
      return {
        [StringUtils.toLowerCaseFirstLetter(name)]: formValue,
      };
    }
    return {};
  }

  goTo(url): void {
    FormUtils.navigateTo(this.sessionService.appendLanguagePath(url));
  }

  toggleFavorite(): void {
    if (!this.sessionService.isLogin) {
      return;
    }
    if (!this.entity.isFavorite) {
      this.baseHttpService
        .follow(this.entity.id)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.entity.isFavorite = !this.entity.isFavorite;
          this.entity.followerCount = this.entity.followerCount + 1;
        });
    } else {
      this.baseHttpService
        .unfollow(this.entity.id)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.entity.isFavorite = !this.entity.isFavorite;
          this.entity.followerCount =
            this.entity.followerCount > 0 ? this.entity.followerCount - 1 : 0;
        });
    }
  }

  toggleLike(entity: T): void {
    if (!this.sessionService.isLogin) {
      return;
    }
    if (entity) {
      if (!entity.isLiked) {
        this.baseHttpService
          .like(entity.id)
          .pipe(untilDestroyed(this))
          .subscribe((newLikeId: number) => {
            entity.isLiked = !entity.isLiked;
            entity.likesCount = entity.likesCount + 1;
            entity.likeId = newLikeId;
          });
      } else {
        this.baseHttpService
          .unlike(entity.likeId)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            entity.isLiked = !entity.isLiked;
            entity.likesCount =
              entity.likesCount > 0 ? entity.likesCount - 1 : 0;
            entity.likeId = null;
          });
      }
    }
  }

  showEntityData(): void {
    this.selectedEntityId = null;
    setTimeout(() => {
      this.selectedEntityId = this.entity?.id;
    });
  }

  afterLeaveEntity(isLeaveSuccess: boolean): void {
    if (isLeaveSuccess) {
      this.isTeam = false;
      this.getLatestEntity(this.id);
    }
  }

  onCommentModalClosed(): void {
    this.syncCommentsState();
  }

  protected handleSyncEntityStateCount(): void {
    this.syncLikingsState();
    this.syncFollowingsState();
    this.syncCommentsState();
  }

  protected handleLoading(isLoading: boolean): void {
    this.isLoadingEntity = isLoading;
    this.globalLoadingService.setLoadingState(isLoading);
  }

  // #region HANDLE CUSTOM ATTRIBUTE SECTION
  saveCustomAttributeSection(index: number): Observable<boolean> {
    this.isSaveFromCustomAttributeSection = true;
    return this.saveSection(index);
  }

  handleCustomMetadata(
    attributeDescriptions: AttributeDescription[] = [],
    attributeValues: AttributeValue[] = []
  ): void {
    if (attributeDescriptions?.length > 0) {
      const sectionCustomMetadata: FormSection = {
        fieldsName: [],
      };
      const filterType = [PatternType.Dedicated];

      this.customMetadata = CustomMetadataUtils.filterMetadataRecord(
        attributeDescriptions,
        attributeValues,
        filterType,
        ORG_SPECIFIC_INFO_SUPPORT_ATTRIBUTE_TYPE
      );

      this.formDataService.customMetadata = this.customMetadata;

      if (this.customMetadata.attributeDescriptions.length > 0) {
        this.customMetadata.attributeDescriptions.forEach((attrDes) => {
          sectionCustomMetadata.fieldsName.push(attrDes.propertyName);
        });

        if (sectionCustomMetadata.fieldsName.length > 0) {
          // Default form
          if (this.formSections.length === this.initFormSection.length) {
            this.addCustomAttributeSection(sectionCustomMetadata);
          } else if (
            !ArrayUtils.isEqual(
              this.formSections[this.formSections.length - 1].fieldsName,
              sectionCustomMetadata.fieldsName
            )
          ) {
            this.resetCustomAttributeSection();
            this.addCustomAttributeSection(sectionCustomMetadata);
          }
        }
      } else {
        this.resetCustomAttributeSection();
      }
      this.afterGenerateFormControl();
    } else {
      this.changeFormStatusSubject.next();
    }
  }

  private addCustomAttributeSection(customSection: FormSection): void {
    this.formSections.push(customSection);
    this.generateCustomAttributeForm(this.customMetadata.attributeDescriptions);
  }

  private resetCustomAttributeSection(): void {
    this.formSections = [...this.initFormSection];
    this.removeCustomAttributeForm();
  }

  generateCustomAttributeForm(
    attributeDescriptions: AttributeDescription[] = []
  ): void {
    if (this.form && attributeDescriptions?.length > 0) {
      this.customFormService.setCustomAttributeControlConfig(
        attributeDescriptions
      );

      const customControls = this.customFormService.createControl(
        attributeDescriptions,
        this.getRequiredKeys()
      );

      for (const key in customControls) {
        const control = this.form.get(key);
        if (control) {
          customControls[key][0] = control.value;
        }
      }

      this.form = this.fb.group({
        ...this.form.controls,
        ...customControls,
      });

      this.isCustomAttributeFormGenerated = true;
    }
  }

  removeCustomAttributeForm(): void {
    if (this.customFormService.customAttributeDescriptions?.length > 0) {
      const customAttributePropertyNames =
        CustomMetadataUtils.getListPropertyName(
          this.customFormService.customAttributeDescriptions
        );

      const newCustomAttributePropertyNames =
        CustomMetadataUtils.getListPropertyName(
          this.customMetadata.attributeDescriptions
        );

      if (customAttributePropertyNames.length > 0) {
        customAttributePropertyNames.forEach((propertyName: string) => {
          if (
            this.form.get(propertyName) &&
            newCustomAttributePropertyNames.includes(propertyName)
          ) {
            return;
          }
          this.form.removeControl(propertyName);
        });
      }
      this.customFormService.setCustomAttributeControlConfig([]);
    }
    this.isCustomAttributeFormGenerated = false;
  }

  private patchCustomAttributeOnCreating(newEntityId: number): Observable<any> {
    let payloadCustomAttr = {};
    if (!this.form) {
      return of(null);
    }
    for (const attr of this.customFormService.customAttributeDescriptions) {
      const key = attr.propertyName;
      payloadCustomAttr[key] = this.form.controls[key].value;
    }
    payloadCustomAttr = this.formatDto(payloadCustomAttr);
    return this.baseHttpService.updateCustomAttribute(
      newEntityId,
      payloadCustomAttr
    );
  }

  generateCustomAttributeFormWhenCreate(
    leadCompanyId: string,
    submittedOrganizations: string[] = []
  ): void {
    if (this.isCreating && this.form) {
      if (!leadCompanyId) this.setDisplayUiTemplate(true);

      this.baseHttpService
        .getCustomAttributeDescription(leadCompanyId, submittedOrganizations)
        .pipe(untilDestroyed(this))
        .subscribe((attributeDescriptions: AttributeDescription[]) => {
          this.handleCustomMetadata(attributeDescriptions);
        });
    }
  }

  // #endregion

  onLanguageChange(
    language: LanguageInterface,
    sectionIndex: number | string
  ): void {
    this.formDataService.switchLanguage(language, sectionIndex);
  }

  getFormSectionIndex(sectionKey: number | string): UntypedFormGroup {
    if (this.formDataService.sectionsLanguage.has(sectionKey)) {
      const localeKey =
        this.formDataService.sectionsLanguage.get(sectionKey)[0];

      if (localeKey === this.formDataService.defaultLanguage.locale) {
        return this.form;
      }

      return this.formDataService.forms.get(localeKey)?.form;
    }

    return this.form;
  }

  getLocaleBySectionKey(sectionKey: number | string): string {
    const sectionLanguage = this.formDataService.sectionsLanguage;

    if (sectionLanguage.has(sectionKey)) {
      return this.sectionLanguage.get(sectionKey)[0];
    }

    return this.formDataService.defaultLanguage.locale;
  }

  async getOrgId(): Promise<number> {
    if (this.sessionService.isHeaderVisible) {
      const filterCriteria = await this.filterStoredService.getFilterCriteria();
      this.globalOrgId = filterCriteria?.organizationIds?.[0];
    }

    return +this.globalOrgId;
  }

  setDisplayUiTemplate(
    fromDescription = false,
    customAttributeValues: AttributeValue[] = null,
    customAttributeDescriptions: AttributeDescription[] = null
  ): void {
    const key = 'UiTemplate';

    if (customAttributeValues) {
      this.uiTemplateName = FormUtils.getFieldValue(
        customAttributeValues,
        key
      )?.describedValue?.value;
      this.uiTemplateName$.next(this.uiTemplateName);
      return;
    }

    if (customAttributeDescriptions) {
      this.uiTemplateName = FormUtils.getFieldOptions(
        customAttributeDescriptions,
        key
      )?.defaultValue;
      this.uiTemplateName$.next(this.uiTemplateName);
      return;
    }

    const attributeValue = fromDescription
      ? FormUtils.getFieldOptions(
          this.customFormService.entityDescription.attributeDescriptions,
          key
        )?.defaultValue
      : FormUtils.getFieldValue(this.entity.attributeValues, key)
          ?.describedValue?.value;

    this.uiTemplateName = attributeValue;
    this.uiTemplateName$.next(this.uiTemplateName);
  }

  handleOrgId(orgId: number): void {
    this.globalOrgId = orgId;
    this.generateForm();
  }

  listenToRefreshBrowser(): void {
    window.addEventListener('storage', (event) => {
      if (event.key === StorageEnum.refreshBrowser) {
        const refreshBrowser = JSON.parse(
          localStorage.getItem(StorageEnum.refreshBrowser)
        );

        if (refreshBrowser) {
          this.unsavedFormCheckService.clearAll();
          location.reload();
          localStorage.removeItem(StorageEnum.refreshBrowser);
          this.sessionService.deleteCookie(StorageEnum.lastLoggedInUser);
        }
      }
    });
  }

  canInvite(): boolean {
    return this.isOwner || this.isTeam;
  }
}

export interface FormSection {
  fieldsName: string[];
}
