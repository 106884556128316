import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CentralConfigService } from '@core/services/central-config.service';
import {
  NgbActiveModal,
  NgbDateParserFormatter,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import {
  DateFilterPayload,
  DateFilterType,
  FilterPaneFilter,
} from '@shared/models/filter-pane.model';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
import { PaginationSettingService } from '@src/app/core/services/pagination-setting.service';
import { SessionService } from '@src/app/core/session.service';
import { CustomDateParser2Formatter } from '@src/app/shared/components/boostrap-datepicker/boostrap-datepicker.component';
import { AttributeType } from '@src/app/shared/enums/attribute-type.enum';
import { GlobalEventBus } from '@src/app/shared/enums/event-bus.enum';
import { FilterPaneUi } from '@src/app/shared/enums/venture.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { WINDOW } from '@src/app/shared/helpers/window.token';
import {
  AttributeDescription,
  CustomAtrributeValue,
  FilterSortAttributes,
} from '@src/app/shared/interfaces/attribute-description.interface';
import { GlobalFilterCriteria } from '@src/app/shared/interfaces/filters/global-filter.interface';
import {
  PaginationSettingFilter,
  PaginationSettingPlace,
} from '@src/app/shared/models/pagination.model';
import { MediaBreakpoint } from '@src/app/shared/models/ui.model';
import {
  CustomMetadataUtils,
  FILTER_PANE_SUPPORT_ATTRIBUTE_TYPE,
} from '@src/app/shared/utils/custom-metadata-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { isMobile as isTouchDevice } from 'detect-touch-device';
import { EventBusService } from 'ngx-eventbus';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-filter-pane',
  templateUrl: './filter-pane.component.html',
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParser2Formatter },
    NgbActiveModal,
  ],
})
export class FilterPaneComponent implements OnInit, OnDestroy, OnChanges {
  @Input() httpService: BaseHttpService<any>;
  @Input() entityName: string;
  @Input() isShowFilterPane: boolean;
  @Input() orgId: string;
  @Input() filterPaneUi: FilterPaneUi;

  @Output() sortingPayloadChange = new EventEmitter();

  @ViewChild('filterMobile') filterMobile: TemplateRef<any>;

  form: UntypedFormGroup;
  sortForm: UntypedFormGroup;
  isShowfilter = false;
  isFiltering = false;

  customFilters: FilterPaneFilter[] = []; // Using for Single/Multi Selection only
  customSorts: AttributeDescription[] = [];

  AttributeType = AttributeType;
  globalFilterEvent: any;
  organizationId: number;
  paginationSettingPlace: PaginationSettingPlace =
    PaginationSettingPlace.VentureMainList;

  selectedChoices: CustomAtrributeValue[] = [];
  numberOfFiltersApplied = 0;
  isFilterPaneCollapsed = false;
  isMobile = false;
  isTouchDevice = isTouchDevice;
  hasFilters = false;
  FilterPaneUi = FilterPaneUi;
  isFiltered: boolean;
  sortingAttribute: AttributeDescription;
  sortingAttributeDirection = 'ASC';

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const { innerWidth } = event?.target;
    this.isMobile = innerWidth <= MediaBreakpoint.sm;
  }

  constructor(
    public customFormService: CustomFormService,
    protected fb: UntypedFormBuilder,
    protected sessionService: SessionService,
    protected eventBus: EventBusService,
    protected filterStoredService: GlobalFilterStoredService,
    private paginationSettingService: PaginationSettingService,
    private centralConfigService: CentralConfigService,
    protected modalService: NgbModal,
    public activeModal: NgbActiveModal,
    @Inject(WINDOW) private window: Window,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.listenGlobalSelectionFilterEvent();
    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe((ready) => {
        if (ready) {
          this.generateFilterData();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isShowFilterPane) {
      this.setShowFilterPaneOnSession();
    }
  }

  onCheckSelectAll(currentCustomFilter: FilterPaneFilter): void {
    const attrDes = currentCustomFilter.attributeDescription;
    if (attrDes) {
      const propertyName: string = attrDes.propertyName;
      if (this.form && propertyName) {
        if (currentCustomFilter.isSelectAll) {
          this.form.controls[propertyName].setValue(attrDes.choice.selections);
        } else {
          this.form.controls[propertyName].setValue([]);
        }
      }
    }

    if (this.filterPaneUi === FilterPaneUi.Simple) {
      this.updateDto();
    }

    this.updateActiveFilters();
  }

  onClearAll(): void {
    this.clearSelectionFilter();
    this.clearDateFilter();
    this.clearSorting();
    this.updateDto();
    this.numberOfFiltersApplied = 0;
    this.hasFilters = false;
    this.onFilter(true);
  }

  onFilter(isFromFilterBtn = false): void {
    this.emitSortingPayload();
    this.eventBus.triggerEvent(GlobalEventBus.FilterPaneEvent, {
      filterPaneOnly: true,
      isFromFilterBtn,
    });

    this.updateNumberOfFilter();
  }

  onValueSelectionChange(value: any, customFilter: FilterPaneFilter): void {
    const attrDes = customFilter.attributeDescription;
    const controlName = attrDes?.propertyName;
    if (this.form && controlName) {
      let controlValue: CustomAtrributeValue[] =
        this.form.controls[controlName].value;

      if (controlValue?.length < attrDes?.choice.selections.length) {
        customFilter.isSelectAll = false;
      } else {
        customFilter.isSelectAll = true;
      }
      this.updateDto(controlName);
    }
  }

  onDateValueChange(controlName: string): void {
    this.updateDto(controlName);
  }

  updateDto(fieldName: string = ''): void {
    let filterDto: Record<string, any> = {};
    this.filterStoredService
      .getFilterPaneSource()
      .pipe(take(1))
      .subscribe((currentFilter: Record<string, any>) => {
        filterDto = currentFilter;
      });

    if (fieldName) {
      filterDto = this.updateFilterDtoFromControlValue(fieldName, filterDto);
    } else {
      const formValuesObj = this.form.getRawValue();
      for (const key of Object.keys(formValuesObj)) {
        filterDto = this.updateFilterDtoFromControlValue(key, filterDto);
      }
    }

    this.filterStoredService.updateFilterPaneSource(filterDto);
    this.updateActiveFilters();
  }

  protected clearSelectionFilter(): void {
    if (this.customFilters.length > 0) {
      this.customFilters.forEach((customFilter: FilterPaneFilter) => {
        customFilter.isSelectAll = false;
        this.onCheckSelectAll(customFilter);
      });
    }
  }

  private clearDateFilter(): void {
    this.customFormService.customAttributeDescriptions.forEach(
      (attrDes: AttributeDescription) => {
        if (attrDes.attributeType === AttributeType.DateTime) {
          const propertyName = attrDes.propertyName;
          this.form.controls[propertyName].setValue(this.getDefaultDateValue());
        }
      }
    );
  }

  private clearSorting() {
    this.sortForm.reset({
      propertyName: '',
      sortingDirection: 'ASC',
    });
    this.sortingPayloadChange.emit({});
  }

  protected updateFilterDtoFromControlValue(
    fieldName: string,
    filterDto: Record<string, any>
  ): Record<string, any> {
    const type =
      this.customFormService.customAttributeDescriptions?.find(
        (x) => x.propertyName === fieldName
      )?.attributeType || '';
    const resultFilterDto = { ...filterDto };
    const lowerCaseFirstLetterFieldName =
      StringUtils.toLowerCaseFirstLetter(fieldName);

    if (
      type === AttributeType.SingleSelection ||
      type === AttributeType.MultipleSelection
    ) {
      const selectionValues: CustomAtrributeValue[] =
        this.form.controls[fieldName]?.value || [];
      if (Array.isArray(selectionValues) && selectionValues.length > 0) {
        resultFilterDto[lowerCaseFirstLetterFieldName] = selectionValues.map(
          (x) => x?.codeId
        );
      } else {
        delete resultFilterDto[lowerCaseFirstLetterFieldName];
      }
    }

    if (type === AttributeType.DateTime) {
      const dateValue: DateFilterPayload = this.form.controls[fieldName]?.value;
      if (dateValue) {
        if (dateValue.selectedMode === DateFilterType.NoFilter) {
          delete resultFilterDto[lowerCaseFirstLetterFieldName];
        } else {
          resultFilterDto[lowerCaseFirstLetterFieldName] = dateValue;
        }
      }
    }

    return resultFilterDto;
  }

  protected async generateFilterData(): Promise<void> {
    let orgFilterTokenId: number;
    orgFilterTokenId = this.orgId
      ? Number(this.orgId)
      : await this.getOrgFilterTokenId();

    this.httpService
      .getFilter(this.entityName, orgFilterTokenId?.toString())
      .pipe(untilDestroyed(this))
      .subscribe((filterSortAttributes: FilterSortAttributes) => {
        const filterCustomAttributeType = [];
        const customMetadataRecord = CustomMetadataUtils.filterMetadataRecord(
          filterSortAttributes.filterAttributes,
          [],
          filterCustomAttributeType,
          FILTER_PANE_SUPPORT_ATTRIBUTE_TYPE
        );

        if (customMetadataRecord.attributeDescriptions.length > 0) {
          customMetadataRecord.attributeDescriptions.forEach(
            (attrDes: AttributeDescription) => {
              if (
                attrDes.attributeType === AttributeType.SingleSelection ||
                attrDes.attributeType === AttributeType.MultipleSelection
              ) {
                if (attrDes.attributeType === AttributeType.SingleSelection) {
                  // Convert to Multiple Selection
                  attrDes.defaultValue = [];
                  attrDes.originalAttributeType = attrDes.attributeType;
                  attrDes.attributeType = AttributeType.MultipleSelection;
                  if (attrDes.required) {
                    attrDes.choice.selections =
                      attrDes.choice.selections.filter(
                        (x) => x.codeId !== 'DefaultValue'
                      );
                  }
                }
                const customFilter: FilterPaneFilter = {
                  isSelectAll: false,
                  attributeDescription: attrDes,
                };
                this.customFilters.push(customFilter);
                this.customSorts = filterSortAttributes.sortAttributes;
              }
            }
          );
          this.isShowfilter = true;
          this.setShowFilterPaneOnSession();

          if (!this.isShowFilterPane) {
            customMetadataRecord.attributeDescriptions = [];
          }

          this.buildCustomAttributeForm(
            customMetadataRecord.attributeDescriptions
          );
          this.buildSortAttributeForm(this.customSorts);
        } else {
          this.resetFilterPane();
        }
        this.onFilter();
      });
  }

  buildSortAttributeForm(customSorts: AttributeDescription[]) {
    if (customSorts.length > 0) {
      this.sortForm = this.fb.group({
        propertyName: [''],
        sortingDirection: ['ASC'],
      });
    }
  }

  protected buildCustomAttributeForm(
    attrDes: AttributeDescription[] = []
  ): void {
    this.customFormService.setCustomAttributeControlConfig(attrDes);
    const customControls = this.customFormService.createControl(
      attrDes,
      [],
      true
    );
    this.form = this.fb.group(customControls);
    this.updateFormValueFromPaginationFilterStored(attrDes).then(() => {
      this.updateDto();
    });
  }

  private listenGlobalSelectionFilterEvent(): void {
    this.globalFilterEvent = this.eventBus.addEventListener({
      name: GlobalEventBus.GlobalFilterSelectionEvent,
      callback: (payload: GlobalFilterCriteria) => {
        this.isShowfilter = false;
        this.resetFilterPane();
        this.customFilters = [];

        if (payload.organizationIds?.length > 0) {
          this.generateFilterData();
        } else {
          this.resetFilterPane();
        }
      },
    });
  }

  protected resetFilterPane(): void {
    this.filterStoredService.updateFilterPaneSource({});
  }

  protected async getOrgFilterTokenId(): Promise<number> {
    if (this.centralConfigService.innovationSpaceId) {
      return this.centralConfigService.innovationSpaceId;
    }

    if (!this.sessionService.isHeaderVisible) {
      return undefined;
    }

    const persistedFilters = await this.filterStoredService.getFilterCriteria();

    return persistedFilters.organizationIds[0];
  }

  //#region PAGINATION FILTER STORED
  private async updateFormValueFromPaginationFilterStored(
    lstAttrDes: AttributeDescription[] = []
  ): Promise<void> {
    const filterStored =
      await this.paginationSettingService.getPaginationFilterStored(
        this.paginationSettingPlace
      );

    const storedValues = filterStored?.keepFilter;

    if (storedValues) {
      for (const attrDes of lstAttrDes) {
        const key = attrDes.propertyName;

        if (
          attrDes.attributeType === AttributeType.MultipleSelection ||
          attrDes.attributeType === AttributeType.SingleSelection
        ) {
          this.patchSelectionValue(storedValues, key, attrDes);
        }

        if (attrDes.attributeType === AttributeType.DateTime) {
          this.patchDateValue(storedValues, key);
        }
      }
    }
  }

  private patchSelectionValue(
    storedValues: PaginationSettingFilter,
    key: string,
    attrDes: AttributeDescription
  ): void {
    const lstCodeId: string[] =
      storedValues[StringUtils.toLowerCaseFirstLetter(key)];
    if (lstCodeId) {
      const selectionValues: CustomAtrributeValue[] =
        attrDes.choice.selections.filter((x) => lstCodeId.includes(x.codeId)) ||
        [];
      this.form.patchValue({ [key]: selectionValues }, { emitEvent: false });
    }
  }

  private patchDateValue(
    storedValues: PaginationSettingFilter,
    key: string
  ): void {
    const dateValue: DateFilterPayload =
      storedValues[StringUtils.toLowerCaseFirstLetter(key)] ||
      this.getDefaultDateValue();
    this.form.patchValue({ [key]: dateValue }, { emitEvent: false });
  }

  private getDefaultDateValue(): DateFilterPayload {
    return { from: null, to: null, selectedMode: DateFilterType.NoFilter };
  }
  //#endregion

  ngOnDestroy(): void {
    this.eventBus.removeEventListener(this.globalFilterEvent);
  }

  protected setShowFilterPaneOnSession(): void {
    this.sessionService.hasFilterPane =
      this.isShowFilterPane && this.customFilters.length > 0;
  }

  updateNumberOfFilter(): void {
    const control = this.form?.controls;
    if (!control) return;
    this.numberOfFiltersApplied = Object.values(control).reduce(
      (count, { value }) => {
        if (value) {
          if (
            (typeof value === 'object' &&
              value.selectedMode &&
              value.selectedMode !== 'Default') ||
            (typeof value.length !== 'undefined' && value.length)
          ) {
            count++;
          }
        }
        return count;
      },
      0
    );
  }

  openFilterMobile(): void {
    if (this.isMobile || this.isTouchDevice) {
      this.modalService.open(this.filterMobile);
    }
  }

  closeFilterMobile(): void {
    this.modalService.dismissAll();
  }

  updateActiveFilters(): void {
    const hasActiveFilters = () => {
      for (const key in this.form.controls) {
        const value = this.form.controls[key].value;
        if (value) {
          if (Array.isArray(value) && value.length > 0) {
            return true;
          } else if (typeof value === 'object') {
            if (value.selectedMode && value.selectedMode !== 'Default') {
              return true;
            }
          } else {
            return true;
          }
        }
      }
      return false;
    };

    this.hasFilters = hasActiveFilters();
  }

  onValueSortingChange(event) {
    this.emitSortingPayload();
  }

  emitSortingPayload(): void {
    if (!this.customSorts.length) return;
    const { propertyName, sortingDirection } = this.sortForm.getRawValue();
    this.sortingPayloadChange.emit({ propertyName, sortingDirection });
    if (propertyName) {
      this.hasFilters = true;
    }
  }
}
