<ng-container *ngIf="!isLoadingEntity && !isGoingToOtherPage && entity">
  <div class="tenant-configuration">
    <ngb-accordion [activeIds]="['tenant-attributes']" [destroyOnHide]="false">
      <ngb-panel
        cardClass="venture__body venture__accordion ignore-overflow"
        id="tenant-attributes"
      >
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            class="btn d-flex align-items-center justify-content-between w-100 text-left"
            ngbPanelToggle
            type="button"
          >
            <div translate class="pull-left float-left">
              <span
                class="venture__company-header mr-5 d-block d-lg-inline-block"
                >{{ 'UI.TenantConfiguration.Attributes' | translate }}</span
              >
            </div>
            <i
              class="fas float-right pull-right venture__company-header"
              [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
            ></i>
          </button>
        </ng-template>

        <ng-template ngbPanelContent>
          <div
            class="venture__body widget__card--background m-0"
            *ngIf="form && formSections.length > 0"
          >
            <app-form-section
              #sectionTenantAttributes
              [index]="TenantConfigurationSectionIndex.TenantAttributes"
              [canSave]="
                isValidSection(TenantConfigurationSectionIndex.TenantAttributes)
              "
              [showEditButton]="!sectionTenantAttributes.editting"
              [saveSectionCallback]="saveCustomAttributeSection.bind(this)"
              (cancelSection)="cancelSection($event)"
              (editIndexChange)="markEditingSectionIndex($event)"
            >
              <ng-container
                *ngFor="
                  let propertyName of formSections[
                    TenantConfigurationSectionIndex.TenantAttributes
                  ].fieldsName;
                  index as fieldIndex
                "
              >
                <div
                  *ngIf="propertyName !== 'PostStream'"
                  class="tenant-form-field"
                  [class.mt-0]="fieldIndex === 0"
                >
                  <ng-container
                    [ngSwitch]="getAttributeTypeByPropertyName(propertyName)"
                  >
                    <ng-container *ngSwitchCase="AttributeType.Image">
                      <div
                        class="form-group position-relative mt-2 d-flex flex-column"
                      >
                        <div class="venture__label widget__label">
                          <label [for]="propertyName">
                            {{ getFieldOptions(propertyName)?.displayName }}
                          </label>
                        </div>

                        <ng-container
                          *ngIf="
                            sectionTenantAttributes.editting;
                            else imgViewRef
                          "
                        >
                          <app-file-upload
                            [multiLocale]="
                              getFieldOptions(propertyName)?.systemType ===
                              SystemType.MultilingualImage
                            "
                            [disabled]="false"
                            (srcUrlChange)="
                              getFormControlByPropertyName(
                                propertyName
                              )?.setValue($event)
                            "
                            [inputId]="propertyName"
                            [allowCropImg]="
                              propertyName | call : isDefaultBanner
                            "
                            [aspectRatio]="20 / 7"
                          >
                            <div
                              *ngIf="
                                !getFormControlByPropertyName(propertyName)
                                  ?.value?.url
                              "
                              class="tenant-logo card upload-file__container justify-content-center align-items-center"
                              [ngClass]="{
                                'alert alert-danger':
                                  getFormControlByPropertyName(propertyName)
                                    ?.invalid,
                                'tenant-default-logo':
                                  propertyName | call : isDefaultLogo,
                                'tenant-default-banner':
                                  propertyName | call : isDefaultBanner
                              }"
                            >
                              <i
                                class="fas fa-camera fa-lg"
                                [ngClass]="{
                                  'upload-file__icon':
                                    getFormControlByPropertyName(propertyName)
                                      ?.valid
                                }"
                              ></i>
                            </div>
                            <div
                              *ngIf="
                                getFormControlByPropertyName(propertyName)
                                  ?.value?.url
                              "
                              class="tenant-logo card upload-file__container border-0"
                              [ngClass]="{
                                'tenant-default-logo':
                                  propertyName | call : isDefaultLogo,
                                'tenant-default-banner':
                                  propertyName | call : isDefaultBanner
                              }"
                            >
                              <img
                                [src]="
                                  getFormControlByPropertyName(propertyName)
                                    ?.value?.url
                                "
                                appDefaultPlaceholder
                                appDynamicImgUrl
                                [default]="placeholderImg"
                              />
                            </div>
                          </app-file-upload>
                        </ng-container>
                        <ng-template #imgViewRef>
                          <div
                            class="tenant-logo d-flex align-items-center m-0"
                            [ngClass]="{
                              'tenant-default-logo':
                                propertyName | call : isDefaultLogo,
                              'tenant-default-banner':
                                propertyName | call : isDefaultBanner
                            }"
                          >
                            <a appUnsavedFormCheck [href]="windowOrigin">
                              <img
                                [src]="
                                  getFormControlByPropertyName(propertyName)
                                    ?.value?.url ||
                                  getFormFieldValueFromEntity(propertyName)?.url
                                "
                                appDefaultPlaceholder
                                appDynamicImgUrl
                              />
                            </a>
                          </div>
                        </ng-template>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      <div class="d-flex justify-content-end">
                        <app-language-switcher
                          *ngIf="
                            getFieldOptions(propertyName).propertyName ===
                              'TermsOfServiceUrl' &&
                            sectionTenantAttributes.editting
                          "
                          [className]="'mr-3'"
                          (languageRemoved)="
                            onRemoveLanguage(
                              TenantConfigurationSectionIndex.TenantAttributes
                            )
                          "
                          (languageSelected)="
                            onLanguageChange(
                              $event,
                              TenantConfigurationSectionIndex.TenantAttributes
                            )
                          "
                        ></app-language-switcher>
                      </div>
                      <app-form-field-custom
                        [formInput]="form"
                        [showLabel]="!excludeLabelFields.includes(propertyName)"
                        [editable]="
                          canEdit(
                            propertyName,
                            sectionTenantAttributes.editting
                          )
                        "
                        [type]="
                          getAttributeTypeByPropertyName(propertyName) ===
                          AttributeType.String
                            ? AttributeType.StringSingle
                            : null
                        "
                        [attributeDescription]="
                          propertyName === 'TermsOfServiceUrl'
                            ? getFieldOptions(propertyName, entity)
                            : getFieldOptions(propertyName)
                        "
                        [formInput]="
                          getFieldOptions(propertyName).propertyName ===
                          'TermsOfServiceUrl'
                            ? getFormSectionIndex(
                                TenantConfigurationSectionIndex.TenantAttributes
                              )
                            : form
                        "
                      >
                      </app-form-field-custom>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container>
                <div
                  [ngClass]="{
                    'd-none': !form.get('PublishingStreams')?.value
                  }"
                >
                  <app-stream-settings
                    #streamSettings
                    [attributeDescription]="getFieldOptions('PostStream')"
                    [entity]="entity"
                    [editable]="sectionTenantAttributes.editting"
                    [showLabel]="false"
                  ></app-stream-settings>
                </div>
              </ng-container>
            </app-form-section>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <ngb-accordion
      [activeIds]="['tenant-email-configurations']"
      [destroyOnHide]="false"
    >
      <ngb-panel
        cardClass="venture__body venture__accordion ignore-overflow"
        id="tenant-email-configurations"
      >
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            class="btn d-flex align-items-center justify-content-between w-100 text-left"
            ngbPanelToggle
            type="button"
          >
            <div translate class="pull-left float-left">
              <span
                class="venture__company-header mr-5 d-block d-lg-inline-block"
                >Tenant Mail Configurations</span
              >
            </div>
            <i
              class="fas float-right pull-right venture__company-header"
              [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
            ></i>
          </button>
        </ng-template>

        <ng-template ngbPanelContent>
          <div
            class="venture__body widget__card--background m-0"
            *ngIf="form && formSections.length > 0"
          >
            <app-form-section
              #sectionTenantCommunicationSettings
              [index]="TenantConfigurationSectionIndex.TenantEmailConfiguration"
              [canSave]="
                isValidSection(
                  TenantConfigurationSectionIndex.TenantEmailConfiguration
                )
              "
              [showEditButton]="!sectionTenantCommunicationSettings.editting"
              [saveSectionCallback]="saveCustomAttributeSection.bind(this)"
              (cancelSection)="cancelSection($event)"
              (editIndexChange)="markEditingSectionIndex($event)"
              [preSaveSectionCallback]="preSaveSection.bind(this)"
            >
              <ng-container
                *ngFor="
                  let propertyName of formSections[
                    TenantConfigurationSectionIndex.TenantEmailConfiguration
                  ].fieldsName;
                  index as fieldIndex
                "
              >
                <div class="tenant-form-field" [class.mt-0]="fieldIndex === 0">
                  <ng-container
                    [ngSwitch]="getAttributeTypeByPropertyName(propertyName)"
                  >
                    <ng-container *ngSwitchDefault>
                      <app-form-field-custom
                        [formInput]="form"
                        [showLabel]="!excludeLabelFields.includes(propertyName)"
                        [editable]="
                          canEdit(
                            propertyName,
                            sectionTenantCommunicationSettings.editting
                          )
                        "
                        [type]="
                          propertyName | call : getAttributeTypeByPropName
                        "
                        [attributeDescription]="getFieldOptions(propertyName)"
                        [options]="
                          propertyName | call : getMailConfigFieldOptions
                        "
                        (storedValue)="onStoreValueChange($event)"
                      >
                      </app-form-field-custom>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </app-form-section>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <ngb-accordion
      [activeIds]="['tenant-communication-settings']"
      [destroyOnHide]="false"
    >
      <ngb-panel
        cardClass="venture__body venture__accordion ignore-overflow"
        id="tenant-communication-settings"
      >
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            class="btn d-flex align-items-center justify-content-between w-100 text-left"
            ngbPanelToggle
            type="button"
          >
            <div translate class="pull-left float-left">
              <span
                class="venture__company-header mr-5 d-block d-lg-inline-block"
                >Tenant Communication Settings</span
              >
            </div>
            <i
              class="fas float-right pull-right venture__company-header"
              [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
            ></i>
          </button>
        </ng-template>

        <ng-template ngbPanelContent>
          <div
            class="venture__body widget__card--background m-0"
            *ngIf="form && formSections.length > 0"
          >
            <app-form-section
              #sectionTenantCommunicationSettings
              [index]="
                TenantConfigurationSectionIndex.TenantCommunicationSettings
              "
              [canSave]="
                isValidSection(
                  TenantConfigurationSectionIndex.TenantCommunicationSettings
                )
              "
              [showEditButton]="!sectionTenantCommunicationSettings.editting"
              [saveSectionCallback]="saveCustomAttributeSection.bind(this)"
              (cancelSection)="cancelSection($event)"
              (editIndexChange)="markEditingSectionIndex($event)"
              [preSaveSectionCallback]="preSaveSection.bind(this)"
            >
              <ng-container
                *ngFor="
                  let propertyName of formSections[
                    TenantConfigurationSectionIndex.TenantCommunicationSettings
                  ].fieldsName;
                  index as fieldIndex
                "
              >
                <div class="tenant-form-field" [class.mt-0]="fieldIndex === 0">
                  <app-form-field-custom
                    [formInput]="form"
                    [showLabel]="!excludeLabelFields.includes(propertyName)"
                    [editable]="
                      canEdit(
                        propertyName,
                        sectionTenantCommunicationSettings.editting
                      )
                    "
                    [type]="
                      getAttributeTypeByPropertyName(propertyName) ===
                      AttributeType.String
                        ? AttributeType.StringSingle
                        : null
                    "
                    [attributeDescription]="getFieldOptions(propertyName)"
                  >
                  </app-form-field-custom>
                </div>
              </ng-container>
            </app-form-section>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <ngb-accordion
      *ngIf="getFieldOptions('FeaturedInnovationSpace')"
      [activeIds]="['popular-community-spaces']"
      [destroyOnHide]="false"
    >
      <ngb-panel
        cardClass="venture__body venture__accordion ignore-overflow"
        id="popular-community-spaces"
      >
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            class="btn d-flex align-items-center justify-content-between w-100 text-left"
            ngbPanelToggle
            type="button"
          >
            <div translate class="pull-left float-left">
              <span
                class="venture__company-header mr-5 d-block d-lg-inline-block"
                >Popular Community Spaces</span
              >
            </div>
            <i
              class="fas float-right pull-right venture__company-header"
              [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
            ></i>
          </button>
        </ng-template>

        <ng-template ngbPanelContent>
          <div class="venture__body widget__card--background m-0">
            <div class="mb-3">
              This section helps you to selection community spaces which will be
              shown in the popular section of Innovation Space Side menu
            </div>
            <div class="mb-3">
              <button
                type="button"
                class="btn btn-primary font-weight-bold"
                (click)="openAddFavoriteDialog()"
              >
                <em class="fas fa-plus pr-2"></em
                ><span
                  translate=""
                  class="text-uppercase"
                  ng-reflect-translate=""
                >
                  Add Community Spaces
                </span>
              </button>
            </div>
            <div>
              <app-featured-innovation-spaces
                #partnerOrgs
                [organisations]="orgAsCommunitySpaces"
                [editing]="true"
                [organizationType]="organizationType.Organization"
                [showVenturesCtrl]="false"
                [isOwner]="false"
                [advancedSetting]="false"
                [isCreating]="false"
                [isShownAsChallenge]="false"
                (itemRemoved)="updateCommunitySpaces($event, 'delete')"
              ></app-featured-innovation-spaces>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</ng-container>

<ng-template #modalRef let-modal>
  <div class="modal-header">
    <h4 class="modal-title" translate>Add to popular</h4>

    <button
      (click)="modal.close()"
      aria-describedby="modal-title"
      aria-label="Close"
      class="btn-close close font-size-2xl"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="height: 500px">
    <app-organisation-search
      #organizationSearch
      [searchMode]="OrgsSearchMode.ForSubmittingAll"
      [searchPlaceholder]="'Search and add' | translate"
      [enableDynamicHeight]="false"
      [height]="420"
      [autoOpenDropdown]="true"
      [closeDropdownOnSelect]="false"
      [excludedOrganisationIds]="ignoreOrgIds"
      [updateListOnSelect]="true"
      (selectOrganisation)="updateCommunitySpaces($event, 'create')"
    ></app-organisation-search>
  </div>
</ng-template>
