<app-unsaved-form-check
  [unsavedFormCheckService]="unsavedFormCheckService"
></app-unsaved-form-check>
<div class="modal-header">
  <div class="d-flex align-items-center">
    <div class="sender-profile d-flex justify-content-center mr-3">
      <div class="message-to-profile rounded-circle">
        <img
          alt="profile image"
          [src]="profileImage"
          appDefaultAvatar
          appDynamicImgUrl
        />
      </div>
    </div>
    <div>
      <h3 class="modal-title" translate>
        {{ postData | call : getModalTitle }}
      </h3>

      <span class="text-gray-500"
        ><i class="fas fa-globe-europe mr-2"></i>This post will be seen by
        everybody</span
      >
    </div>
  </div>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body new-message-dialog-modal-body">
  <ng-container
    *ngIf="isLoadingAttributeDescriptions$ | async; else sendMessageTemplate"
  >
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-container>
  <ng-template #sendMessageTemplate>
    <form [formGroup]="form">
      <div *ngIf="currentOrg" class="d-flex align-items-center mb-3">
        <button
          class="filter__btn mr-2 filter__btn--active flex-shrink-0"
          id="filterCollapse"
        >
          <app-internal-icon
            [hasFilter]="false"
            [height]="20"
            [iconType]="IconType.PNG"
            [name]="InternalIcon.InnovationSpace"
          ></app-internal-icon>
        </button>
        <span class="flex-shrink-0" translate>UI.News.ThisPostBelongsTo</span>

        <span class="font-weight-bold text-truncate">
          &nbsp;{{ currentOrg?.name || 'All Innovation Spaces' | translate }}
        </span>
      </div>
      <div class="d-flex flex-row">
        <div class="message-content-quill ml-0">
          <app-form-field-custom
            #messageForm
            [formInput]="form"
            [type]="AttributeType.RTE"
            [attributeDescription]="contentAttributeDescription"
            [shouldValidateOnTouch]="true"
            [options]="{
              placeholder: placeholder | translate,
              allowEmojiOnRTE: true,
              allowImgOnRTE: true,
              allowCropImg: true,
              aspectRatio: 16 / 9
            }"
            (ngxVisibility)="formFieldLoaded(messageForm)"
          >
          </app-form-field-custom>
        </div>

        <div class="mt-2"></div>
      </div>

      <!-- Stream select -->
      <ng-container
        *ngIf="!(isLoadingStreamOptions$ | async) && streams.length"
      >
        <div class="mt-2">
          <label
            class="pointer-cursor font-weight-bold mb-1 filter-label"
            translate
          >
            UI.PublishingStreams.Label
          </label>
          <p class="m-0" translate>UI.PublishingStreams.ExplanationText</p>
        </div>
        <div class="mt-1 stream-select-wrapper">
          <ng-select
            *ngIf="!(isLoadingStreamOptions$ | async)"
            [placeholder]="'UI.SmallFilter.Select' | translate"
            [items]="streams"
            [multiple]="true"
            bindLabel="name"
            bindValue="id"
            [selectableGroup]="true"
            [closeOnSelect]="false"
            formControlName="streams"
          >
            <ng-template
              ng-option-tmp
              let-item="item"
              let-item$="item$"
              let-index="index"
            >
              <input
                class="option-checkbox"
                id="item-{{ index }}"
                type="checkbox"
                [checked]="item$.selected"
              />
              {{ index === 0 ? '' : index + '. ' }}{{ item.name }}
            </ng-template>

            <ng-template ng-label-tmp let-item="item">
              {{ item.name }}{{ shouldShowComma(item) ? ', ' : '' }}
            </ng-template>
          </ng-select>
        </div>
      </ng-container>
    </form>
  </ng-template>
</div>
<div class="modal-footer message-command">
  <button type="button" class="btn btn-outline-secondary" (click)="close()">
    {{ 'Cancel' | translate | uppercase }}
  </button>
  <button
    appRequireLogin
    [disabled]="
      form.invalid || isSubmitting || messageForm?.imageInputDisplay?.uploading
    "
    type="button"
    ngbAutofocus
    class="btn btn-primary"
    (click)="onSubmit(postData)"
  >
    <ng-container *ngIf="isSubmitting">
      <i class="fas fa-spinner fa-spin btn-icon-right"></i>
    </ng-container>
    <span>{{
      postData | call : getSubmitButtonText | translate | uppercase
    }}</span>
  </button>
</div>
