export enum VenturePropertyName {
  Initiator = 'Initiator',
  Stakeholders = 'Stakeholders',
  PendingStakeholders = 'PendingStakeholders',
  CurrentSubmission = 'CurrentSubmission',
  IsPrivate = 'IsPrivate',
  SubmittedOrganizations = 'SubmittedOrganizations',
  TimestampCreator = 'TimestampCreator',
  Phase = 'Phase'
}

export enum OrganizationPropertyName {
  PartnerStatus = 'PartnerStatus',
  HasMemberCard = 'HasMemberCard',
  WhiteLableSiteName = 'WhiteLableSiteName',
}
