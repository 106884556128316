import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { ChallengeHttpService } from '@core/http/challenge-http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ChallengeInterface } from '@shared/interfaces/challenge.interface';
import { EntityNotFoundDialogComponent } from '@src/app/components/dialogs/entity-not-found-dialog/entity-not-found-dialog.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { BaseListComponent } from '../base/base-list/base-list.component';
import { EntityGlobalFilterCriteria } from '@src/app/shared/interfaces/filters/global-filter.interface';
import { filter, Observable } from 'rxjs';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { flattenParams } from '@src/app/shared/utils/common.utils';

@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  providers: [{ provide: BaseHttpService, useClass: ChallengeHttpService }],
})
export class ChallengesComponent
  extends BaseListComponent<ChallengeInterface>
  implements OnInit
{
  entityName = EntityName.Challenge;
  selectedChallenge: ChallengeInterface;

  page = 1;
  pageSize = 8;

  showPeopleModal = false;
  showEventsModal = false;
  showVenturesModal = false;
  showLikesModal = false;
  showKeyOfferModal = false;

  skeletonCount = [];
  modalService: NgbModal;

  constructor(
    public listService: ChallengeHttpService,
    protected translateService: TranslateService,
    protected sessionService: SessionService,
    protected authenService: AuthenService,
    protected toastService: ToastService,
    protected injector: Injector
  ) {
    super(
      listService,
      translateService,
      sessionService,
      authenService,
      injector
    );

    this.modalService = this.injector.get(NgbModal);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.skeletonCount = [...Array(Number(this.columns || '3'))];
    this.onNotFoundChallenge();
  }

  private onNotFoundChallenge() {
    const notFoundParam = StringUtils.getParamFromUrl(UrlParam.NotFound);
    if (notFoundParam) {
      this.openNotFoundEntityDialog();
    }
  }

  getEntityBase(): string {
    return environment.jipUrl.challenges;
  }

  submitVentureToOrg(entity: ChallengeInterface): void {
    if (
      !this.sessionService.isLogin ||
      !entity.showAsChallenge ||
      !entity?.id
    ) {
      return;
    }
    if (entity.isPrivate) {
      this.listService
        .checkSubmitVenture(entity.id)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            FormUtils.navigateTo(
              this.generateCreateEntityUrl(EntityName.Venture, entity.id)
            );
          },
          error: (errorRes: HttpErrorResponse) => {
            if (errorRes.error?.title) {
              this.toastService.showError(errorRes.error.title);
            }
          },
        });
    } else {
      FormUtils.navigateTo(
        this.generateCreateEntityUrl(EntityName.Venture, entity.id)
      );
    }
  }

  generateCreateEntityUrl(entityName: string, entityId: number): string {
    if (!entityName) {
      return '';
    }
    const queryParams = `${UrlParam.OrganizationId}=${entityId}`;
    const entityUrl = StringUtils.getEntityUrlByEntityName(entityName);
    if (entityUrl) {
      return this.sessionService.appendLanguagePath(
        `${entityUrl}/create?${queryParams}`
      );
    }
    return '';
  }

  canSubmitVentureToCall(call: ChallengeInterface): boolean {
    return call && this.isSubmissionPhase(call);
    // 14851 Temporary remove check related to challenge
    // && (!this.sessionService.isLogin || call.canSubmitVentureToChallenge);
  }

  getReasonWhyCannotSubmitVentureToCall(call: ChallengeInterface): string {
    if (!this.isSubmissionPhase(call)) {
      return 'UI.Challenge.DuringSubmissionPhase';
    }
    // 14851 Temporary remove check related to challenge
    // if (this.sessionService.isLogin && !call.canSubmitVentureToChallenge) {
    //   return 'UI.Challenge.RelatedToCall';
    // }
  }

  protected handleSyncEntityStateCount(): void {
    super.handleSyncEntityStateCount();
    this.syncEntityJoinStates();
    this.modifyDescriptionView();
  }

  private isSubmissionPhase(call: ChallengeInterface): boolean {
    return call.challengeTimeline[0]?.isCurrent;
  }

  private modifyDescriptionView(): void {
    this.items.forEach((challenge: ChallengeInterface) => {
      if (challenge.description) {
        challenge.description = this.enhanceDescriptionHtmlString(
          challenge.description
        );
      }
    });
  }

  openNotFoundEntityDialog() {
    const modal = this.modalService.open(EntityNotFoundDialogComponent, {
      centered: true,
      backdrop: 'static',
    });

    modal.componentInstance.heading = 'UI.NotFoundDialog.Heading.Challenges';
    modal.componentInstance.message = 'UI.NotFoundDialog.Message.Challenges';
  }
}
