import { Component, Input } from '@angular/core';
import { PopupBlockerDetectionService } from '@src/app/core/services/popup-blocker-detection.service';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { StringUtils } from '@src/app/shared/utils/string-utils';

@Component({
  selector: 'app-popup-blocker-detection-notification',
  templateUrl: './popup-blocker-detection-notification.component.html',
})
export class PopupBlockerDetectionNotificationComponent {
  @Input() guideUrl = '';
  isPopupBlocked$ = this.popupBlockerDetectionService.isPopupBlocked$;

  constructor(
    private popupBlockerDetectionService: PopupBlockerDetectionService
  ) {}

  removeNotification() {
    this.popupBlockerDetectionService.isPopupBlocked$.next(false);
    StringUtils.removeParamFromUrl(UrlParam.PopUpBlocked);
  }
}
