<ng-container *ngIf="!isCreating && timestampId; else ventureDetail">
  <app-venture-timestamp
    [uiTemplateConfig]="uiTemplateConfig"
    [globalOrgId]="globalOrgId"
    [timestampId]="timestampId"
    [entityId]="id"
  >
  </app-venture-timestamp>
</ng-container>

<ng-template #ventureDetail>
  <app-unsaved-form-check
    [unsavedFormCheckService]="unsavedFormCheckService"
  ></app-unsaved-form-check>

  <ng-container *ngIf="!isLoadingEntity && !isGoingToOtherPage">
    <form *ngIf="form" [formGroup]="form" class="venture-edit">
      <div class="venture__wrapper venture-details pb-3">
        <div
          class="w-100 py-1 px-3 d-flex justify-content-between align-items-center background-white"
        >
          <div
            *ngIf="isCreating && hasDefaultBanner"
            class="upload-control-wrapper d-flex flex-column-reverse"
          >
            <div class="d-flex">
              <div
                appRequireSession
                class="d-flex align-items-center pointer-cursor"
                (credentialClick)="imageControl?.imageUpload?.triggerUpload()"
              >
                <span class="upload-control-wrapper__change-logo d-block">
                  <i class="fas fa-pencil-alt"></i>
                </span>
                <span
                  class="ml-1 upload-control-wrapper__control-text"
                  translate
                  >UI.DefaultImageControl.ChangeBanner</span
                >
              </div>
              <ng-container *ngIf="form.value?.Image?.url !== hasDefaultBanner">
                <div
                  appRequireSession
                  class="d-flex align-items-center upload-control-wrapper__back-to-default pointer-cursor"
                  (credentialClick)="resetImage()"
                >
                  <span class="upload-control-wrapper__change-logo d-block">
                    <i class="fas fa-undo-alt"></i>
                  </span>
                  <span
                    class="ml-1 upload-control-wrapper__control-text"
                    translate
                    >UI.DefaultImageControl.BackToDefaultBanner</span
                  >
                </div>
              </ng-container>
            </div>
          </div>
          <app-language-indicator *ngIf="isCreating"></app-language-indicator>
        </div>

        <div class="venture__header">
          <div class="venture__publishing" *ngIf="!isCreating">
            <app-privacy-flag
              [isPrivate]="entity?.isPrivate"
            ></app-privacy-flag>

            <div class="align-self-end d-flex">
              <app-join-venture
                *ngIf="(entity?.attributeValues | attributeValue : 'UiTemplate')?.describedValue?.value !== TemplateName.Reduced4"
                class="mr-2"
                [baseHttpService]="baseHttpService"
                [entity]="entity"
              >
              </app-join-venture>

              <div
                *ngIf="isOwner && !isTeam"
                class="venture__publishing-ops d-flex"
              >
                <ng-container *ngIf="entity?.isPrivate; else willUnpublish">
                  <div class="d-flex align-items-center">
                    <button
                      type="button"
                      appRequireLogin
                      appDebounceClick
                      (click)="publish()"
                      class="btn btn-interact btn-interact--big btn-primary"
                    >
                      <i class="fas fa-eye"></i>
                      <span class="text-hide-small" translate>Publish</span>
                    </button>

                    <i
                      class="fas fa-info-circle pointer-cursor widget__color ml-2"
                      appTooltip
                      placement="left auto"
                      triggers="click"
                      [ngbTooltip]="'UI.Venture.PublishVenture' | translate"
                    ></i>
                  </div>
                </ng-container>
                <ng-template #willUnpublish>
                  <div class="d-flex align-items-center">
                    <button
                      type="button"
                      appRequireLogin
                      appDebounceClick
                      (click)="unpublish()"
                      class="btn btn-interact btn-interact--big btn-primary"
                    >
                      <i class="fas fa-eye-slash"></i>
                      <span class="text-hide-small" translate>Unpublish</span>
                    </button>
                    <i
                      class="fas fa-info-circle pointer-cursor widget__color ml-2"
                      appTooltip
                      placement="left auto"
                      triggers="click"
                      [ngbTooltip]="'UI.Venture.UnpublishVenture' | translate"
                    ></i>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>

          <div
            *ngIf="editable && !isCreating"
            [ngClass]="{
              'alert-danger': imageControl?.isInvalid,
              'bg-white': !imageControl?.isInvalid
            }"
            class="upload-file__container justify-content-between align-items-center position-relative w-100 shadow-none"
          >
            <div class="org-details__image-upload--primary btn-primrary">
              <app-form-field-custom
                #imageControl
                styleClass="fa-image text-white fill-container small-image-upload-btn"
                [attributeDescription]="getFieldOptions('Image')"
                [formInput]="getFormSectionIndex('Image')"
                [language]="getLocaleBySectionKey('Image')"
                [options]="{
                  id: form.controls['Image']?.value?.id,
                  multiLocale: isCreating
                }"
                [allowCropImg]="true"
                [aspectRatio]="bannerImageCropRatio"
              >
              </app-form-field-custom>
            </div>

            <app-language-switcher
              (languageRemoved)="onRemoveLanguage('Image')"
              (languageSelected)="onLanguageChange($event, 'Image')"
              *ngIf="!isCreating"
              [className]="'mr-3'"
            ></app-language-switcher>
          </div>

          <app-image-wrapper
            [imgAlt]="getFormSectionIndex('Image')?.value?.Title"
            [imgObject]="getFormSectionIndex('Image')?.value?.Image"
          >
          </app-image-wrapper>

          <div
            class="venture__title-wrapper"
            [class.position-relative]="hasDefaultBanner"
          >
            <app-form-section
              #section0
              [index]="VentureSectionIndex.Title"
              [canSave]="isValidSection(VentureSectionIndex.Title)"
              [showEditButton]="!isCreating && editable"
              [saveSectionCallback]="saveSection.bind(this)"
              (cancelSection)="cancelSection($event)"
              (editIndexChange)="markEditingSectionIndex($event)"
            >
              <app-form-field-custom
                [showLanguageIndicator]="isCreating"
                [formInput]="form"
                [editable]="isCreating || (editable && section0.editting)"
                [attributeDescription]="getFieldOptions('Title')"
                [options]="{ placeholder: 'Venture Name' | translate }"
              >
              </app-form-field-custom>
            </app-form-section>
          </div>
          <div
            *ngIf="isCreating"
            [ngClass]="{
              'alert-danger': imageControl?.isInvalid,
              'bg-white': !imageControl?.isInvalid,
              'venture__upload-btn--creating': isCreating,
              'extend-container': !form?.value?.Image?.url,
              'd-none': hasDefaultBanner
            }"
            class="upload-file__container venture__upload-btn"
          >
            <app-form-field-custom
              #imageControl
              [attributeDescription]="getFieldOptions('Image')"
              [formInput]="getFormSectionIndex('Image')"
              [language]="getLocaleBySectionKey('Image')"
              [options]="{
                id: form.controls['Image']?.value?.id,
                multiLocale: isCreating
              }"
              styleClass="upload-file__big-upload-btn"
              [allowCropImg]="true"
              [aspectRatio]="bannerImageCropRatio"
              [customUploadButtonTemplate]="customUploadButtonTemplate"
            >
            </app-form-field-custom>
            <ng-template #customUploadButtonTemplate>
              <ng-container
                *ngIf="hasDefaultBanner; else defaultImageUploadButton"
              >
              </ng-container>

              <ng-template #defaultImageUploadButton>
                <div
                  class="card w-fit mr-auto mb-0 upload-file__big-upload-btn extend-container d-flex align-items-center flex-row"
                  [ngClass]="{
                    'alert alert-danger': imageControl?.isInvalid,
                    'extend-container': !form?.value?.Image?.url
                  }"
                >
                  <i
                    class="fas fa-camera fa-lg"
                    [ngClass]="{
                      'upload-file__icon': !imageControl?.isInvalid
                    }"
                  ></i>
                  <span *ngIf="!form?.value?.Image?.url">{{
                    'UI.UploadImageButton.FieldIsRequired' | translate
                  }}</span>
                </div>
              </ng-template>
            </ng-template>
          </div>
        </div>

        <ng-container *ngIf="!isCreating">
          <app-interaction-bar
            entityPath="{{ entityBase }}/{{ entity?.id }}"
            [httpService]="baseHttpService"
            [entity]="entity"
            [entityName]="entityName"
            [isLoadingLikingsCount]="isLoadingLikingsCount"
            [isLoadingFollowingsCount]="isLoadingFollowingsCount"
            [isLoadingCommentsCount]="isLoadingCommentsCount"
            (triggerToggleLike)="toggleLike(entity)"
            (triggerShowLikingsData)="
              showEntityData(); showLikesModal = !!entity?.likesCount
            "
            (triggerShowFollowingsData)="
              showEntityData(); showFollowersModal = true
            "
            (afterCommentModalClosed)="onCommentModalClosed()"
            (triggerToggleFavorite)="toggleFavorite()"
          >
            <div class="d-flex">
              <ng-container *ngIf="canDeleteVenture">
                <button
                  type="button"
                  appRequireLogin
                  class="btn btn-interact btn-interact--small btn-outline-primary"
                  (click)="deleteVenture()"
                  [class.mr-2]="profile?.isTenantAdmin"
                >
                  <i class="fas fa-trash"></i>
                  <span class="text-hide-small ml-2">{{
                    'UI.Venture.Delete' | translate
                  }}</span>
                </button>
              </ng-container>

              <button
                *ngIf="!isOwner && !isTeam"
                class="btn btn-interact btn-interact--small btn-secondary-dark text-white"
                type="button"
                appRequireLogin
                appDebounceClick
                (debounceClick)="getInTouch()"
              >
                <i class="fas fa-paper-plane"></i>
                <span class="text-hide-small">{{
                  'UI.Ventures.GetInTouch' | translate
                }}</span>
              </button>
            </div>
          </app-interaction-bar>
        </ng-container>
        <ng-container
          *ngIf="uiTemplateName | visibility : 'InnovationProgress'"
        >
          <div class="venture__body widget__card--background">
            <app-form-section
              #section1
              [index]="VentureSectionIndex.Phase"
              [canSave]="isValidSection(VentureSectionIndex.Phase)"
              [showEditButton]="!isCreating && editable"
              [saveSectionCallback]="saveCustomAttributeSection.bind(this)"
              (cancelSection)="cancelSection($event)"
              (editIndexChange)="markEditingSectionIndex($event)"
            >
              <div class="row">
                <div class="col col-12">
                  <div
                    class="venture__label venture__label--head widget__label"
                    translate
                  >
                    Current Innovation phase of this venture
                  </div>
                  <div class="venture__phase-description">
                    <span
                      *ngIf="
                        this.uiTemplateName !== TemplateName.Reduced4;
                        else cbChartaPhaseDescription
                      "
                    >
                      {{ 'UI.VenturePhase.Description' | translate }}
                    </span>

                    <ng-template #cbChartaPhaseDescription>
                      <span>
                        {{ 'UI.VenturePhase.Description-CbCharta' | translate }}
                      </span>
                    </ng-template>
                  </div>
                  <app-form-field-custom
                    *ngIf="this.uiTemplateName !== TemplateName.Reduced4"
                    [formInput]="form"
                    [editable]="isCreating || (editable && section1?.editting)"
                    [type]="allType.VenturePhase"
                    [attributeDescription]="getFieldOptions('Phase')"
                  ></app-form-field-custom>

                  <app-form-field-custom
                    *ngIf="this.uiTemplateName === TemplateName.Reduced4"
                    [formInput]="form"
                    [editable]="isCreating || (editable && section1?.editting)"
                    [type]="allType.VenturePhase"
                    [attributeDescription]="getFieldOptions('CbChartaPhase')"
                  ></app-form-field-custom>
                </div>
              </div>
            </app-form-section>
          </div>
        </ng-container>

        <div class="venture__body widget__card--background">
          <app-form-section
            #section2
            [index]="VentureSectionIndex.Description"
            [canSave]="isValidSection(VentureSectionIndex.Description)"
            [showEditButton]="!isCreating && editable"
            [saveSectionCallback]="saveCustomAttributeSection.bind(this)"
            (cancelSection)="cancelSection($event)"
            (editIndexChange)="markEditingSectionIndex($event)"
          >
            <div *ngIf="uiTemplateName !== TemplateName.Reduced4" class="row">
              <div class="col col-12">
                <ul
                  ngbNav
                  #nav="ngbNav"
                  [destroyOnHide]="!isCreating"
                  class="nav-tabs w-100"
                >
                  <li ngbNavItem>
                    <a ngbNavLink
                      ><span
                        class="venture__tabs__title"
                        [ngClass]="{
                          'venture__tabs__title--invalid':
                            shouldShowContentExcerptError()
                        }"
                        translate
                        >Short Description <span class="after"></span></span
                    ></a>

                    <ng-template ngbNavContent>
                      <div class="mb-3">
                        <label class="venture__label widget__label" translate
                          >Excerpt</label
                        >

                        <app-form-field-custom
                          #excerpt
                          [formInput]="
                            getFormSectionIndex(VentureSectionIndex.Description)
                          "
                          [editable]="
                            isCreating || (editable && section2?.editting)
                          "
                          [attributeDescription]="
                            getFieldOptions('Excerpt', entity)
                          "
                          [options]="{
                            placeholder:
                              'Type short description of Venture' | translate,
                            isViewAsText: true,
                            showHiddenMarkBackground: isOwner
                          }"
                        ></app-form-field-custom>
                      </div>

                      <div
                        *ngIf="
                          shouldShowContentExcerptError() &&
                          (isCreating || (editable && section2?.editting))
                        "
                        class="alert alert-danger"
                        translate
                      >
                        Please enter either excerpt or description
                      </div>
                    </ng-template>
                  </li>

                  <li
                    *ngIf="uiTemplateName | visibility : 'Content'"
                    ngbNavItem
                  >
                    <a ngbNavLink
                      ><span
                        class="venture__tabs__title"
                        [ngClass]="{
                          'venture__tabs__title--invalid':
                            shouldShowContentExcerptError()
                        }"
                        translate
                        >Long Description <span class="after"></span></span
                    ></a>

                    <ng-template ngbNavContent>
                      <label class="venture__label widget__label" translate
                        >Description</label
                      >

                      <app-form-field-custom
                        #content
                        [formInput]="
                          getFormSectionIndex(VentureSectionIndex.Description)
                        "
                        [editable]="
                          isCreating || (editable && section2?.editting)
                        "
                        [attributeDescription]="
                          getFieldOptions('Content', entity)
                        "
                        [options]="{
                          placeholder:
                            'Type long description of Venture' | translate,
                          isViewAsText: true,
                          isWideView: true,
                          showHiddenMarkBackground: isOwner
                        }"
                      ></app-form-field-custom>

                      <div
                        *ngIf="
                          shouldShowContentExcerptError() &&
                          (isCreating || (editable && section2?.editting))
                        "
                        class="alert alert-danger"
                        translate
                      >
                        Please enter either excerpt or description
                      </div>
                    </ng-template>
                  </li>

                  <li
                    *ngIf="
                      customMetadata
                        | call : checkBestPracticeAttribute : uiTemplateName
                    "
                    ngbNavItem
                  >
                    <a ngbNavLink
                      ><span
                        class="venture__tabs__title"
                        [ngClass]="{
                          'venture__tabs__title--invalid':
                            shouldShowContentExcerptError()
                        }"
                        translate
                        >Best Practices<span class="after"></span></span
                    ></a>

                    <ng-template ngbNavContent>
                      <label class="venture__label widget__label" translate
                        >Best Practices</label
                      >
                      <app-form-field-custom
                        #content
                        controlName="BestPractices"
                        [formInput]="
                          getFormSectionIndex(VentureSectionIndex.Description)
                        "
                        [editable]="
                          isCreating || (editable && section2?.editting)
                        "
                        [attributeDescription]="
                          getFieldOptions('BestPractices', entity)
                        "
                        [options]="{
                          placeholder:
                            'Type best practices of Venture' | translate,
                          isViewAsText: true,
                          isWideView: true,
                          showHiddenMarkBackground: isOwner
                        }"
                      ></app-form-field-custom>
                    </ng-template>
                  </li>

                  <li class="d-flex align-items-center ml-auto mt-1">
                    <app-language-indicator
                      [className]="'m-2'"
                      *ngIf="isCreating"
                    ></app-language-indicator>

                    <app-language-switcher
                      [className]="'mt-n1 pb-2'"
                      (languageSelected)="
                        onLanguageChange(
                          $event,
                          VentureSectionIndex.Description
                        )
                      "
                      (languageRemoved)="
                        onRemoveLanguage(
                          VentureSectionIndex.Description,
                          $event
                        )
                      "
                      *ngIf="!isCreating && editable && section2.editting"
                    ></app-language-switcher>
                  </li>
                </ul>

                <div class="venture__content-tab" [ngbNavOutlet]="nav"></div>
              </div>
            </div>

            <div *ngIf="uiTemplateName === TemplateName.Reduced4" class="row">
              <div class="col col-12">
                <app-form-field-custom
                  [formInput]="
                    getFormSectionIndex(VentureSectionIndex.Description)
                  "
                  [showLabel]="true"
                  [labelClass]="'venture__label--head'"
                  [editable]="isCreating || (editable && section2.editting)"
                  [attributeDescription]="getFieldOptions('Excerpt', entity)"
                  [options]="{ isViewAsText: true, areRowsLimited: false }"
                >
                </app-form-field-custom>
                <div
                  *ngIf="
                    shouldShowDescriptionErrorForCbcharta() &&
                    (isCreating || (editable && section2?.editting))
                  "
                  class="alert alert-danger"
                  translate
                >
                  Please enter description
                </div>
              </div>

              <div class="col col-12">
                <app-form-field-custom
                  [type]="allType.Files"
                  [formInput]="form"
                  [showLabel]="true"
                  [editable]="isCreating || (editable && section2.editting)"
                  [attributeDescription]="getFieldOptions('LinkAttatchFiles')"
                  [options]="{
                    showHiddenMarkBackground: isOwner
                  }"
                >
                </app-form-field-custom>
              </div>
            </div>
          </app-form-section>
        </div>
        <ng-container
          *ngIf="
            uiTemplateName !== TemplateName.Reduced3 &&
            uiTemplateName !== TemplateName.Reduced4
          "
        >
          <div
            *ngIf="!shouldHideDemandSection"
            class="venture__body widget__card--background"
          >
            <app-form-section
              [index]="VentureSectionIndex.Demands"
              [canSave]="isValidSection(VentureSectionIndex.Demands)"
              [showEditButton]="!isCreating && editable"
              [saveSectionCallback]="saveSection.bind(this)"
              [preSaveSectionCallback]="beforeChangeNDA.bind(this, entity)"
              (cancelSection)="cancelSection($event)"
              (editIndexChange)="markEditingSectionIndex($event)"
              #sectionDemands
            >
              <div class="row">
                <div class="col">
                  <div
                    *ngIf="getFieldOptions('Demands') as demands"
                    class="mb-3"
                  >
                    <ng-container
                      *ngIf="uiTemplateName | visibility : 'OpenFor'"
                    >
                      <div class="venture__label widget__label">
                        {{ demands.displayName }}
                        <app-collapsible-hint
                          [content]="demands.helpTextId | translate"
                        ></app-collapsible-hint>
                      </div>
                      <app-form-field-custom
                        *ngIf="
                          isCreating || sectionDemands?.editting;
                          else openForRef
                        "
                        [formInput]="form"
                        [editable]="
                          isCreating || (editable && sectionDemands?.editting)
                        "
                        [type]="allType.MultiString"
                        [options]="{
                          placeholder: 'Type hashtag' | translate,
                          hasTagSuggestions: true
                        }"
                        [attributeDescription]="demands"
                        [entityName]="entityName"
                      >
                      </app-form-field-custom>
                      <ng-template #openForRef>
                        <ul class="list-inline widget__bold">
                          <li
                            *ngFor="let item of form.controls['Demands']?.value"
                            class="list-inline-item font-weight-bold mw-100"
                          >
                            <span
                              class="venture__hashtags widget__bold badge overflow-hidden truncate-line-1 mw-100 d-block"
                              ># {{ item?.value }}</span
                            >
                          </li>
                        </ul>
                      </ng-template>
                    </ng-container>
                  </div>

                  <ng-container *ngIf="uiTemplateName | visibility : 'Terms'">
                    <div class="mb-3">
                      <div class="venture__label widget__label" translate>
                        Venture Terms
                      </div>

                      <div
                        *ngIf="
                          shouldShowVentureDetails();
                          else hiddenContentRef
                        "
                        [class.hidden-content-mark]="
                          shouldShowHiddenMarkBackground
                        "
                        [class.nda-term]="!isCreating && !isOpenVenture"
                      >
                        <app-form-field-custom
                          [formInput]="form"
                          [editable]="
                            isCreating || (editable && sectionDemands?.editting)
                          "
                          [controlName]="entityOriginField.Terms"
                          [type]="allType.SingleSelection"
                          [attributeDescription]="
                            getFieldOptions(entityOriginField.Terms)
                          "
                          [options]="{
                            defaultLabel: 'Select Venture Term' | translate,
                            showLanguageSwitcher:
                              !isCreating && editable && section2.editting
                          }"
                        >
                        </app-form-field-custom>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="isCreating">
                    <div class="mb-3">
                      <div
                        class="venture__label widget__label--no-truncate"
                        translate
                      >
                        {{ getFieldOptions('IsPrivate')?.displayName }}
                        <i
                          class="fas fa-info-circle pointer-cursor"
                          appTooltip
                          triggers="click"
                          [ngbTooltip]="
                            'UI.VentureEdit.PrivacyTooltip'
                              | translate
                              | stringReplacement
                                : {
                                    '{tenantName}': tenantName
                                  }
                          "
                        ></i>
                      </div>

                      <app-form-field-custom
                        [formInput]="form"
                        [editable]="
                          isCreating || (editable && sectionDemands?.editting)
                        "
                        [attributeDescription]="getFieldOptions('IsPrivate')"
                      >
                      </app-form-field-custom>
                    </div>
                  </ng-container>

                  <div
                    class="mb-3"
                    *ngIf="uiTemplateName | visibility : 'LevelOfDisclosure'"
                  >
                    <div
                      class="venture__label widget__label venture__nda-required"
                    >
                      <div class="mb-2 pb-1">
                        {{ getFieldOptions('IsNDARequired')?.displayName }}
                        <i
                          class="fas fa-info-circle pointer-cursor"
                          appTooltip
                          triggers="click"
                          [ngbTooltip]="disclosureTooltip"
                        ></i>

                        <ng-template #disclosureTooltip>
                          <quill-view
                            class="ql-editor-only-view"
                            [content]="
                              getFieldOptions('IsNDARequired')?.helpTextId
                                | translate
                            "
                          ></quill-view>
                        </ng-template>
                      </div>
                      <div>
                        <app-toggle-button-slider
                          [(status)]="isOpenVenture"
                          [settings]="{
                            disabled: !(
                              isCreating ||
                              (editable && sectionDemands?.editting)
                            ),
                            activeText:
                              'VENTURE-DETAILS_Level-of-Disclosure_Open'
                              | translate,
                            deactiveText:
                              'VENTURE-DETAILS_Level-of-Disclosure_Private'
                              | translate
                          }"
                        >
                        </app-toggle-button-slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </app-form-section>
          </div>
        </ng-container>

        <ngb-accordion
          [activeIds]="uiTemplateName | call : getActiveIds"
          [destroyOnHide]="false"
        >
          <!-- Custom Metadata for cbcharta, screen4care -->
          <ng-container
            *ngIf="
              uiTemplateName === TemplateName.Reduced4 ||
              uiTemplateName === TemplateName.Reduced3
            "
          >
            <ngb-panel
              *ngIf="
                customMetadata.attributeDescriptions.length
                  | call
                    : shouldShowCustomMetadataSection
                    : isCustomAttributeFormGenerated
              "
              cardClass="venture__body venture__accordion ignore-overflow"
              id="custom-metadata-panel"
            >
              <ng-template ngbPanelHeader let-opened="opened">
                <button
                  class="btn d-flex align-items-center justify-content-between w-100 text-left"
                  ngbPanelToggle
                  type="button"
                >
                  <div translate class="pull-left float-left">
                    <span
                      *ngIf="uiTemplateName === TemplateName.Reduced4"
                      class="venture__company-header mr-5 d-block d-lg-inline-block"
                      >Projektdetails</span
                    >
                    <span
                      *ngIf="uiTemplateName === TemplateName.Reduced3"
                      class="venture__company-header mr-5 d-block d-lg-inline-block"
                      >Feedback Category</span
                    >
                  </div>
                  <i
                    class="fas float-right pull-right venture__company-header"
                    [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                  ></i>
                </button>
              </ng-template>

              <ng-template ngbPanelContent>
                <div class="venture__body widget__card--background m-0">
                  <app-form-section
                    #sectionCustomMetadata
                    [index]="formSections.length - 1"
                    [canSave]="isValidSection(formSections.length - 1)"
                    [showEditButton]="
                      !isCreating && (editable || entity?.isOrganizationAdmin)
                    "
                    [saveSectionCallback]="
                      saveCustomAttributeSection.bind(this)
                    "
                    (saveSection)="markOrgFormAsTouched()"
                    (cancelSection)="cancelSection($event)"
                    (editIndexChange)="markEditingSectionIndex($event)"
                  >
                    <div
                      *ngIf="shouldShowVentureDetails(); else hiddenContentRef"
                      [class.hidden-content-mark]="
                        shouldShowHiddenMarkBackground
                      "
                    >
                      <app-custom-attributes
                        [formInput]="form"
                        [editable]="
                          isCreating ||
                          ((editable || entity?.isOrganizationAdmin) &&
                            sectionCustomMetadata?.editting)
                        "
                        [metadataRecord]="customMetadata"
                        [orderOfPropertyNames]="
                          VENTURE_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES
                        "
                      >
                      </app-custom-attributes>
                    </div>
                  </app-form-section>
                </div>
              </ng-template>
            </ngb-panel>
          </ng-container>

          <!-- Privacy settings section for cbcharta -->
          <ngb-panel
            *ngIf="
              isCreating &&
              (uiTemplateName === TemplateName.Reduced4 ||
                uiTemplateName === TemplateName.Reduced3)
            "
            cardClass="venture__body venture__accordion ignore-overflow"
            id="privacy-settings"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <button
                class="btn d-flex align-items-center justify-content-between w-100 text-left"
                ngbPanelToggle
                type="button"
              >
                <div translate class="pull-left float-left">
                  <span
                    *ngIf="uiTemplateName === TemplateName.Reduced4"
                    class="venture__company-header mr-5 d-block d-lg-inline-block"
                    >Sichtbarkeit</span
                  >
                </div>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </button>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="venture__body widget__card--background m-0">
                <app-form-section
                  [index]="VentureSectionIndex.Demands"
                  [canSave]="isValidSection(VentureSectionIndex.Demands)"
                  [showEditButton]="!isCreating && editable"
                  [saveSectionCallback]="saveSection.bind(this)"
                  [preSaveSectionCallback]="beforeChangeNDA.bind(this, entity)"
                  (cancelSection)="cancelSection($event)"
                  (editIndexChange)="markEditingSectionIndex($event)"
                  #sectionDemands
                >
                  <div class="row">
                    <div class="col">
                      <ng-container *ngIf="isCreating">
                        <div class="mb-3">
                          <div
                            class="venture__label widget__label--no-truncate"
                            translate
                          >
                            {{ getFieldOptions('IsPrivate')?.displayName }}
                            <i
                              class="fas fa-info-circle pointer-cursor"
                              appTooltip
                              triggers="click"
                              [ngbTooltip]="
                                'UI.VentureEdit.PrivacyTooltip'
                                  | translate
                                  | stringReplacement
                                    : {
                                        '{tenantName}': tenantName
                                      }
                              "
                            ></i>
                          </div>

                          <app-form-field-custom
                            [formInput]="form"
                            [editable]="
                              isCreating ||
                              (editable && sectionDemands?.editting)
                            "
                            [attributeDescription]="
                              getFieldOptions('IsPrivate')
                            "
                          >
                          </app-form-field-custom>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </app-form-section>
              </div>
            </ng-template>
          </ngb-panel>

          <!-- Lead Organization -->
          <ngb-panel
            cardClass="venture__body venture__accordion ignore-overflow"
            id="lead-company-panel"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <button
                class="btn d-flex align-items-center justify-content-between w-100 text-left"
                ngbPanelToggle
                type="button"
              >
                <div
                  translate
                  class="pull-left float-left container-fluid px-0"
                >
                  <div class="row">
                    <div class="col-12">
                      <span
                        *ngIf="uiTemplateName !== TemplateName.Reduced4"
                        class="venture__company-header mr-2"
                        translate
                        >Lead Organization</span
                      >
                      <span
                        *ngIf="uiTemplateName === TemplateName.Reduced4"
                        class="venture__company-header mr-2"
                        translate
                        >Bauherrschaft</span
                      >

                      <i
                        class="fas fa-info-circle widget__color"
                        *ngIf="isCreating || isOwner"
                        appTooltip
                        placement="left auto"
                        triggers="click"
                        [ngbTooltip]="tooltipContent"
                        (click)="$event.stopPropagation()"
                      ></i>
                      <ng-template #tooltipContent>
                        <span
                          class="tooltip-link"
                          [innerHtml]="
                            'Lead-Org-For-Ventures-Tooltip'
                              | translate
                              | stringReplacement
                                : {
                                    '{create}':
                                      createOrgBaseUrl + '/create'
                                      | linkWrapper : ('create' | translate)
                                  }
                          "
                        >
                        </span>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </button>
            </ng-template>

            <ng-template ngbPanelContent>
              <app-deactivated-organization-warning
                *ngIf="!isEditingOrg && !isCreating && !leadCompany?.value"
              ></app-deactivated-organization-warning>

              <div class="venture__body widget__card--background">
                <app-form-section
                  #section3
                  [index]="VentureSectionIndex.LeadCompany"
                  [canSave]="isValidSection(VentureSectionIndex.LeadCompany)"
                  [(editting)]="isEditingOrg"
                  [showEditButton]="canEditLeadCompany"
                  [formSectionClass]="'row'"
                  [preSaveSectionCallback]="
                    beforeSelectLeadOrg?.bind(this, entity)
                  "
                  [saveSectionCallback]="saveSection.bind(this)"
                  (saveSection)="markOrgFormAsTouched()"
                  (cancelSection)="
                    cancelSection($event); reverseLinkToExistingOrg()
                  "
                  (editIndexChange)="markEditingSectionIndex($event)"
                >
                  <div class="row mb-2">
                    <div class="col-12 px-md-0">
                      <app-organizer
                        #organizerComponent
                        deactivatedOrgIcon="fas fa-times"
                        deactivatedOrgLabel="UI.LeadOrgUnavailable"
                        (orgFormChange)="updateLeadOrgFormValue($event)"
                        (orgSelected)="onLeadOrgSelectedEvent($event)"
                        [leadCompany]="leadCompany?.value"
                        [disabledSection]="
                          !isCreating && !(canEditLeadCompany && isEditingOrg)
                        "
                        [childClass]="'col-12 col-lg-6'"
                        [(isLinkedFromParent)]="isLinkedToExistingOrg"
                        [searchMode]="orgsSearchMode"
                        [editing]="
                          isCreating || (isOwner && section3?.editting)
                        "
                        [markOrgFormTouched]="markOrgFormTouched"
                        [nameAttributeDescription]="
                          getFieldOptions('LeadCompanyName')
                        "
                        [logoAttributeDescription]="
                          getFieldOptions('LeadCompanyLogo')
                        "
                        [urlAttributeDescription]="
                          getFieldOptions('LeadCompanyLink')
                        "
                        [userId]="profile?.id"
                      ></app-organizer>

                      <div
                        *ngIf="
                          showLeadOrgError() &&
                          (isCreating || (isOwner && section3?.editting))
                        "
                        class="alert alert-danger"
                      >
                        <span>{{
                          'UI.Common.Lead-Org-is-required' | translate
                        }}</span>
                      </div>
                    </div>
                  </div>
                </app-form-section>
              </div>
            </ng-template>
          </ngb-panel>

          <!-- Custom Metadata -->
          <ng-container
            *ngIf="
              uiTemplateName !== TemplateName.Reduced4 &&
              uiTemplateName !== TemplateName.Reduced3
            "
          >
            <ngb-panel
              *ngIf="
                customMetadata.attributeDescriptions.length
                  | call
                    : shouldShowCustomMetadataSection
                    : isCustomAttributeFormGenerated
              "
              cardClass="venture__body venture__accordion ignore-overflow"
              id="custom-metadata-panel"
            >
              <ng-template ngbPanelHeader let-opened="opened">
                <button
                  class="btn d-flex align-items-center justify-content-between w-100 text-left"
                  ngbPanelToggle
                  type="button"
                >
                  <div translate class="pull-left float-left">
                    <span
                      class="venture__company-header mr-5 d-block d-lg-inline-block"
                      >{{
                        'UI.Organization.OrganizationSpecificInformation'
                          | translate
                      }}</span
                    >
                  </div>
                  <i
                    class="fas float-right pull-right venture__company-header"
                    [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                  ></i>
                </button>
              </ng-template>

              <ng-template ngbPanelContent>
                <div class="venture__body widget__card--background m-0">
                  <app-form-section
                    #sectionCustomMetadata
                    [index]="formSections.length - 1"
                    [canSave]="isValidSection(formSections.length - 1)"
                    [showEditButton]="
                      !isCreating && (editable || entity?.isOrganizationAdmin)
                    "
                    [saveSectionCallback]="
                      saveCustomAttributeSection.bind(this)
                    "
                    (saveSection)="markOrgFormAsTouched()"
                    (cancelSection)="cancelSection($event)"
                    (editIndexChange)="markEditingSectionIndex($event)"
                  >
                    <div
                      *ngIf="shouldShowVentureDetails(); else hiddenContentRef"
                      [class.hidden-content-mark]="
                        shouldShowHiddenMarkBackground
                      "
                    >
                      <app-custom-attributes
                        [formInput]="form"
                        [editable]="
                          isCreating ||
                          ((editable || entity?.isOrganizationAdmin) &&
                            sectionCustomMetadata?.editting)
                        "
                        [metadataRecord]="customMetadata"
                        [orderOfPropertyNames]="
                          VENTURE_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES
                        "
                      >
                      </app-custom-attributes>
                    </div>
                  </app-form-section>
                </div>
              </ng-template>
            </ngb-panel>
          </ng-container>

          <!-- Submit Venture to Organizations -->
          <ngb-panel
            cardClass="venture__accordion venture__organizations ignore-overflow"
            id="submit-to-orgs"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <button
                class="btn d-flex align-items-center justify-content-between w-100 text-left"
                ngbPanelToggle
                type="button"
              >
                <div translate class="pull-left float-left">
                  <span
                    *ngIf="uiTemplateName === TemplateName.Reduced4"
                    class="venture__company-header mr-5 d-block d-lg-inline-block"
                    >Partnerorganisationen</span
                  >
                  <span
                    *ngIf="uiTemplateName !== TemplateName.Reduced4"
                    class="venture__company-header mr-5 d-block d-lg-inline-block"
                    >{{
                      'VENTURE-DETAILS_Submit-venture-section'
                        | kczTranslationKey : uiTemplateName
                        | translate
                    }}
                  </span>
                </div>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </button>
            </ng-template>

            <ng-template ngbPanelContent>
              <div class="venture__body widget__card--background">
                <app-form-section
                  #section7
                  [index]="VentureSectionIndex.SubmittedOrganizations"
                  [canSave]="
                    isValidSection(VentureSectionIndex.SubmittedOrganizations)
                  "
                  [showEditButton]="!isCreating && editable"
                  [preSaveSectionCallback]="
                    beforeSubmitVenture?.bind(this, entity)
                  "
                  [saveSectionCallback]="saveSection.bind(this)"
                  (saveSection)="markOrgFormAsTouched()"
                  (cancelSection)="ventureOrgs.reset(); cancelSection($event)"
                  (editIndexChange)="markEditingSectionIndex($event)"
                >
                  <app-venture-organizations
                    #ventureOrgs
                    [editting]="isCreating || section7.editting"
                    [items]="
                      entity?.submittedOrganizations || organizationsToSubmit
                    "
                    [userId]="profile?.id"
                    [ignoreCompany]="entity?.leadCompany"
                    [baseHttpService]="baseHttpService"
                    [orgsOnly]="uiTemplateName === TemplateName.Reduced4"
                    (notifyOrganisationPayload)="
                      updateOrganisationPayload($event)
                    "
                  ></app-venture-organizations>
                </app-form-section>
              </div>
            </ng-template>
          </ngb-panel>

          <!-- Initiator &  Stakeholders -->
          <ngb-panel
            cardClass="venture__body venture__accordion"
            id="people-panel"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <button
                class="btn d-flex align-items-center justify-content-between w-100 text-left"
                ngbPanelToggle
                type="button"
              >
                <div translate class="pull-left float-left">
                  <span
                    *ngIf="uiTemplateName === TemplateName.Reduced4"
                    class="venture__company-header mr-5 d-block d-lg-inline-block"
                    >Ansprechspersonen & Site Administrator (created by)</span
                  >
                  <span
                    *ngIf="uiTemplateName !== TemplateName.Reduced4"
                    class="venture__company-header mr-5 d-block d-lg-inline-block"
                    >{{ getFieldOptions('Initiator')?.displayName }} &
                    {{ getFieldOptions('Stakeholders')?.displayName }}</span
                  >
                </div>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </button>
            </ng-template>

            <ng-template ngbPanelContent>
              <div
                class="venture__body venture-people widget__card--background"
              >
                <div class="row">
                  <div class="col col-12 px-md-0">
                    <app-search-people-box
                      (keyChanged)="onSearchPeople($event)"
                      [searchName]="filteringKeyword"
                      styleClass="my-2"
                    ></app-search-people-box>

                    <label
                      class="venture__label venture__label--head widget__label"
                      translate
                    >
                      {{ getFieldOptions('Initiator')?.displayName }}
                    </label>
                    <ng-container *ngIf="isCreating; else adminInEditMode">
                      <app-initator-cards
                        *ngIf="profile"
                        [items]="[profile]"
                        [enablePagination]="false"
                        [editable]="false"
                        [allowAddPeople]="false"
                        [allowRemovePeople]="false"
                        [globalOrgId]="globalOrgId"
                      ></app-initator-cards>
                    </ng-container>
                    <ng-template #adminInEditMode>
                      <ng-container
                        *ngIf="entity?.initiator; else deactiveUser"
                      >
                        <app-initator-cards
                          [items]="[entity?.initiator]"
                          [entityId]="entity?.id"
                          [httpService]="baseHttpService"
                          [enablePagination]="false"
                          [customUserRoles]="customAdminRoles"
                          [editable]="editable || entity?.isOrganizationAdmin"
                          [allowAddPeople]="false"
                          [allowRemovePeople]="false"
                          [filteringKeyword]="filteringKeyword"
                          [globalOrgId]="globalOrgId"
                        ></app-initator-cards>
                      </ng-container>

                      <ng-template #deactiveUser>
                        <app-deactive-entity
                          [deactiveShowType]="deactiveShowTypeEnum.Card"
                        ></app-deactive-entity>
                      </ng-template>
                    </ng-template>

                    <ng-container
                      *ngIf="uiTemplateName !== TemplateName.Reduced4"
                    >
                      <ng-container *ngIf="isOwner && !isCreating">
                        <app-pending-people-cards
                          *ngIf="
                            !isPendingPeopleLoading;
                            else loadingPendingPeople
                          "
                          (peopleApproved)="handleAfterApprovePeople($event)"
                          (peopleRemoved)="removePendingPerson($event)"
                          [allowAddPeople]="false"
                          [customUserRoles]="customUserRoles"
                          [editable]="editable"
                          [enableExtraAction]="true"
                          [entityId]="entity?.id"
                          [filteringKeyword]="filteringKeyword"
                          [httpService]="baseHttpService"
                          [parentEntityName]="entityName"
                          [parentEntity]="entity"
                        >
                        </app-pending-people-cards>
                        <ng-template #loadingPendingPeople>
                          <div
                            class="d-flex justify-content-center align-items-center people-loading--min-height"
                          >
                            <i class="fas fa-spinner fa-spin"></i>
                          </div>
                        </ng-template>
                      </ng-container>

                      <div *ngIf="entity?.canInvite" class="my-4 pb-3">
                        <app-invitation-by-email
                          [baseHttpService]="baseHttpService"
                          [ccEmail]="(profile$ | async)?.email"
                          [entityId]="entity?.id"
                          [messageBody]="
                            'UI.Invitation.Mail-content--Body-For-Venture'
                          "
                          [senderName]="
                            profile?.firstName + ' ' + profile?.lastName
                          "
                          [senderSignature]="
                            'UI.Invitation.Signature'
                              | translate
                              | stringReplacement
                                : {
                                    '{name}':
                                      profile?.firstName +
                                      ' ' +
                                      profile?.lastName,
                                    '{portalName}': portalName
                                  }
                          "
                          [disableReason]="
                            isInviteSectionDisabled(entity)
                              ? 'UI.VentureEdit.DisabledInviteTooltip'
                              : ''
                          "
                          [inviteTokenType]="TokenTypes.InviteVenture"
                        >
                          <div
                            class="tooltip-wrapper"
                            [ngbTooltip]="
                              'UI.VentureEdit.DisabledInviteTooltip' | translate
                            "
                            [disableTooltip]="!isInviteSectionDisabled(entity)"
                          >
                            <button
                              onmouseup="this.blur();"
                              appRequireLogin
                              appDebounceClick
                              (debounceClick)="
                                openShareEntityToPeopleDialog(entity)
                              "
                              class="btn btn-primary form-section__btn m-0"
                              [disabled]="isInviteSectionDisabled(entity)"
                            >
                              {{ 'UI.VentureEdit.ShareButton' | translate }}
                            </button>
                          </div>
                        </app-invitation-by-email>
                      </div>

                      <div
                        class="d-flex flex-column flex-md-row align-items-start align-items-md-center"
                      >
                        <label
                          class="venture__label venture__label--head widget__label mr-4"
                          translate
                        >
                          {{ getFieldOptions('Stakeholders')?.displayName }}
                        </label>
                        <ng-container *ngIf="isSendMessToMemberShown()">
                          <app-btn-direct-message
                            [entityId]="entity.id"
                          ></app-btn-direct-message>
                        </ng-container>
                        <ng-container *ngIf="!isCreating && isTeam">
                          <button
                            appLeaveEntity
                            [entityId]="entity.id"
                            [entityName]="entityName"
                            [(isLeaving)]="isLeavingEntity"
                            [disabled]="isLeavingEntity"
                            (afterLeaved)="afterLeaveEntity($event)"
                            class="btn btn-outline-primary form-section__btn m-0 mt-2 mt-md-0 ml-md-2"
                          >
                            <i class="fas fa-user-times"></i>
                            <span
                              class="ml-2"
                              [innerHTML]="
                                'UI.Common.LeaveThis'
                                  | translate
                                  | stringReplacement
                                    : {
                                        '{entity}': (entityName | translate)
                                      }
                              "
                            >
                            </span>
                          </button>
                        </ng-container>
                      </div>

                      <app-stakeholder-cards
                        (itemsChange)="updateStakeholder($event)"
                        (peopleAdded)="handleAfterAddPeople()"
                        [httpService]="baseHttpService"
                        [items]="stakeholders"
                        [entityId]="entity?.id"
                        [editable]="editable || entity?.isOrganizationAdmin"
                        [shouldValidatePersonBeforeAdding]="!isCreating"
                        [ignoreId]="
                          entity?.initiator?.id || (profile$ | async)?.id
                        "
                        [parentEntityName]="entityName"
                        [parentEntity]="entity"
                        [allowAddPeople]="isOwner"
                        [allowRemovePeople]="isOwner && !isTeam"
                        [customUserRoles]="customUserRoles"
                        [filteringKeyword]="filteringKeyword"
                        [globalOrgId]="globalOrgId"
                      ></app-stakeholder-cards>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>

          <!-- Venture Details -->
          <ng-container *ngIf="uiTemplateName !== TemplateName.Reduced4">
            <ngb-panel
              cardClass="venture__body venture__accordion"
              id="venture-details-panel"
            >
              <ng-template ngbPanelHeader let-opened="opened">
                <button
                  class="btn d-flex align-items-center justify-content-between w-100 text-left"
                  ngbPanelToggle
                  type="button"
                >
                  <div translate class="pull-left float-left">
                    <span
                      class="venture__company-header mr-5 d-block d-lg-inline-block"
                      >{{
                        'Venture Details'
                          | kczTranslationKey : uiTemplateName
                          | translate
                      }}</span
                    >
                  </div>
                  <i
                    class="fas float-right pull-right venture__company-header"
                    [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                  ></i>
                </button>
              </ng-template>

              <ng-template ngbPanelContent>
                <div
                  class="venture__body widget__card--background venture__characteristic-edit"
                >
                  <div class="row">
                    <div class="col col-12 px-md-0">
                      <ul
                        ngbNav
                        [activeId]="
                          uiTemplateName | call : characteristicsActiveTab
                        "
                        #navVentureDetails="ngbNav"
                        [destroyOnHide]="false"
                        class="nav-tabs"
                      >
                        <!-- Characteristics -->
                        <li
                          *ngIf="
                            uiTemplateName
                              | visibility : 'BusinessCharacteristics'
                          "
                          (ngxVisibility)="characteristicsActiveTab($event)"
                          ngbNavItem
                          [ngbNavItem]="0"
                        >
                          <a ngbNavLink
                            ><span class="venture__tabs__title"
                              >{{ 'Venture Characteristic' | translate }}
                              <span class="after"></span> </span
                          ></a>

                          <ng-template ngbNavContent>
                            <app-form-section
                              #section4
                              [index]="VentureSectionIndex.BusinessAreas"
                              [canSave]="
                                isValidSection(
                                  VentureSectionIndex.BusinessAreas
                                )
                              "
                              [showEditButton]="!isCreating && editable"
                              [formSectionClass]="'row'"
                              [saveSectionCallback]="saveSection.bind(this)"
                              (saveSection)="markOrgFormAsTouched()"
                              (cancelSection)="cancelSection($event)"
                              (editIndexChange)="
                                markEditingSectionIndex($event)
                              "
                            >
                              <div
                                *ngIf="
                                  shouldShowVentureDetails();
                                  else hiddenContentRef
                                "
                                [class.hidden-content-mark]="
                                  shouldShowHiddenMarkBackground
                                "
                              >
                                <ng-container
                                  *ngIf="
                                    !uiTemplateName ||
                                    !(
                                      uiTemplateName
                                      | visibility : 'OnlyBusinessArea'
                                    )
                                  "
                                >
                                  <ng-container
                                    *ngIf="
                                      isCreating ||
                                        (editable && section4?.editting);
                                      else viewCharateristicRef
                                    "
                                  >
                                    <app-form-field-custom
                                      *ngFor="
                                        let fieldName of VENTURE_CHARACTERISTICS
                                      "
                                      [formInput]="form"
                                      [editable]="
                                        isCreating ||
                                        (editable && section4?.editting)
                                      "
                                      [showLabel]="true"
                                      [attributeDescription]="
                                        getFieldOptions(fieldName)
                                      "
                                    ></app-form-field-custom>
                                  </ng-container>
                                  <ng-template #viewCharateristicRef>
                                    <ul class="list-inline widget__highlight">
                                      <ng-container
                                        *ngFor="
                                          let fieldName of VENTURE_CHARACTERISTICS
                                        "
                                      >
                                        <li
                                          *ngIf="
                                            form?.getRawValue()[fieldName]
                                              ?.codeId !== 'DefaultValue'
                                          "
                                          class="list-inline-item"
                                        >
                                          <span
                                            class="venture__characteristic widget__characteristics--background widget__highlight badge badge-pill badge-light"
                                          >
                                            {{
                                              form?.getRawValue()[fieldName]
                                                ?.value
                                            }}</span
                                          >
                                        </li>
                                      </ng-container>
                                    </ul>
                                  </ng-template>
                                </ng-container>
                                <app-form-field-custom
                                  #businessArea
                                  [formInput]="form"
                                  [editable]="
                                    isCreating ||
                                    (editable && section4?.editting)
                                  "
                                  [showLabel]="true"
                                  [attributeDescription]="
                                    getFieldOptions('BusinessAreas')
                                  "
                                  [options]="{
                                    showHiddenMarkBackground: isOwner
                                  }"
                                ></app-form-field-custom>
                              </div>
                            </app-form-section>
                          </ng-template>
                        </li>

                        <!-- References -->
                        <li ngbNavItem [ngbNavItem]="1">
                          <a ngbNavLink
                            ><span class="venture__tabs__title"
                              >{{
                                'Venture References'
                                  | kczTranslationKey : uiTemplateName
                                  | translate
                              }}
                              <span class="after"></span> </span
                          ></a>
                          <ng-template ngbNavContent>
                            <app-form-section
                              #section6
                              [index]="VentureSectionIndex.Link"
                              [canSave]="
                                isValidSection(VentureSectionIndex.Link)
                              "
                              [showEditButton]="!isCreating && editable"
                              [formSectionClass]="'row'"
                              [saveSectionCallback]="saveSection.bind(this)"
                              (cancelSection)="cancelSection($event)"
                              (editIndexChange)="
                                markEditingSectionIndex($event)
                              "
                              [hasBindingDataBeforeSave]="true"
                            >
                              <div
                                *ngIf="
                                  shouldShowVentureDetails();
                                  else hiddenContentRef
                                "
                                [class.hidden-content-mark]="
                                  shouldShowHiddenMarkBackground
                                "
                              >
                                <span class="venture__label widget__label">
                                  {{
                                    (isCreating ||
                                    (editable && section6?.editting)
                                      ? 'Please add links to your venture'
                                      : 'Venture links'
                                    ) | translate
                                  }}
                                </span>
                                <ng-container>
                                  <app-form-field-custom
                                    [type]="allType.MultiLink"
                                    [formInput]="form"
                                    [editable]="
                                      isCreating ||
                                      (editable && section6?.editting)
                                    "
                                    [attributeDescription]="
                                      getFieldOptions('LinkCompanyHomePage')
                                    "
                                    [options]="{
                                      placeholder:
                                        'VENTURE-DETAILS_Webpage-link-placeholder'
                                        | translate,
                                      showHiddenMarkBackground: isOwner,
                                      fixedItems: form?.getRawValue()
                                        .LinkCompanyHomePage || [
                                        {
                                          url: '',
                                          faIconClass: 'fa-home'
                                        }
                                      ]
                                    }"
                                  ></app-form-field-custom>

                                  <app-form-field-custom
                                    [type]="allType.MultiLink"
                                    [formInput]="form"
                                    [editable]="
                                      isCreating ||
                                      (editable && section6?.editting)
                                    "
                                    [attributeDescription]="
                                      getFieldOptions('LinkCollaborationSpaces')
                                    "
                                    [selectionChoices]="
                                      form?.getRawValue()
                                        .LinkCollaborationSpaces
                                    "
                                    [options]="{
                                      enabledAddNew: true,
                                      showHiddenMarkBackground: isOwner,
                                      placeholder:
                                        'VENTURE-DETAILS_Others-links-placeholder'
                                        | translate
                                    }"
                                  ></app-form-field-custom>
                                </ng-container>
                                <app-form-field-custom
                                  [type]="allType.Files"
                                  [formInput]="form"
                                  [showLabel]="true"
                                  [editable]="
                                    isCreating ||
                                    (editable && section6.editting)
                                  "
                                  [attributeDescription]="
                                    getFieldOptions('LinkAttatchFiles')
                                  "
                                  [options]="{
                                    showHiddenMarkBackground: isOwner
                                  }"
                                >
                                </app-form-field-custom>
                              </div>
                            </app-form-section>
                          </ng-template>
                        </li>

                        <!-- Evolution -->
                        <li
                          *ngIf="
                            uiTemplateName | visibility : 'VentureEvolution'
                          "
                          ngbNavItem
                          [ngbNavItem]="2"
                        >
                          <a ngbNavLink
                            ><span class="venture__tabs__title"
                              >{{
                                'Venture Evolution'
                                  | kczTranslationKey : uiTemplateName
                                  | translate
                              }}
                              <span class="after"></span> </span
                          ></a>

                          <ng-template ngbNavContent>
                            <app-form-section
                              #section8
                              [index]="VentureSectionIndex.Status"
                              [canSave]="
                                isValidSection(VentureSectionIndex.Status)
                              "
                              [showEditButton]="!isCreating && editable"
                              [saveSectionCallback]="saveSection.bind(this)"
                              (cancelSection)="cancelSection($event)"
                              (editIndexChange)="
                                markEditingSectionIndex($event)
                              "
                            >
                              <div
                                *ngIf="
                                  shouldShowVentureDetails();
                                  else hiddenContentRef
                                "
                                [class.hidden-content-mark]="
                                  shouldShowHiddenMarkBackground
                                "
                              >
                                <div class="row">
                                  <div class="col-md-auto mr-5">
                                    <label
                                      class="venture__label widget__label"
                                      translate
                                      >{{
                                        getFieldOptions('CreatedDate')
                                          ?.displayName
                                      }}</label
                                    >
                                    {{
                                      entity?.createdDate
                                        | localizedDate
                                          : DateFormat.FullDateTimeCommon
                                    }}
                                  </div>
                                  <div class="col">
                                    <label
                                      class="venture__label widget__label"
                                      translate
                                      >{{
                                        getFieldOptions('ModifiedDate')
                                          ?.displayName
                                      }}</label
                                    >
                                    {{
                                      entity?.modifiedDate
                                        | localizedDate
                                          : DateFormat.FullDateTimeCommon
                                    }}
                                  </div>
                                </div>

                                <ng-container
                                  *ngIf="
                                    uiTemplateName
                                      | visibility : 'ConstitutionState'
                                  "
                                >
                                  <div class="row">
                                    <div class="col col-12">
                                      <div
                                        class="venture__label widget__label"
                                        translate
                                      >
                                        Constitution State
                                      </div>
                                      <app-form-field-custom
                                        [formInput]="form"
                                        [editable]="
                                          isCreating ||
                                          (editable && section8?.editting)
                                        "
                                        [type]="allType.VentureStatus"
                                        [attributeDescription]="
                                          getFieldOptions('Status')
                                        "
                                      ></app-form-field-custom>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </app-form-section>
                          </ng-template>
                        </li>
                      </ul>
                      <div
                        class="venture__content-tab"
                        [ngbNavOutlet]="navVentureDetails"
                      ></div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ng-container>

          <!-- Timestamp -->
          <ng-container *ngIf="uiTemplateName !== TemplateName.Reduced4">
            <ngb-panel
              *ngIf="entity && !isCreating"
              cardClass="venture__body venture__accordion ignore-overflow"
              id="timestamp-panel"
            >
              <ng-template ngbPanelHeader let-opened="opened">
                <button
                  class="btn d-flex align-items-center justify-content-between w-100 text-left"
                  ngbPanelToggle
                  type="button"
                >
                  <div
                    translate
                    class="pull-left float-left container-fluid px-0"
                  >
                    <div class="row">
                      <div
                        class="col-12 d-flex justify-content-between flex-column flex-md-row"
                      >
                        <span class="venture__company-header mr-4" translate>
                          UI.Ventures.Timestamps
                        </span>

                        <ng-container
                          *ngIf="editable || entity?.isOrganizationAdmin"
                        >
                          <app-btn-create-timestamp
                            class="mr-4"
                            [httpService]="baseHttpService"
                            [entityId]="entity?.id"
                            (createSuccess)="onCreateTimestampSuccess($event)"
                          ></app-btn-create-timestamp>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <i
                    class="fas float-right pull-right venture__company-header ml-1"
                    [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                  ></i>
                </button>
              </ng-template>

              <ng-template ngbPanelContent>
                <div class="venture__body widget__card--background">
                  <app-timestamp-section
                    #timestampSection
                    [entityName]="entityName"
                    [entityId]="entity?.id"
                  ></app-timestamp-section>
                </div>
              </ng-template>
            </ngb-panel>
          </ng-container>

          <!-- Advanced setting -->
          <ngb-panel
            *ngIf="isCreating || isOwner"
            cardClass="venture__body venture__accordion"
            id="advanced-setting-panel"
          >
            <ng-template let-opened="opened" ngbPanelHeader>
              <button class="btn panel__header" ngbPanelToggle type="button">
                <div class="pull-left float-left" translate>
                  <span
                    class="venture__company-header mr-5 d-block d-lg-inline-block"
                    translate
                    >Settings</span
                  >
                </div>
                <i
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                  class="fas float-right pull-right venture__company-header"
                ></i>
              </button>
            </ng-template>

            <ng-template ngbPanelContent>
              <div
                class="venture__body widget__card--background location-section"
              >
                <app-form-section>
                  <div>
                    <div class="venture__label widget__label mb-3">
                      {{ getFieldOptions('SendMessageRights').displayName }}
                    </div>
                    <app-radio-selection
                      [items]="
                        getFieldOptions('SendMessageRights').choice.selections
                      "
                      [controlName]="'SendMessageRights'"
                      [currentIndex]="ventureMessageRightSelectedIndex"
                      [editable]="true"
                      (selectItem)="onMessageRightChange($event)"
                    >
                    </app-radio-selection>
                  </div>
                </app-form-section>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <div
          *ngIf="(form.errors || form.invalid) && form.touched && isSubmitted"
          [innerHTML]="
            errorMessage[formErrorKey] || 'Please check the error above'
          "
          class="venture__footer-alert alert alert-danger"
          translate
        ></div>

        <div *ngIf="isCreating" class="venture__btn-wrapper">
          <button
            appRequireSession
            (credentialClick)="handleOnSubmit()"
            class="btn btn-primary venture__btn-submit"
            [disabled]="
              isSubmitting || isSubmitted || !(form?.touched || form?.dirty)
            "
          >
            <ng-container *ngIf="isSubmitting">
              <i class="fas fa-spinner fa-spin"></i>
            </ng-container>
            <span translate>{{ isCreating ? 'Create' : 'Save' }}</span>
          </button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-template #hiddenContentRef>
    <app-hidden-content></app-hidden-content>
  </ng-template>

  <app-people-modal
    *ngIf="showFollowersModal"
    [entityId]="selectedEntityId"
    [entityTitle]="entity?.title"
    modalTitle="Followers"
    [httpService]="baseHttpService"
    (dismissModal)="showFollowersModal = false"
  ></app-people-modal>

  <app-likes-modal
    *ngIf="showLikesModal"
    [entityId]="selectedEntityId"
    [entityTitle]="entity?.title"
    [modalTitle]="'UI.Likes.Venture' | translate"
    [httpService]="baseHttpService"
    (dismissModal)="showLikesModal = false"
  ></app-likes-modal>
</ng-template>
